import React, { useState, useEffect } from 'react'
import { Form, Button, Input, Card, Select, InputNumber, Tabs, Affix, TimePicker, Radio, Spin, Modal, message, Row, Col, Tooltip } from "antd";

const LangSwitcher = ({ lang, setLang }) => {
  return <Radio.Group
    value={lang}
    buttonStyle="solid"
    onChange={(e) => setLang(e.target.value)}
  >
    <Radio.Button value="pl">PL</Radio.Button>
    <Radio.Button value="en">ENG</Radio.Button>
  </Radio.Group>
}

export {
  LangSwitcher
}