import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Steps, Card, Button } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons'
import { Link } from "react-router-dom";

const OnboardingSidebar = styled.div`
text-align: center;
padding: 100px 24px;
h3 {
    margin-bottom: 48px;
}
p {
    font-size: 13px;
    margin-bottom: 24px;
}
`

const OnboardingsSteps = ({step}) => {
    const Step = Steps.Step
    return <Card style={{marginBottom: 24, display: 'flex', justifyContent: 'center'}}>
        <Steps
            current={step}
            size="small"
            direction="vertical"
            style={{margin: '0 auto'}}
        >
            <Step title="Uruchom płatności online" />
            <Step title="Dodaj Ustawienia" />
            <Step title="Uzupełnij Wizytówkę" />
            <Step title="Dodaj Produkty" />
        </Steps>

        { step == 3 && <div style={{textAlign: 'center'}}>
            <Link to="/">
                <Button
                    style={{ marginTop: 24 }}
                    type="primary"
                >
                    Dalej <ArrowRightOutlined />
                </Button>
            </Link>
        </div>}
    </Card>
}


export { OnboardingSidebar, OnboardingsSteps }
