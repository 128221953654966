import React, { useState, useEffect } from 'react'
import { HotelPanelLayout as PanelLayout, t } from '../components/PanelLayout';
import { Form, Button, Input, Card, Select, InputNumber, Tabs, PageHeader, Typography, Divider, Spin, Affix, message, Modal } from "antd";
import { format, eachDayOfInterval, addDays } from 'date-fns'
import { pl as localePl } from 'date-fns/locale'
import { useRequest } from "ahooks";
import { sortedUniq, reverse } from 'lodash'
import { ApiShop } from '../Api';

const Page = ({ shopId }) => {

  const response = useRequest(() => ApiShop.get('/hotel/orders'))
  const orders = response.data && response.data.data || []
  const days = reverse(sortedUniq(orders.map(x => x.day).sort()))

  return <PanelLayout>
    <Card title={t({pl: 'Tabele dla Kuchni i Room Service', en: 'Tables for Kitchen and Room Service'})}>
      {days.map((d, i) => (
        <Button
          key={i}
          style={{
            height: 'auto',
            margin: 12,
            width: 250,
            paddingTop: 12,
          }}
          onClick={() => {
            const w = window.open(`/api/shop/${shopId}/hotel/reports/${d}`, '_blank')
            w.focus()
            //setTimeout(() => w.print(), 200)
          }}
        >
          <i className="icon-File-Download" style={{ fontSize: 32, marginTop: 32 }} />
          <br />
          {format(new Date(d), 'eeee dd.MM.yyyy', t({ pl: { locale: localePl }, en: {} }) )}
        </Button>
      ))}
    </Card>
  </PanelLayout>
}

export default Page
