import React, { useState, useEffect } from 'react'
import { HotelPanelLayout as PanelLayout, t } from '../components/PanelLayout';
import { Form, Button, Input, Card, Select, InputNumber, Tabs, PageHeader, Typography, Divider, Spin, Affix, message, Modal, Row, Col } from "antd";
import { KeyOutlined, QrcodeOutlined, WalletOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { ApiShop } from '../Api';
import { fromPairs, values, reverse, sortBy } from 'lodash';
import { useRequest, useCreation } from "ahooks";
import { format } from 'date-fns'
import { handleSaveError } from '../utils'


const Page = ({ }) => {
  const [form] = Form.useForm()

  const roomsReq = useRequest(() => ApiShop.get('/hotel/rooms'), {
    pollingInterval: 60000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
  })
  const rooms = roomsReq.data && roomsReq.data.data
  const reloadRooms = roomsReq.run

  const byRoom = useCreation(() => rooms && fromPairs(rooms.map(x => [x.room, x])), [rooms])
  const byCard = useCreation(() => rooms && fromPairs(rooms.map(x => [x['qr-id'], x])), [rooms])

  const activeCards = useCreation(() => reverse(sortBy(values(byCard), x => x.check_in_date || '0')), [byCard])

  const doCheckin = (values) => {
    ApiShop.post('/hotel/check-in', values).then(() => {
      Modal.info({
        title: t({pl: 'Karta QR aktywowana', en: 'QR card activated'})
      })
      form.resetFields()
      reloadRooms()
    })
    .catch(handleSaveError("Błąd rejestracji karty QR"))
  }

  const AssignedRoom = ({card}) => {
    if(card && byCard && byCard[card]) {
      const date = byCard[card].check_in_date && new Date(byCard[card].check_in_date)
      return <Typography.Text type="warning">
        Przypisany pokój: <b>{byCard[card].room}</b> <br/>
        {date && <span>od <b>{format(date, "dd/MM/yyyy HH:mm")}</b></span>}
      </Typography.Text>
    }
    return <div/>
  }

  const AssignedCard = ({room}) => {
    if(room && byRoom && byRoom[room] && byRoom[room]['qr-id']) {
      const date = byRoom[room].check_in_date && new Date(byRoom[room].check_in_date)
      return <Typography.Text type="warning">
        Aktualna karta QR: <b>{byRoom[room]['qr-id']}</b> <br/>
        {date && <span>od <b>{format(date, "dd/MM/yyyy HH:mm")}</b></span>}
      </Typography.Text>
    }
    return <div/>
  }

  return <PanelLayout>
    <Card title="Check-in"
      style={{
        textAlign: 'center',
      }}
    >
      <Typography.Text type="warning">
        {t({pl: 'UWAGA!', en: 'ATTENTION!'})}
        <br/>
        {t({pl: 'Aktywacja unieważnia poprzednie karty QR dla podanego numeru pokoju!', en: 'Activation invalidates previous QR cards for the given room number!'})}
      </Typography.Text>
      <Form
        layout="vertical"
        onFinish={doCheckin}
        form={form}
      >
        <Row
          justify="center"
          gutter={24}
          style={{margin: '50px 0'}}
        >
          <Col>
            <p style={{fontSize: 18}}><KeyOutlined /> {t({pl: 'Numer pokoju', en: 'Room number'})}</p>
            <Form.Item
              name="room"
              rules={[
                { required: true, message: 'Podaj numer pokoju' },
                { pattern: /^[1-9]\d*$/, message: 'Sprawdź numer pokoju' }
              ]}
            >
              <Input
                size="large"
                placeholder="000"
                type="tel"
                style={{
                  fontSize: 36,
                  width: 180,
                  textAlign: 'center',
                }}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues.room !== curValues.room}>
              {() => <AssignedCard
                room={form.getFieldValue("room")}
              />}
            </Form.Item>
          </Col>
          <Col>
            <p style={{fontSize: 18}}><QrcodeOutlined /> {t({pl: 'PIN karty QR', en: 'QR card PIN'})}</p>
            <Form.Item
              name="qr-id"
              rules={[
                { required: true, message: 'Podaj PIN karty QR' },
                { pattern: /\d{4}/, message: 'PIN musi mieć 4 cyfry' }
              ]}
            >
              <Input
                size="large"
                placeholder="0000"
                type="tel"
                maxLength={4}
                style={{
                  fontSize: 36,
                  width: 180,
                  textAlign: 'center',
                }}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, curValues) => prevValues['qr-id'] !== curValues['qr-id']}>
              {() => <AssignedRoom
                card={form.getFieldValue("qr-id")}
              />}
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
        >
          {t({pl: 'AKTYWUJ', en: 'ACTIVATE'})}
        </Button>
      </Form>
    </Card>
    <Card title={t({pl: 'Aktywne karty QR', en: 'Active QR cards'})} style={{marginTop: 24}}>
      {activeCards.map(x => x['qr-id'] && <div
        key={x['qr-id']}
        style={{
          display: 'inline-block',
          width: 240,
          border: '1px solid #f0f0f0',
          borderRadius: 12,
          boxShadow: "0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)",
          margin: 12,
          padding: '6px 12px',
          verticalAlign: 'top',
      }}>
        <div style={{
          fontWeight: 'bold',
          fontSize: '1.2em',
          textAlign: 'center',
          marginBottom: 4,
        }}><QrcodeOutlined /> {x['qr-id']}</div>
        <span><WalletOutlined /> {x.room}</span>
        {x.check_in_date && <span style={{float: 'right'}}><ClockCircleOutlined /> {format(new Date(x.check_in_date), "dd/MM/yyyy HH:mm")}</span>}
      </div>)}

    </Card>
  </PanelLayout>
}

export default Page
