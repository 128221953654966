import './App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { Router } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { state } from './models/AppState'
import { pageview } from './analytics'
import { ConfigProvider } from 'antd';
import plPL from 'antd/es/locale/pl_PL';

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

const stores = {
  // Key can be whatever you want
  routing: routingStore,
  state: state
  // ...other stores
};

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactDOM.render(
  <ConfigProvider locale={plPL}>
    <Provider {...stores}>
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
);

history.listen((location) => {
  pageview()
})
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
