
// temporary solution, to be splitted info independend files for each env

const prod = {
  frontend_url: 'https://fablo.pl',
  twoMenusIds: [27],
}

const staging = {
  servicesMenu: true,
  frontend_url: 'https://staging.fablo.pl',
}

const dev = {
  frontend_url: '',
  twoMenusIds: [33],
}


const host = window.location.hostname
const val = {
  'my.fablo.wip': {...staging, ...dev},
  'panel.demo.fablo.pl': staging,
  'panel.staging.fablo.pl': staging,
  'my.fablo.pl': prod
}[host]

export default val || prod
