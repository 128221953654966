import React, { Component, useEffect, useState } from 'react';
import PanelLayout from './PanelLayout'

import { Affix, Button, Card, Col, Form, Input, Descriptions, Row, Select, TimePicker, message, Spin, Upload, Alert, Switch, InputNumber, Steps, Radio } from 'antd';
import { CameraOutlined, EnvironmentOutlined, PlusOutlined, CloseOutlined, TableOutlined, AppstoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import { inject } from 'mobx-react';
import styled from 'styled-components';
import { ApiShop } from '../Api';
import { OnboardingSidebar, OnboardingsSteps } from './Onboarding'
import { useHistory } from "react-router-dom";
import { HelpButton } from './Utils';


const { Option } = Select;
const { RangePicker } = TimePicker;


const getShopData = () => {
    return ApiShop.get('/details').then(x => {
        return {
            ...x.data,
            opening_hours: x.data.opening_hours && x.data.opening_hours.map(a => a ? [moment(a[0], 'HH:mm'), moment(a[1], 'HH:mm')] : [null, null])
        }
    })
}

const saveShopData = (data) => {
    const opening_hours = (data.opening_hours || []).map(x => x && x[0] ? [...x] : null)
    const format = (x) => {
        if (typeof x === 'string') return x;
        return x.format('HH:mm')
    }
    for (var a of (opening_hours || [])) {
        if (a) {
            a[0] = format(a[0])
            a[1] = format(a[1])
        }
    }
    return ApiShop({
        method: 'PUT',
        url: '/details',
        data: {
            ...data,
            opening_hours
        },
    })
}

const validNIP = (nip) => {
    if (!nip) return Promise.resolve();
    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7]
    if (nip.length === 10) {
        var sum = 0;
        for (var i = 0; i < 9; i++) {
            sum += nip[i] * weights[i];
        }
        if ((sum % 11) === Number(nip[9])) {
            return Promise.resolve();
        }
    }
    return Promise.reject('Sprawdź poprawność numeru NIP');
}

const ExtraCheckoutQuestions = ({ form }) => {
    const [inEdit, setInEdit] = useState()

    const Question = ({ field, remove }) => {
        var options = form.getFieldValue(["extra_checkout_questions", field.name, "options"])
        options = options && ('[' + options.join(', ') + ']')
        return <div
            key={field.name}
            style={{
                padding: '0 0 0 14px',
                fontWeight: '600',
                cursor: 'pointer',
            }}>
            <p>
                <span onClick={() => setInEdit(field.name)}>
                    {form.getFieldValue(["extra_checkout_questions", field.name, "label"])} {options}
                </span>
                <a style={{ fontSize: 12, color: '#888', marginLeft: 8 }} onClick={() => remove(field.name)}><CloseOutlined /></a>
            </p>
        </div>
    }

    const QuestionInEdit = ({ field, remove }) => <div
        key={field.name}
        style={{
            marginBottom: 12,
            borderLeft: '2px solid var(--primary)',
            padding: '0 0 0 12px',
        }}
    >
        <p style={{
            color: 'var(--primary)',
            fontWeight: 'bold',
        }}>
            <span>{form.getFieldValue(["extra_checkout_questions", field.name, "label"])}</span>
            <a style={{ fontSize: 12, color: '#888', marginLeft: 8 }} onClick={() => remove(field.name)}><CloseOutlined /></a>
        </p>

        <Form.Item
            name={[field.name, "type"]}
            //style={{ margin: 0 }}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 18, offset: 0 }}
        >
            <Radio.Group >
                <Radio.Button value="radio">Jedna opcja</Radio.Button>
                <Radio.Button value="number">Liczba</Radio.Button>
            </Radio.Group>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(p, n) => !n.extra_checkout_questions[field.name] || p.extra_checkout_questions[field.name].type != n.extra_checkout_questions[field.name].type}>
            {() => form.getFieldValue(["extra_checkout_questions", field.name, "type"]) == "radio" &&
                <Form.List name={[field.name, "options"]}>
                    {(items, { add, remove }) => (
                        <>
                            {items.map((f, i) => (
                                <Radio
                                    key={i}
                                    checked={false}
                                    style={{ display: 'block', lineHeight: "30px" }}
                                >
                                    {form.getFieldValue(["extra_checkout_questions", field.name, "options", f.name])}
                                    <a
                                        onClick={() => remove(f.name)}
                                        style={{ fontSize: 12, marginLeft: 8, color: "#888" }}
                                    >
                                        <CloseOutlined />
                                    </a>
                                </Radio>
                            ))}
                            <Input.Search
                                style={{ maxWidth: 300 }}
                                placeholder="Wpisz odpowiedź"
                                enterButton="Dodaj"
                                onSearch={(x, e) => {
                                    add(x)
                                    e.preventDefault()
                                }}
                            />
                        </>
                    )}
                </Form.List>}
        </Form.Item>
        <Button type="primary" style={{ marginLeft: 8 }} onClick={() => setInEdit(null)}>Zapisz</Button>
    </div>

    return <div>
        <Form.List name="extra_checkout_questions">
            {(items, { add, remove }) => <div>
                <Form.Item
                    label={<p>
                        Dodatkowa opcja personalizowania zamówień dla klientów<br />
                        <span>Np. "Ile dodać sztućców?", "Czy zapakować na prezent?"</span>
                    </p>}
                    //extra='Np. "Czy zapakować na prezent?" albo "Ile dodać sztućców?"'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                >
                    {items.map((field, index) =>
                        inEdit == field.name
                            ? <QuestionInEdit key={field.name} field={field} remove={remove} />
                            : <Question key={field.name} field={field} remove={remove} />
                    )}
                    <Input //.Search
                        placeholder="Zadaj pytanie, na które będzie odpowiadał klient"
                        //enterButton="Dodaj"
                        onPressEnter={(e) => {
                            add({ label: e.target.value })
                            setInEdit(form.getFieldValue(["extra_checkout_questions"]).length - 1)
                            e.preventDefault()
                        }}
                        style={{ maxWidth: 500 }}
                    />
                    <HelpButton
                        title="Dodatkowa opcja personalizowania zamówień dla klientów"
                        content={<>
                            <p style={{textAlign: 'center', fontSize: 16}}>Klient zobaczy pytania/e na końcu formularza podsumowującego zamówienie</p>
                            <img src='/imgs/extra_checkout_questions-help.png' style={{ width: '100%' }} />
                        </>}
                    />
                </Form.Item>
            </div>}
        </Form.List>
    </div>
}



const Wrapper = styled.div`
.ant-form-item-label {
    white-space: normal;
    > label {
        height: auto;
        min-height: 32px;
    }
    > label > p {


        span {
            color: #777;
        }
    }
}

.subform {
    .ant-form-item-label > label {
        padding-left: 12px;
        color: #777;
    }
}
@media (max-width: 575px) {
    .ant-form .ant-form-item .ant-form-item-label.ant-col-xs-16 {
        flex: 0 0 65%;
    }
    .ant-form .ant-form-item .ant-form-item-control.ant-col-xs-8 {
        flex: 0 0 35%;
    }
    .hours .ant-form-item {
        .ant-form-item-label, .ant-form-item-control {
            flex: 0 0 50%;
        }
    }
    .ant-form-item-label > label {
        font-size: 13px;
    }
    .ant-card-body {
        padding: 16px;
    }
    .ant-form-item-control {
        text-align: right;
    }
}

`


const OnboardingInfo = () => {
    return <OnboardingSidebar>
        <h3>Ustawienia</h3>
        <p>1. Informacje zawarte w tej zakładce zapewnią wygodną i szybką obsługę klienta.</p>
        <p>2. Podaj telefon kontaktowy dla klientów jeśli chcesz by mogli oni dzwonić w sprawie złożonych zamówień.</p>
        <p>3. Pamiętaj o tym aby weryfikować dane wpisane tutaj po rozpoczęciu sprzedaży online.</p>
        <p>4. Jeśli widzisz, że pakowanie zamówień zajmuje więcej albo mniej czasu możesz w każdej chwili go zmienić!</p>
        <p>5. Im bardziej precyzyjne będą informacje w tej zakładce tym klienci będą bardziej zadowoleni i zwiększysz ich chęć do powrotu!</p>
    </OnboardingSidebar>
}

const FabloPlanSection = ({ shop }) => {
    const subscription = shop['fablo-subscription']
    if(!subscription || !subscription['offer-id']) {
        return <div/>
    }
    const trialLeft = moment(subscription['trial-end']).diff(moment.now(), 'days')
    return <Card title="Twój plan Fablo" style={{marginBottom: 24 }}>
        <p>Twój plan: <b>{subscription['offer-id']}</b></p>

        {trialLeft > 0 &&
            <p>Pozostało Ci {trialLeft} dni gwarancji. Kliknij <a>tutaj</a> jeśli chcesz zawnioskować o zwrot pieniędzy i usunięcie konta.</p>
        }
    </Card>
}

const SettingsPage = inject('routing', 'state')(({ routing, state }) => {

    const firstTime = routing.location.search && !!routing.location.search.match('first-time')

    const history = useHistory()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true);
    const [shop, setShop] = useState({});
    const companyData = shop.company_data
    const hasOnlinePayments = !!shop.tpay_merchant_id
    
    const onFinish = (values) => {
        setLoading(true)
        saveShopData(values)
            .then(() => {
                if (firstTime) {
                    history.push('/shop?first-time')
                } else {
                    message.success("Zmiany zapisane")
                }
                setLoading(false)
            })
            .catch(() => {
                message.error("Wystąpił błąd podczas zapisywania. Spróbuj później")
                setLoading(false)
            })
    }

    useEffect(() => {
        getShopData().then((data) => {
            form.setFieldsValue(data)
            setLoading(false)
            setShop(data)
        })
    }, [])

    return <PanelLayout
        sidebarContent={firstTime && <OnboardingInfo />}
    >
        {firstTime && <OnboardingsSteps step={1} />}
        <Wrapper>
            <Form
                form={form}
                //layout="horizontal"
                hideRequiredMark
                initialValues={{
                    packing_cost: 0,
                    min_order_value: 0,
                    order_processing_time: 30,
                }}
                onFinish={onFinish}
                onFinishFailed={() => { message.error("Sprawdź poprawność danych w formularzu") }}

                labelCol={{
                    xs: { span: 16 },
                    sm: { span: 14 }
                }}
                wrapperCol={{
                    xs: { span: 8 },
                    sm: { span: 8, offset: 2 }
                }}
                colon={false}
                labelAlign="left"
            >
                <Spin spinning={loading} delay={200}>

                    <Card title="Ustawienia sklepu" style={{ 'marginBottom': '24px' }} bordered={false}>
                        <Row gutter={50}>
                            <Col lg={12} span={24}>
                                <Form.Item
                                    label="Sklep widoczny publicznie"
                                    name="visible"
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
                                </Form.Item>

                                {!firstTime &&
                                    <Form.Item
                                        label="Widok produktów"
                                    >
                                        <Form.Item
                                            noStyle
                                            name="layout"
                                            initialValue="small"
                                        >
                                            <Radio.Group size="large">
                                                <Radio.Button value="small"><TableOutlined style={{ transform: "rotate(90deg)" }} /></Radio.Button>
                                                <Radio.Button value="big"><AppstoreOutlined /></Radio.Button>
                                            </Radio.Group>
                                        </Form.Item>
                                        <HelpButton
                                            title="Widok produktów"
                                            content={<>
                                                <img src='/imgs/layout-help.png' style={{ width: '100%' }} />
                                            </>}
                                        />
                                    </Form.Item>
                                }
                            </Col>
                            <Col lg={12} span={24}>
                                <Form.Item label="Godziny otwarcia" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} className="hours">
                                    <div className="subform">
                                        {[...Array(7).keys()].map(x => (
                                            <Form.Item key={x} label={moment().weekday(x).format("dddd")} name={["opening_hours", x]} labelCol={{ span: 8 }} wrapperCol={{ span: 12 }} style={{ flexDirection: 'row', marginBottom: 12 }}>
                                                <RangePicker format="HH:mm" minuteStep={15} placeholder={["Od", "Do"]} style={{ width: 160 }} />
                                            </Form.Item>
                                        ))}
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Card>

                    <Card title="Ustawienia zamówień" style={{ 'marginBottom': '24px' }} bordered={false}>
                        <Row gutter={32}>
                            <Col lg={12} span={24}>
                                <Form.Item
                                    label="Telefon kontaktowy dla klientów"
                                    name="phone"
                                    type="number"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'wymagane',
                                        },
                                        {
                                            pattern: /^\d+$/,
                                            message: 'Wpisz tylko cyfry'
                                        }
                                    ]}
                                    normalize={(v) => v.replace(/\D/g, '')}
                                >
                                    <Input
                                        style={{
                                            width: 145,
                                        }}
                                        addonBefore="+48"
                                        placeholder="123456789"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Telefon dla powiadomień SMS o zamówieniach"
                                    name="notifications-phone"
                                    type="number"
                                    rules={[{
                                            pattern: /^\d{9}$/,
                                            message: 'Wpisz tylko cyfry'
                                        }
                                    ]}
                                    normalize={(v) => v.replace(/\D/g, '')}
                                >
                                    <Input
                                        style={{
                                            width: 145,
                                        }}
                                        addonBefore="+48"
                                        placeholder="123456789"
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Adres e-mail dla powiadomień o zamówieniach"
                                    name="notifications-email"
                                    rules={[{
                                        type: 'email',
                                        message: 'Wprowadź poprawny adres E-mail',
                                    }]}
                                >
                                    <Input type="email" />
                                </Form.Item>

                                <Form.Item
                                    label="Koszt przygotowania zamówienia"
                                    label={<p>
                                        Koszt przygotowania zamówienia<br />
                                        <span>Podany koszt zostanie doliczony do każdego zamówienia</span>
                                    </p>}
                                    name="packing_cost"
                                    rules={[{
                                        required: true,
                                        message: 'wymagane',
                                    }]}
                                >
                                    <InputNumber
                                        precision={2}
                                        decimalSeparator=","
                                        min={0}
                                        max={100}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Minimalna wartość zamówienia"
                                    name="min_order_value"
                                    rules={[{
                                        required: true,
                                        message: 'wymagane',
                                    }]}
                                >
                                    <InputNumber
                                        precision={2}
                                        decimalSeparator=","
                                        min={0}
                                        max={1000}
                                    />
                                </Form.Item>

                                <Form.Item
                                    label="Przewidywany czas realizacji zamówienia (w&nbsp;minutach)"
                                    name="order_processing_time"
                                >
                                    <InputNumber
                                        precision={0}
                                        min={5}
                                        step={5}
                                        max={3000}
                                    />
                                </Form.Item>

                                <Form.Item
                                    shouldUpdate={(p, n) => p.delivery_available != n.delivery_available}
                                    noStyle
                                >
                                    {() => form.getFieldValue('delivery_available') &&
                                        <Form.Item
                                            label={<p>Przewidywany czas dostawy zamówienia (w&nbsp;minutach)<br />
                                                <span>Czas liczony od skompletowania zamówienia do jego dostarczenia.</span>
                                            </p>}
                                            name="order_delivery_time"
                                        >
                                            <InputNumber
                                                precision={0}
                                                min={5}
                                                step={5}
                                                max={3000}
                                            />
                                        </Form.Item>
                                    }
                                </Form.Item>

                                {!firstTime &&
                                    <ExtraCheckoutQuestions form={form} />
                                }

                            </Col>
                            <Col lg={12} span={24}>
                                <Form.Item
                                    label="Czy Twój biznes oferuje własną dostawę?"
                                    name="delivery_available"
                                    valuePropName="checked"
                                >
                                    <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
                                </Form.Item>

                                <Form.Item
                                    shouldUpdate={(p, n) => p.delivery_available != n.delivery_available}
                                    noStyle
                                >
                                    {() => form.getFieldValue('delivery_available') && <div className="subform">
                                        <Form.Item
                                            label="W jakiej odległości od lokalu (km)"
                                            name="delivery_radius"
                                        >
                                            <InputNumber
                                                precision={1}
                                                min={0.1}
                                                step={0.1}
                                                max={50}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label="Koszt dostawy"
                                            name="delivery_cost"
                                        >
                                            <InputNumber
                                                precision={2}
                                                min={0}
                                                step={1}
                                                max={50}
                                            />
                                        </Form.Item>

                                    </div>
                                    }
                                </Form.Item>

                                <Form.Item label="Akceptowane formy płatności"></Form.Item>
                                <div className="subform">
                                    <Form.Item
                                        label="Płatność online"
                                        name={["available_payment_types", "online"]}
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Tak" unCheckedChildren="Nie" disabled={!hasOnlinePayments} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Gotówka przy odbiorze"
                                        name={["available_payment_types", "collect_cash"]}
                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Karta przy odbiorze"
                                        name={["available_payment_types", "collect_card"]}

                                        valuePropName="checked"
                                    >
                                        <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
                                    </Form.Item>
                                    <Form.Item
                                        shouldUpdate={(p, n) => p.delivery_available != n.delivery_available}
                                        noStyle
                                    >
                                        {() => form.getFieldValue('delivery_available') &&
                                            <>
                                                <Form.Item
                                                    label="Gotówka przy dostawie"
                                                    name={["available_payment_types", "delivery_cash"]}
                                                    valuePropName="checked"
                                                >
                                                    <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
                                                </Form.Item>
                                                <Form.Item
                                                    label="Karta przy dostawie"
                                                    name={["available_payment_types", "delivery_card"]}

                                                    valuePropName="checked"
                                                >
                                                    <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
                                                </Form.Item>
                                            </>
                                        }
                                    </Form.Item>
                                </div>
                            </Col>
                        </Row>
                    </Card>

                    <FabloPlanSection shop={shop}/>
                    
                    <Affix offsetBottom={-8}>
                        <Card bodyStyle={{ paddingTop: 16, paddingBottom: 16, textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit">
                                Zapisz zmiany
                            </Button>
                        </Card>
                    </Affix>

                    {companyData &&
                        <Card title="Dane Twojej firmy" style={{marginBottom: 24 }}>
                            <Descriptions layout="vertical">
                                <Descriptions.Item label="Nazwa">{companyData.name}</Descriptions.Item>
                                <Descriptions.Item label="NIP">{companyData.NIP}</Descriptions.Item>
                                <Descriptions.Item label="REGON">{companyData.REGON}</Descriptions.Item>
                                <Descriptions.Item label="Adres">
                                    {companyData.street} {companyData.streetNumber}{companyData.houseNumber ? `/${companyData.houseNumber}` : ''}, {companyData.city}
                                </Descriptions.Item>
                            </Descriptions>
                        </Card>}
                </Spin>
                {/*</PageHeaderWrapper>*/}
            </Form>
        </Wrapper>
    </PanelLayout >
})

export default SettingsPage
export { validNIP }
