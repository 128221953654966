import React, { useState, useEffect } from 'react'
import { HotelPanelLayout as PanelLayout } from '../components/PanelLayout';
import { TimePicker, Form, Button, Input, Card, Select, InputNumber, Tabs, PageHeader, Typography, Divider, Spin, Affix, message, Modal } from "antd";
import moment from 'moment';

const emails = [
  'manager@hotel.ihg',
  'Jan.Kowalski@hotel.ihg']

const Page = ({ }) => {

  return <PanelLayout>
    <Card title="Ustawienia dokumentów" style={{ marginBottom: 12, opacity: 0.6 }}>
      <div style={{ marginBottom: 24 }}>
        <span>Wysyłka raportu zbiorczego mail</span>
        <TimePicker
          disabledSeconds={() => 0}
          format="HH:mm"
          defaultValue={moment('21:15:', 'HH:mm:ss')}
          style={{ marginLeft: 24 }}
          disabled
        />
        <Select
          mode="tags"
          defaultValue={emails}
          style={{ marginLeft: 24, width: 300 }}
          dropdownRender={() => null}
          disabled
        />
      </div>

      <div style={{ marginBottom: 24 }}>
        <span>Wysyłka raportu zbiorczego sms</span>
        <TimePicker
          disabledSeconds={() => 0}
          format="HH:mm"
          defaultValue={moment('21:15:', 'HH:mm:ss')}
          style={{ marginLeft: 24 }}
          disabled
        />
        <Select
          mode="tags"
          defaultValue={['+48 600 123 123']}
          style={{ marginLeft: 24, width: 300 }}
          dropdownRender={() => null}
          disabled
        />
      </div>

      <div style={{ marginBottom: 24 }}>
        <span>Wysyłki Tabel dla Kuchni (PDF)</span>
        <TimePicker
          disabledSeconds={() => 0}
          format="HH:mm"
          defaultValue={moment('21:15:', 'HH:mm:ss')}
          style={{ marginLeft: 24 }}
          disabled
        />
        <Select
          mode="tags"
          defaultValue={emails}
          style={{ marginLeft: 24, width: 300 }}
          dropdownRender={() => null}
          disabled
        />
      </div>

    </Card>
    <Card title="Ustawienia dostępów" style={{ opacity: 0.6 }}>

      <div style={{ marginBottom: 24 }}>
        <span>Zakadka "Zamówienia"</span>
        <Select
          mode="tags"
          defaultValue={['recepcja@hotel.ihg', 'kuchnia@hotel.ihg']}
          style={{ marginLeft: 24, width: 300 }}
          dropdownRender={() => null}
          disabled
        />
      </div>

    </Card>
  </PanelLayout>
}

export default Page
