import axios from 'axios';
import { state } from './models/AppState'

const Api = axios.create({
    baseURL: '/api',
});



const checkAuthError = (data,res) => {
    if(res.status == 401 || res.status == 403) {
        state.logout()
        // return url after login
    }
    return data
}

var ApiShop = axios.create({
    baseURL: `/api/shop/${state.session.shopId}`,
    transformResponse: [...axios.defaults.transformResponse, checkAuthError]
});

const getShopId = () => state.session.shopId

const createApiShop = () => {
    ApiShop = axios.create({
        baseURL: `/api/shop/${state.session.shopId}`,
        transformResponse: [...axios.defaults.transformResponse, checkAuthError]
    });
}

export {ApiShop, Api, createApiShop, getShopId}
