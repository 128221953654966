import React, { useEffect, useState } from 'react';
import PanelLayout from './PanelLayout'

import { Affix, Button, Card, Col, Form, Input, Row, message, Spin, Upload, Popover } from 'antd';
import { CameraOutlined, EnvironmentOutlined, InstagramOutlined, FacebookOutlined, EyeOutlined } from '@ant-design/icons';
import { inject } from 'mobx-react';
import styled from 'styled-components';
import { ApiShop } from '../Api';
import { OnboardingSidebar, OnboardingsSteps } from './Onboarding'
import { useHistory } from "react-router-dom";


export const geocode = (address) => {
    const geocoder = new window.google.maps.Geocoder();
    const OK = window.google.maps.GeocoderStatus.OK;
    return new Promise((resolve, reject) => {
        geocoder.geocode({ address }, (results, status) => {
            if (status !== OK) {
                reject(status);
            }
            resolve(results);
        });
    });
};

const getShopData = () => {
    return ApiShop.get('/details').then(x => {
        return x.data
    })
}

const saveShopData = (data) => {
    return ApiShop({
        method: 'PUT',
        url: '/details',
        data
    })
}

// todo
const debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}


const checkImage = () => ApiShop.head('/image')

const Wrapper = styled.div`
.ant-upload {
    width: 100%;
    border: none;
    > .ant-upload {
        padding: 0;
        img {
            border-radius: 6px;
        }
    }
}
.upload-placeholder {
    width: 100%;
    border: 1px dashed #888;
    border-radius: 12px;
    padding: 80px 12px;
    .anticon-camera {
        font-size: 32px;
    }
}
.ant-upload.ant-upload-select-picture-card { height: auto; }
@media(max-width: 768px) {
    .ant-card-head { display: none; }
}
`


const OnboardingInfo = () => {
    return <OnboardingSidebar>
        <h3>Wizytówka biznesu</h3>
        <p>1. Jest to jeden z dwóch sposobów na przedstawienie swojego biznesu klientowi (drugim jest Twoja oferta).</p>
        <p>2. Wypełnij wizytówkę jak najdokładniej i starannie aby zaciekawić klienta oraz zachęcić go do przejrzenia oferty.</p>
        <p>3. Znajdzie się tutaj miejsce na to aby opowiedzieć o historii biznesu oraz wizji i umieścić linki do kont na social mediach.</p>
        <p>4. Będzie ona widoczna po kliknięciu na kafelek sklepu podczas wyszukiwania na stronie głównej fablo.pl bądź po kliknięciu na zdjęcie biznesu podczas przeglądania oferty.</p>
        <p>5. Wizytówkę sklepu możesz udostępniać w internecie aby powiadomić klientów iż mogą odwiedzać Cię online. Będzie ona dostępna pod indywidualnym linkiem URL.</p>
    </OnboardingSidebar>
}

const ShopPage = inject('routing', 'state')(({ routing, state }) => {

    const firstTime = routing.location.search && !!routing.location.search.match('first-time')

    const [form] = Form.useForm()
    const [loading, setLoading] = useState(true);
    const [mapUrl, setMapUrl] = useState("");
    const [hasImage, setHasImage] = useState(false)
    const [companyData, setCompanyData] = useState()
    const [savedData, setSavedData] = useState({})
    const history = useHistory()

    const fetchShopData = () => {
        getShopData().then((data) => {
            form.setFieldsValue(data)
            setSavedData(data)
            updateMapPreview()
            setLoading(false)
            setCompanyData(data.company_data)
        })
        checkImage().then(() => setHasImage(true)).catch(() => { })
    }

    useEffect(() => {
        fetchShopData()
    }, [])


    const onFinish = (values) => {
        setLoading(true)
        const save = (values) => {
            saveShopData(values)
                .then(() => {
                    if(firstTime) {
                        history.push('/add-product?first-time')
                    } else {
                        message.success("Zmiany zapisane")
                    }
                    setSavedData(values)
                    fetchShopData()
                })
                .catch(() => {
                    message.error("Wystąpił błąd podczas zapisywania. Spróbuj później")
                    setLoading(false)
                })
        }

        if (savedData.city != values.city || savedData.street != values.street || savedData.postcode != values.postcode) {
            geocode(values.street + ", " + values.postcode + " " + values.city).then((results) => {
                const result = results[0]
                const lat = result.geometry.location.lat()
                const lng = result.geometry.location.lng()
                save({ ...values, lat, lng })
            }).catch((e) => {
                console.warn("Err", e)
                message.error("Adres sklepu nieznaleziony. Spróbuj później lub skontaktuj się z nami.")
                setLoading(false)
            })
        } else {
            save(values)
        }
    }

    const updateMapPreview2 = () => {
        const postcode = form.getFieldValue('postcode')
        const city = form.getFieldValue('city')
        const street = form.getFieldValue('street')
        var params = `markers=${encodeURIComponent(street + ", " + postcode + " " + city)}`
        params += "&zoom=15"
        params += "&size=300x300"
        // todo: &scale=2 for hdpi
        params += `&key=${encodeURIComponent('AIzaSyCYyiJQ4TS9LHuCTfw-9KXu0eRDhsTVkNA')}`
        if (city && street) {
            setMapUrl(params)
        } else {
            setMapUrl("")
        }
    }
    const updateMapPreview = debounce(updateMapPreview2, 1000)

    const MapPreview = () => {
        if (mapUrl) {
            return <img
                src={`//maps.googleapis.com/maps/api/staticmap?${mapUrl}`}
            />
        } else {
            return <div />
        }
    }

    const slug = savedData.slugs && savedData.slugs[0]

    return <PanelLayout
        sidebarContent={firstTime && <OnboardingInfo/>}
    >
        {firstTime && <OnboardingsSteps step={2} /> }
        <Wrapper>
            <Form
                form={form}
                //layout="horizontal"
                hideRequiredMark
                initialValues={{
                    packing_cost: 0,
                    min_order_value: 0,
                    order_processing_time: 30,
                }}
                onFinish={onFinish}
                onFinishFailed={() => { message.error("Sprawdź poprawność danych w formularzu") }}
                onValuesChange={(a, b) => updateMapPreview()}

                colon={false}
                labelAlign="left"
            >
                <Spin spinning={loading} delay={200}>
                    <Card title="Wizytówka biznesu" style={{ 'marginBottom': '24px' }} bordered={false}>

                        <Row justify="center">
                            <Form.Item
                                name="name"
                                validateFirst
                                rules={[{
                                    required: true,
                                    message: 'Pole wymagane',
                                }, {
                                    pattern: /[a-zA-Z]/,
                                    message: 'Nazwa musi zawierać litery'
                                }, {
                                    min: 3,
                                    message: 'Nazwa jest za krótka'
                                }, {
                                    validator: (_, name) => ApiShop.post('/check-shop-name', { name }),
                                    message: 'Ta nazwa zajęta'
                                }]}
                            >
                                <Input
                                    style={{
                                        maxWidth: 300,
                                    }}
                                    placeholder="Nazwa sklepu"
                                />
                            </Form.Item>
                        </Row>
                        <Row justify="center" gutter={8}>
                            <Col>
                                <Form.Item
                                    name="street"
                                    rules={[{
                                        required: true,
                                        message: 'Pole wymagane',
                                    }]}
                                >
                                    <Input
                                        style={{
                                            width: 200,
                                        }}
                                        placeholder="Ulica numer"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="city"
                                    rules={[{
                                        required: true,
                                        message: 'Pole wymagane',
                                    }]}
                                >
                                    <Input
                                        style={{
                                            width: 150,
                                        }}
                                        placeholder="Miasto"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    name="postcode"
                                    rules={[{
                                        required: true,
                                        message: 'Pole wymagane',
                                    }, {
                                        pattern: /^\d\d-\d\d\d+$/,
                                        message: 'Wpisz kod pocztowy w formacie 00-000'
                                    }]}
                                >
                                    <Input
                                        style={{
                                            width: 120,
                                        }}
                                        placeholder="Kod pocztowy"
                                    />
                                </Form.Item>
                            </Col>
                            <Col>
                                {!!form.getFieldValue('city') && !!form.getFieldValue('street') &&
                                    <Popover
                                        content={() => <MapPreview />}
                                    >
                                        <EnvironmentOutlined style={{ fontSize: 24, marginTop: 4 }} />
                                    </Popover>}
                            </Col>

                        </Row>
                        <Row gutter={24}>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="img"
                                >
                                    <Upload
                                        name="file"
                                        listType="picture-card"
                                        showUploadList={false}
                                        action={`/api/shop/${state.session.shopId}/image`}
                                        onChange={(e) => {
                                            if (e.file.status == 'done') {
                                                setHasImage(true)
                                            }
                                        }}
                                    >
                                        {hasImage
                                            ? <img src={`/api/shop/${state.session.shopId}/image`} style={{ width: '100%' }} />
                                            : <div className="upload-placeholder">
                                                <CameraOutlined />
                                                <div className="ant-upload-text">
                                                    Dodaj zdjęcie <br/>
                                                    <span style={{opacity: 0.5}}>(min. 600x450px)</span>
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                                <Form.Item
                                    name="description"
                                >
                                    <Input.TextArea
                                        style={{
                                            width: '100%',
                                        }}
                                        maxLength={200}
                                        placeholder="Opis 200 znaków"
                                        autoSize={{minRows: 4}}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24} md={12}>
                                <Form.Item
                                    name="long_description"
                                >
                                    <Input.TextArea
                                        style={{
                                            width: '100%',
                                        }}
                                        maxLength={600}
                                        placeholder="Opis 600 znaków"
                                        autoSize={{minRows: 8}}
                                    />
                                </Form.Item>

                                { !firstTime &&
                                    <Form.Item
                                        label={<p style={{fontFamily: 'Courier', fontSize: 18, paddingTop: 12}}>www</p>}
                                    >
                                        <a href={slug ? 'https://fablo.pl/' + slug : ''} target="_blank">
                                        <Input
                                            value={slug ? 'fablo.pl/' + slug : ''}
                                            disabled
                                            style={{cursor: 'pointer'}}
                                        />
                                        </a>
                                    </Form.Item>
                                }
                                <Form.Item
                                    label={<InstagramOutlined style={{fontSize: 28, width: 32}}/>}
                                    name={["socials", "instagram"]}
                                >
                                    <Input
                                        addonBefore="instagram.com/"
                                    />
                                </Form.Item>
                                <Form.Item
                                    label={<FacebookOutlined style={{fontSize: 28, width: 32}}/>}
                                    name={["socials", "facebook"]}
                                >
                                    <Input
                                        addonBefore="facebook.com/"
                                    />
                                </Form.Item>

                            </Col>
                        </Row>
                    </Card>

                    <Affix offsetBottom={-8}>
                        <Card bodyStyle={{ paddingTop: 16, paddingBottom: 16, textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit">
                                Zapisz zmiany
                            </Button>

                            { !firstTime &&
                                <a href={window.location.origin.replace(/my\.|panel\./, '') + `/${savedData.id}` } target="_blank">
                                    <Button icon={<EyeOutlined />} style={{marginLeft: 26}}>
                                        Zobacz wizytówkę
                                    </Button>
                                </a>
                            }
                        </Card>
                    </Affix>
                </Spin>
            </Form>
        </Wrapper>
    </PanelLayout>
})

export default ShopPage
