import { getShopId } from './Api';
import { message } from "antd"

const imageUrl = (id, type) => {
    if (!type) { return; }
    const host = "//" + window.location.host.replace('my.', '').replace('panel.', '').replace(':8082', ':8083')
    if (type == "custom") {
        return host + `/cust-images/${getShopId()}/${id}.jpg`
    }
    if (type == 'bio') {
        return host + `/bio-images/${id}.jpg`
    }
    if (type.match(/^fruit:/)) {
        return host + `/fruits-images/${(type.match(/^fruit:(.+)$/) || [])[1]}.jpg`
    }
    return host + `/db-images/${id}-${type}.jpg`
}

const formatPrice = (x) => x.toFixed(2).replace('.', ',')

const formatQuantity = (x) => x.toFixed(1).replace('.', ',').replace(',0', '')

const quantityStep = (unit) => unit == 'kg' ? 0.1 : 1;

const debounce = (callback, wait) => {
    let timeout;
    return (...args) => {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => callback.apply(context, args), wait);
    };
}

const paymentDone = (order) => order.paid
const paymentPending = (order) => order.payment_type == 'online' && !order.paid
// fixme: use or remove
const paymentError = (order) => false //order.payment_result && (order.payment_result.status == "ERROR" || order.payment_result.status == "error")


const handleSaveError = (msg) => (error) => {
    if(error.response?.data?.error == "Read only access") {
        message.warn("Thank you for testing the panel. Changes made to the demo will not be saved.")
    } else {
        message.error(msg || "Wystąpił błąd podczas zapisywania. Spróbuj później")
    }
}

export {
    imageUrl,
    formatPrice,
    formatQuantity,
    debounce,
    paymentDone,
    paymentPending,
    paymentError,
    quantityStep,
    handleSaveError,
}
