import { CheckCircleFilled, DeleteOutlined, ExclamationCircleOutlined, MinusCircleFilled, QuestionCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Card, Checkbox, Col, Empty, Form, Input, InputNumber, message, Modal, Row, Spin, Tooltip } from 'antd';
import GoogleMapReact from 'google-map-react';
import { inject, observer } from 'mobx-react';
import 'moment/locale/pl';
import moment from 'moment'
import React, { useEffect, useMemo, useState } from 'react';
import Moment from 'react-moment';
import styled from 'styled-components';
import { ApiShop } from '../Api';
import { state } from '../models/AppState'; // todo inject
import { debounce, formatPrice, formatQuantity, paymentDone, paymentError, paymentPending, quantityStep } from '../utils';
import PanelLayout from './PanelLayout';
import PinInput from 'react-pin-input';
import { useRef } from 'react';
import { useParams } from 'react-router';


const FlexH = styled.div`
display: flex;
justify-content: space-between;
`

const OrdersContainer = styled.div`
display: flex;
flex-wrap: wrap;
`

const ProductWrapper = styled.div`
padding: 12px 0;
`

const OrderDescription = styled.div`
margin-bottom: 12px;
> div {
    margin-bottom: 8px;
}
`

const OtherCosts = ({ order }) => {
    const costs = order.other_costs
    if (!costs) return <div />
    const res = []
    const Line = ({ name, price }) => <FlexH style={{ padding: "4px 0", alignItems: 'start' }}>
        <div style={{ flex: 1, marginLeft: 24 }}><small>{name}</small></div>
        <div style={{ marginRight: 8 }}>{formatPrice(price)} zł</div>
    </FlexH>


    if (costs.delivery) res.push(<Line key="delivery" name="Koszt dostawy" price={costs.delivery} />)
    if (costs.packing) res.push(<Line key="packing" name="Koszt pakowania" price={costs.packing} />)

    return <div>{res}</div>
}


const ProductCheckbox = styled(Checkbox)`
flex: 1;
margin-right: 12px;
display: flex;
align-items: flex-start;

.ant-checkbox {
    margin-top: 5px;
}
.ant-checkbox-inner {
    width: 28px;
    height: 28px;
    &::after {
        left: 35%;
    }
}
.ant-checkbox-indeterminate .ant-checkbox-inner {
    border-color: var(--primary);
    &::after {
        left: 50%;
    }
}
.ant-checkbox-disabled {
    .ant-checkbox-inner {
        border-color: #ff4d4f !important;
    }
    &.ant-checkbox-checked .ant-checkbox-inner {
        border-color: var(--primary) !important;
        background-color: var(--primary);
    }
    &.ant-checkbox-indeterminate .ant-checkbox-inner {
        border-color: var(--primary) !important;
        &::after {
            background-color: var(--primary);
        }
    }
    .ant-checkbox-inner::after {
        border-color: #fff;
    }
}
`

const ProductLineNumbers = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    @media(min-width: 576px) {
        //min-width: 175px;
    }
`

const ProductLine = ({ product, setFinalQuantity }) => {

    const quantity = Math.round(product.quantity * 10) / 10
    const final_quantity = Math.round(product.final_quantity * 10) / 10

    return <ProductWrapper key={product.id}>
        <FlexH style={{ display: 'inline-flex', width: '100%', alignItems: 'start' }}>

            <ProductCheckbox
                onChange={(e) => setFinalQuantity && setFinalQuantity(e.target.checked ? quantity : 0)}
                checked={final_quantity == quantity}
                indeterminate={final_quantity > 0 && final_quantity < quantity}
                disabled={!setFinalQuantity}
                style={{ marginRight: 12 }}
            >

                <p style={{ marginBottom: 4 }}>{product.name} <small>{product.id}</small></p>

                {(product.customizations || []).map((v) => <p key={v.id} style={{ margin: 0, fontSize: 12 }}>
                    {v.title}: <b>{(v.options || []).map(x => x.label + (x.price ? ` (${formatPrice(x.price)} zł)` : '')).join(', ')}</b>
                </p>)}
            </ProductCheckbox>

            <ProductLineNumbers>
                <div style={{ fontSize: "22px" }}>
                    {!setFinalQuantity &&
                        <span>{formatQuantity(final_quantity)}</span>}
                    {setFinalQuantity &&
                        <InputNumber
                            min={0}
                            max={quantity}
                            step={quantityStep(product.unit)}
                            precision={quantityStep(product.unit) == 1 ? 0 : 1}
                            value={final_quantity}
                            onChange={setFinalQuantity}
                            style={{ width: 60, verticalAlign: 'bottom' }}
                            decimalSeparator=","
                        />}
                    <span>/{formatQuantity(quantity)}</span>
                </div>
                <div style={{ marginLeft: 4 }}>
                    <span className="only-desktop">{product.unit}</span> × {formatPrice(product.unit_price)} zł
                    </div>
            </ProductLineNumbers>
        </FlexH>
    </ProductWrapper>
}

const setOrderNote = (order, note) => ApiShop.put(`/order/${order.id}`, { note })
const setNote = debounce((order, note) => setOrderNote(order, note), 3000)

const setOrderStatus = (order, status, extraData) => {
    ApiShop.put(`/order/${order.id}`, { status, ...extraData })
        .then(() => {
            message.success("Status zamówienia zmieniony")
            state.fetchOrders()
        })
        .catch(() => { message.error("Nie udało się zmienić statusu zamówienia. Spróbuj ponownie później.") })

}


const markAsReady = (order, onOk, toRefund) => {
    const onSuccess = () => {
        onOk && onOk();
        setOrderStatus(order, 'ready')
    }
    
    const refundNotice = toRefund >= 0.01 && <div style={{marginTop: 32}}>
        <ExclamationCircleOutlined style={{
            color: '#faad14',
            position: 'absolute',
            left: 32,
            fontSize: 22,
        }}/>
        <p>
        Za nieodznaczone produkty zostanie wykonany zwrot środków na kwotę <b>{formatPrice(toRefund)} zł</b>
    </p></div>

    Modal.confirm({
        title: "Zamówienie jest gotowe do odbioru i można wysłać powiadomienie do klienta?",
        content: refundNotice || null,
        icon: <QuestionCircleOutlined />,
        okText: "Tak",
        cancelText: "Nie",
        onOk: onSuccess
    })

}

const markAsPending = (order, onOk) => {
    Modal.confirm({
        title: "Przywracanie zamówienia do skompletowania",
        content: "Czy chcesz przenieść zamówienie do zakładki \"Do skompletowania\"?",
        icon: <QuestionCircleOutlined />,
        okText: "Tak",
        cancelText: "Nie",
        onOk: () => {
            onOk && onOk();
            setOrderStatus(order, 'pending')
        }
    })
}

const PinCodePopup = ({ visible, order, onClose }) => {
    const [pinCorrect, setPinCorrect] = useState(false)
    const pinInputRef = useRef()

    const verifyPin = (pin) => {
        return ApiShop.post(`/order/${order.id}/verify-pin`, { pin }).then(() => true).catch(() => false)
    }

    return <Modal
        title={`Wydawanie zamówienia numer #${order.id}`}
        visible={visible}
        footer={null}
        onCancel={() => onClose()}
    >
        <div style={{ textAlign: 'center' }}>
            {!paymentDone(order) &&
                <p style={{ color: 'rgb(231, 48, 28)', fontWeight: 600 }}>
                    Zamówienie NIE OPŁACONE,<br /> pobierz płatność od klienta przed wydaniem.
                </p>
            }
            <p style={{ margin: '24px 0' }}>
                Wpisz PIN odbioru zamówienia. Znajduje się w mailu klienta potwierdzającym możliwość odbioru zamówienia.
        </p>
            <PinInput
                length={4}
                focus
                ref={pinInputRef}
                type="numeric"
                disabled={!!pinCorrect}
                inputStyle={{
                    border: '2px solid #d9d9d9',
                    borderRadius: 12,
                    fontSize: 28,
                }}
                style={{
                    margin: '48px 0'
                }}
                onComplete={(pin) => {
                    verifyPin(pin).then((res) => {
                        setPinCorrect(res && pin)
                        if (!res) {
                            message.error('Błędny kod PIN')
                            pinInputRef.current.clear()
                        }
                    })
                }}
            />
            <div>
                <Button
                    onClick={() => onClose()}
                >
                    Zamknij
                </Button>
                <Button
                    type="primary"
                    disabled={!pinCorrect}
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                        setOrderStatus(order, 'completed', { pin: pinCorrect })
                        onClose(true)
                    }}
                >
                    Wydaj zamówienie
            </Button>
            </div>
        </div>

    </Modal>
}

const markAsCancelled = (order, onOk) => {

    var formRef = null
    const CancelForm = () => {
        const [cancelForm] = Form.useForm()
        formRef = cancelForm
        return <Form
            form={cancelForm}
            layout="vertical"
            onFinish={(values) => {
                onOk && onOk();
                setOrderStatus(order, 'cancelled', { 'cancel_reason': values.cancel_reason })
                Modal.destroyAll()
            }}
        >
            <div>
                Zamówienie zostanie przeniesione do zakładki "Zakończone"
                    {order.payment_type != 'online' ?
                    <div style={{ fontWeight: 600, color: 'var(--primary)' }}>
                        Anulowanie zamówienia nie opłaconego online można cofnąć.
                    </div>
                    :
                    <div style={{ fontWeight: 600, color: '#DC5534' }}>
                        Anulowanie zamówienia opłaconego online wiąże się ze zwrotem środków w związku z czym nie można go cofnąć.
                    </div>
                }
            </div>

            <Form.Item
                style={{ marginTop: 12 }}
                name="cancel_reason"
                label="Powód anulowania"
                rules={[{
                    required: true,
                    message: 'Pole wymagane',
                }]}
            >
                <Input.TextArea placeholder="Podaj powód" />
            </Form.Item>
        </Form>
    }

    Modal.confirm({
        title: "Anulowanie zamówienia",
        content: <CancelForm />,
        icon: <QuestionCircleOutlined />,
        okText: "ANULUJ ZAMÓWIENIE",
        okType: 'danger',
        cancelText: "ZAMKNIJ",
        onOk: (close) => {
            formRef.submit()
        }
    })
}

const paymentTypes = {
    collect_card: 'kartą przy odbiorze',
    collect_cash: 'gotówką przy odbiorze',
    delivery_card: 'kartą przy dostawie',
    delivery_cash: 'gotówką przy dostawie',
    online: 'online',
}

const paymentStatus = (order) => {
    if (order.payment_data) {
        if (paymentDone(order)) return `(opłacone: ${order.paid && formatPrice(order.paid)} zł)`
        if (paymentPending(order)) return "(oczekuje na płatność)"
        if (paymentError(order)) return "(błąd płatności)"
    } else {
        return ''
    }
}

const Refunds = ({ order }) => {
    const reason = (x) => x == 'order cancelled' ? 'Anulowanie zamówienia' : x

    if (order.payment_result && order.payment_result.refunds) {
        return <div style={{ margin: '12px 0' }}>
            <p style={{ margin: 0 }}>Wykonano zwrot płatności:</p>
            {order.payment_result.refunds.map((x, i) => <div key={i} style={{ marginLeft: 16 }}>
                <b>-{formatPrice(x.amount)} zł</b> ({reason(x.reason)})
            </div>)}
        </div>
    } else {
        return <div />
    }
}

const PaymentIcon = ({ order }) => {

    const icon = ['online', 'collect_card', 'delivery_card'].includes(order.payment_type) ? 'Credit-Card2' : 'Coins-3'
    const status = (order.status == 'completed' || ((order.payment_type == 'online') && paymentDone(order))) ? 'done'
        : ((order.payment_type == 'online') && paymentPending(order)) ? 'pending'
            : 'todo'

    return <div style={{ position: 'relative', marginTop: 12 }}>
        <Tooltip
            title={'Płatność ' + paymentTypes[order.payment_type] + ' ' + paymentStatus(order)}
            placement="bottomLeft"

        >
            <span
                className={'icon-' + icon}
                style={{ fontSize: 32 }}
            />
            <div style={{ position: 'absolute', right: -12, top: -12, fontSize: 20 }}>
                {status == 'todo' && <MinusCircleFilled style={{ color: 'rgb(231, 48, 28)' }} />}
                {status == 'done' && <CheckCircleFilled style={{ color: '#2E773A' }} />}
                {status == 'pending' && <QuestionCircleFilled style={{ color: '#ED6600' }} />}
            </div>

        </Tooltip>
    </div>
}

const OrderId = styled.div`
font-size: 20px;
color: #000;
`
const Num = styled.span`
    font-size: 20px;
    color: #000;
`
const Time = styled.div`
    color: #2E773A;
    font-size: 20px;
`

const Counter = ({ current, total, label }) => {
    return <div>
        <span style={{
            color: '#888',
            verticalAlign: 'super',
            marginRight: 6,
            fontWeight: 600,
        }}>{label}</span>
        <Num>{current}</Num>
        {total && <span> / <small>{total}</small></span>}
    </div>
}

const OrderBoxWrapper = styled.div`
border: 1px solid #f0f0f0;
margin: 12px;
width: 300px;
@media(max-width: 710px) {
    width: 100%;
    margin: 12px 0px;
}
`
const OrderBoxSummary = styled.div`
padding: 24px 12px;
cursor: pointer;
`
const Notes = styled.div`
border-top: 1px solid #f0f0f0;
padding: 24px 12px;
`
const DeliveryLabel = styled.div`
border-radius: 6px;
background-color: ${props => props.delivery ? "#ED6600" : "#2E773A"};
color: #fff;
font-size: 11px;
font-weight: bold;
padding: 6px 20px;
`
const OrderRowDeliveryLabel = styled.div`
    border-radius: 6px;
    color: ${props => (props.delivery || props.orange) ? "#ED6600" : "#2E773A"};
    font-size: 11px;
    font-weight: bold;
    padding: 6px 20px;
    border: 2px solid #f0f0f0;
    min-width: 100px;
    text-align: center;
`


const polishPlural = (singularNominativ, pluralNominativ, pluralGenitive, value) => {
    if (value === 1) {
        return singularNominativ;
    } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 >= 20)) {
        return pluralNominativ;
    } else {
        return pluralGenitive;
    }
}

const Timer = ({ time, add }) => {
    const [val, setVal] = useState('');

    const update = () => {
        const deadline = moment(time).add(add, 'minutes')
        const minutes = deadline.diff(moment(), 'minutes')
        const hours = deadline.diff(moment(), 'hours')
        const days = deadline.diff(moment(), 'days')
        if(Math.abs(minutes) <= 60) return setVal(minutes + ' ' + polishPlural('minuta', 'minuty', 'minut', minutes))
        if(Math.abs(hours) <= 24) return setVal(hours + ' ' + polishPlural('godzina', 'godziny', 'godzin', hours))
        setVal(days + ' ' + polishPlural('dzień', 'dni', 'dni', hours))
    }

    useEffect(() => {
      update()
      const interval = setInterval(update, 60 * 1000);
      return () => clearInterval(interval);
    }, []);

    if(val[0] == '-') {
        return <span style={{ color: '#DC5534' }}>{val}</span>
    }
    return <span>{val}</span>
}

const OrderBox = ({ order, orderProcessingTime }) => {
    const params = useParams();

    const [opened, setOpened] = useState(params.order_id == order.id)

    var total = order.total
    if (!total) { // todo: remove, only for old orders without :total
        total = 0
        order.products = order.products || []
        order.products.forEach(p => total += p.unit_price * p.quantity)
    }

    return <OrderBoxWrapper>
        <OrderBoxSummary onClick={() => setOpened(true)}>
            <Row justify="space-between" align="middle">
                <Col>
                    <OrderId>#{order.id}</OrderId>
                </Col>
                <Col>
                    <DeliveryLabel delivery={!!order.delivery_address}>
                        {order.delivery_address ? 'DOSTAWA' : 'ODBIÓR'}
                    </DeliveryLabel>
                </Col>
                <Col>
                    <Time>
                        <Timer
                            add={orderProcessingTime}
                            time={order['created-at']}
                        />
                    </Time>
                </Col>
            </Row>
            <Row justify="space-around" align="middle" style={{ marginTop: 36 }}>
                <Col>
                    <Counter label="Ilość" current={order.products.length} />
                </Col>
                <Col>
                    <PaymentIcon order={order} />
                </Col>
                <Col>
                    <Counter label="PLN" current={formatPrice(total)} />
                </Col>
            </Row>
        </OrderBoxSummary>
        <Notes>
            {order.extra_message && <div style={{
                marginBottom: 12,
                color: '#888'
            }}>
                Notatki: {order.extra_message}
            </div>}
            <Input.TextArea
                placeholder="Notatki"
                style={{
                    borderRadius: 0,
                    border: 'none',
                    background: '#f6f6f6'
                }}
                defaultValue={order.note}
                onChange={(e) => setNote(order, e.target.value)}
            />
        </Notes>

        <OrderPopup
            visible={opened}
            order={order}
            onClose={() => setOpened(false)}
            orderProcessingTime={orderProcessingTime}
        />
    </OrderBoxWrapper>
}

const OrderRowWrapper = styled.div`
border: 1px solid #f0f0f0;
margin: 12px;
padding: 12px 12px;
cursor: pointer;

@media(max-width: 710px) {
    margin: 12px 0px;
}
`

const OrderRow = ({ order, orderProcessingTime }) => {
    const [opened, setOpened] = useState(false)

    var total = order.final_total
    if (!total) { // todo: remove, only for old orders without :final_total
        total = 0
        order.products = order.products || []
        order.products.forEach(p => total += p.unit_price * (p.final_quantity || 0))
        Object.values(order.other_costs || {}).forEach(x => total += x)
    }

    return <div>
        <OrderRowWrapper onClick={() => setOpened(true)}>
            <Row justify="space-around" align="middle">
                <Col style={{ minWidth: 50 }}>
                    <OrderId>#{order.id}</OrderId>
                </Col>
                {(order.status == 'completed' || order.status == 'cancelled') &&
                    <Col style={{width: 130}}>
                        {order.status == 'completed' && <OrderRowDeliveryLabel>ZREALIZOWANE</OrderRowDeliveryLabel>}
                        {order.status == 'cancelled' && <OrderRowDeliveryLabel orange>ANULOWANE</OrderRowDeliveryLabel>}
                    </Col>}
                <Col>
                    <OrderRowDeliveryLabel>{order.delivery_address ? 'DOSTAWA' : 'ODBIÓR'}</OrderRowDeliveryLabel>
                </Col>
                <Col style={{ minWidth: 105 }}>
                    <Time>
                        {(order.status == 'completed' || order.status == 'cancelled' || !order.delivery_address)
                            ? <Moment
                                locale="pl"
                                calendar={{
                                    lastDay: 'L',
                                    sameDay: 'LT',
                                    nextDay: 'L',
                                    lastWeek: 'L',
                                    nextWeek: 'L',
                                    sameElse: 'L'
                                }}
                                style={{ color: '#000' }}
                            >{order['created-at']}</Moment>
                            : <Timer
                                add={orderProcessingTime}
                                time={order['created-at']}
                            />}
                    </Time>
                </Col>

                <Col style={{ width: 32 }}>
                    <PaymentIcon order={order} />
                </Col>
                <Col style={{ minWidth: 96, textAlign: 'right' }}>
                    <Counter label="PLN" current={formatPrice(total)} />
                </Col>
                <Col style={{ minWidth: 80 }}>
                    <Counter label="Ilość" current={order.products.length} />
                </Col>
            </Row>
        </OrderRowWrapper>
        <OrderPopup
            visible={opened}
            order={order}
            onClose={() => setOpened(false)}
            orderProcessingTime={orderProcessingTime}
        />
    </div>

}

const OrderPopupDeliveryLabel = styled.div`
border-radius: 6px;
background-color: ${props => props.delivery ? "#ED6600" : "#2E773A"};
color: #fff;
font-size: 11px;
font-weight: bold;
padding: 6px 20px;
`

const OrderPopupBody = ({ order, onClose, orderProcessingTime }) => {

    const [finalQuantities, setFinalQuantities] = useState(() => {
        const v = {};
        (order.products || []).forEach((p, i) => {
            v[i] = p.final_quantity
        })
        return v
    })

    const [pinCodePopupVisible, setPinCodePopupVisible] = useState(false)

    const setFinalQuantity = (idx) => (quantity) => {
        const old = finalQuantities
        setFinalQuantities({
            ...finalQuantities,
            [idx]: quantity
        })
        ApiShop.put(`/order/${order.id}`, { final_quantities: { [idx]: quantity } })
            .then(() => {
                state.fetchOrder(order.id)
            })
            .catch((err) => {
                setFinalQuantities(old)
            })
    }

    const finalTotal = useMemo(() => {
        var total = Object.values(order.other_costs || {}).reduce((a, b) => a + b, 0)
        order.products.forEach((p) => {
            p.final_quantity = finalQuantities[p.id] || p.final_quantity || 0;
            total += p.final_quantity * p.unit_price
        })
        return total
    }, [order, finalQuantities])

    const toRefund = paymentDone(order) ? order.total - finalTotal : 0

    return <div>

        <Row justify="space-between" align="middle" style={{ marginRight: 18 }}>
            <Col>
                <OrderId>#{order.id}</OrderId>
            </Col>
            <Col>
                {order.status == 'completed' && <OrderRowDeliveryLabel>ZREALIZOWANE</OrderRowDeliveryLabel>}
                {order.status == 'cancelled' && <OrderRowDeliveryLabel orange>ANULOWANE</OrderRowDeliveryLabel>}
            </Col>
            <Col>
                <DeliveryLabel>{order.delivery_address ? 'DOSTAWA' : 'ODBIÓR'}</DeliveryLabel>
            </Col>
            <Col>
                <Time>
                    <Moment
                        locale="pl"
                        format="L LT"
                        add={{ minutes: orderProcessingTime }}
                    >{order['created-at']}</Moment>
                </Time>
            </Col>
            <Col>
                <PaymentIcon order={order} />
            </Col>
            <Col width={32} />
        </Row>

        <Row justify="space-between" align="middle"
            style={{
                margin: "12px 0",
                borderTop: '1px solid #f0f0f0',
                paddingTop: 12,
            }}
        >
            <Col style={{ fontSize: 18 }}>
                <a href={`tel:+48${order.phone}`}><span className="icon-Phone-2" /> {order.phone}</a>
            </Col>
            <Col>
                Złożone: <Moment format="LT, L" locale="pl">{order['created-at']}</Moment>
            </Col>
        </Row>

        {order.delivery_address &&
            <p>
                Adres: <b>
                    <a href={`https://maps.google.com/?q=${encodeURIComponent(order.delivery_address)}`} target="_blank">
                        {order.delivery_address}
                    </a>
                </b>
            </p>}

        {Object.entries(order.extra_questions || {}).map(([k, v], i) => (
            <p key={i}>{k} <b>{v}</b></p>
        ))}

        {order.extra_message &&
            <p>Notatki: {order.extra_message}</p>}

        <Input.TextArea
            placeholder="Notatki"
            style={{
                borderRadius: 0,
                border: 'none',
                background: '#f6f6f6'
            }}
            defaultValue={order.note}
            onChange={(e) => setNote(order, e.target.value)}
        />

        <Refunds order={order} />

        <Row justify="space-around" align="middle"
            style={{
                marginTop: 12,
                borderTop: '1px solid #f0f0f0',
                paddingTop: 12,
            }}
        >
            <Col>
                <Counter label="Ilość" current={order.products.length} />
            </Col>
            <Col>
                <Counter
                    label="PLN"
                    current={finalTotal && formatPrice(finalTotal)}
                    total={order.total && formatPrice(order.total)}
                />
            </Col>
        </Row>

        {order.products.map((p, i) =>
            <ProductLine
                key={i}
                product={{
                    ...p,
                    final_quantity: finalQuantities[i] || 0
                }}
                setFinalQuantity={order.status == 'pending' ? setFinalQuantity(i) : null}
            />
        )}
        <OtherCosts order={order} />

        <Row align="middle" style={{ marginTop: 24 }}>
            <Col>
                {(order.status == 'pending' || order.status == 'ready') &&
                    <Button
                        icon={<DeleteOutlined />}
                        type="link"
                        danger
                        onClick={() => markAsCancelled(order)}
                    />
                }
            </Col>
            <Col flex={1}></Col>
            <Col style={{ marginRight: 12 }}>
                <Button
                    onClick={onClose}
                >ZAMKNIJ</Button>
            </Col>
            <Col>
                {order.status == 'pending' &&
                    <Button
                        onClick={() => markAsReady(order, null, toRefund)}
                        type="primary"
                    >DO WYDANIA</Button>}
                {order.status == 'ready' &&
                    <Button
                        onClick={() => setPinCodePopupVisible(true)}
                        type="primary"
                    >Wydaj zamówienie</Button>}
            </Col>
            <Col flex={1}></Col>
        </Row>
        {((order.status == 'ready' && !(order.paid && order.paid < order.total)) ||
            (order.status == 'cancelled' && order.payment_type != 'online'))
            &&
            <a
                onClick={() => markAsPending(order)}
                style={{
                    marginTop: 24,
                    textAlign: "center",
                    textDecoration: 'underline',
                    display: 'block',
                    fontSize: 13,
                }}
            >Przywróć do “Do skompletowania”</a>}

        <PinCodePopup
            order={order}
            visible={pinCodePopupVisible}
            onClose={(done) => {
                setPinCodePopupVisible(false)
                if (done) {
                    Modal.destroyAll()
                }
            }}
        />
    </div>
}

const OrderPopup = ({ order, onClose, visible }) => {

    return <Modal
        visible={visible}
        onCancel={onClose}
        footer={null}
        width={680}
        style={{ top: 20 }}
    >
        {visible && <OrderPopupBody order={order} onClose={onClose} />}
    </Modal>
}
/*
const Order = ({ order }) => {
    var total = order.total

    if (!total) { // todo: remove, only for old orders without :total
        total = 0
        order.products = order.products || []
        order.products.forEach(p => total += p.unit_price * p.quantity)
    }


    const [opened, setOpened] = useState(false);

    const possibleTransitions = {
        ready: ['completed', 'cancelled'],
        completed: [],
        cancelled: [],
        pending: ['ready', 'cancelled'],
    }
    const possible = (status) => possibleTransitions[order.status].includes(status)
    const statusChangePossible = !!possibleTransitions[order.status].length

    const actions = []
    const Action = ({ title, icon, onClick }) => <Tooltip
        title={title}
        onClick={onClick}
    >
        <span style={{ fontSize: 16, padding: 8 }}>{icon}</span>
    </Tooltip>

    const badgeCount = (order.note ? 1 : 0) + (order.extra_message ? 1 : 0)

    actions.push(<Action title="Zobacz zamówienie" onClick={() => setOpened(true)} icon={<EyeOutlined />} />)
    actions.push(<Action title="Zobacz zamówienie" onClick={() => setOpened(true)} icon={<Badge count={badgeCount} offset={[5, 0]}><MessageOutlined /></Badge>} />)

    if (possible('cancelled')) actions.push(<Action title="Anuluj zamówienie" onClick={() => markAsCancelled(order)} icon={<CloseCircleOutlined />} />)
    if (possible('ready')) actions.push(<Action title="Oznacz jako skompletowane" onClick={() => markAsReady(order)} icon={<CheckCircleOutlined />} />)
    if (possible('completed')) actions.push(<Action title="Oznacz jako odebrane" onClick={() => markAsCompleted(order)} icon={<CheckCircleOutlined />} />)

    var previewOk = {
        okType: 'link',
        okText: " ",
    }

    if (possible('ready')) {
        previewOk = {
            okText: "Oznacz jako skompletowane",
            onOk: () => markAsReady(order, () => setOpened(false))
        }
    } else if (possible('completed')) {
        previewOk = {
            okText: "Oznacz jako odebrane",
            onOk: () => markAsCompleted(order, () => setOpened(false))
        }
    }

    const setNote = debounce((order, note) => setOrderNote(order, note), 3000)

    const tags = <>
        {order.delivery_address ? <Tag color="purple">dostawa</Tag> : <Tag color="blue">odbiór własny</Tag>}

        {order.status == 'completed' && <Tag color="success">zrealizowane</Tag>}
        {order.status == 'cancelled' && <Tag color="warning">anulowane</Tag>}
    </>

    const [refundForm] = Form.useForm()
    const openRefundPopup = () => {
        const doRefund = (values) => {
            ApiShop.post(`/order/${order.id}/refund`, values)
                .then(() => {
                    Modal.destroyAll()
                    Modal.success({ content: 'Zwrot wykonany' })
                })
                .catch(() => {
                    message.error('Nie udało się wykonać zwrotu. Spróbuj później')
                })
        }
        Modal.confirm({
            icon: <ExclamationCircleOutlined />,
            title: 'Zwrot środków opłaconych online',
            content: <Form
                form={refundForm}
                layout="vertical"
                onFinish={doRefund}
            >
                <Form.Item
                    name="amount"
                    label="Kwota do zwrotu"
                    rules={[{
                        required: true,
                        message: 'Pole wymagane',
                    }]}
                >
                    <InputNumber
                        min={0.01}
                        step={1}
                        max={total}
                        precision={2}
                        autoFocus
                        placeholder="12.34"
                    />
                </Form.Item>
                <Form.Item
                    name="reason"
                    label="Powód zwrotu"
                    rules={[{
                        required: true,
                        message: 'Pole wymagane',
                    }]}
                >
                    <Input placeholder="Brak produktu" />
                </Form.Item>
            </Form>,
            onOk() { refundForm.submit() },

        })
    }

    return <Card
        key={order.id}
        style={{ width: 300, margin: 12 }}
        actions={actions}
        title={"#" + order.id}
        extra={<Moment fromNow>{order['created-at']}</Moment>}
    >
        <FlexH style={{ marginBottom: 12 }}>
            {tags}
        </FlexH>

        <FlexH>
            <Statistic title="Produktów" value={order.products.length} />
            <Statistic title="Wartość" value={total.toFixed(2)} />
        </FlexH>
        <Modal
            visible={opened}
            title={`Zamówienie: #${order.id}`}
            onCancel={() => setOpened(false)}
            cancelButtonProps={{ shape: 'round' }}
            okButtonProps={{ shape: 'round' }}
            cancelText="Zamknij"
            {...previewOk}
        >
            <OrderDescription>
                <FlexH>
                    <div style={{ flex: 1 }}>
                        <div>Złożone: <b><Moment format="LLLL" locale="pl">{order['created-at']}</Moment></b></div>
                        <div>Telefon do klienta: <a href={`tel:+48${order.phone}`}><PhoneOutlined /> {order.phone}</a></div>
                        <div>Płatność: <b>{paymentTypes[order.payment_type]} {paymentStatus(order)}</b>{paymentDone(order) && <a onClick={openRefundPopup}> zwrot środków</a>}</div>
                        <Refunds order={order} />
                    </div>
                    <div style={{ textAlign: "right", maxWidth: 100 }}>
                        {tags}
                    </div>
                </FlexH>
                {order.delivery_address &&
                    <div>Adres dostawy: <b><a href={`https://maps.google.com/?q=${encodeURIComponent(order.delivery_address)}`} target="_blank">
                        {order.delivery_address}
                    </a></b>
                    </div>}

                {Object.entries(order.extra_questions || {}).map(([k, v], i) => (
                    <div key={i}>{k} <b>{v}</b></div>
                ))}

                {order.extra_message &&
                    <div>Wiadomość od klienta:
                        <div style={{ border: "1px solid #d9d9d9", borderRadius: 2, maxHeight: 100, overflow: "scroll", padding: "4px 11px", color: "#444" }}>{order.extra_message}</div>
                    </div>}

                <div>
                    Notatki:
                    <Input.TextArea
                        defaultValue={order.note}
                        onChange={(e) => setNote(order, e.target.value)}
                    />
                </div>
            </OrderDescription>
            {order.products.map(p => <ProductLine key={p.id} product={p} />)}
            <OtherCosts order={order} />
        </Modal>
    </Card>
}
*/


const Marker = styled.div`
position: absolute;
width: 36px;
height: 36px;
left: -18px;
top: -18px;
line-height: 32px;
border: 2px solid #f44336;
border-radius: 50%;
background: #fff;
text-align: center;
font-size: 14px;
font-weight: bold;
`


const Map = ({ orders }) => {
    const [center, setCenter] = useState()
    const markers = (orders || []).filter(x => x.delivery_geolocation)

    useEffect(() => {
        ApiShop.get('/details')
            .then(({ data }) => {
                setCenter({ lat: data.lat, lng: data.lng })
            })
    }, [])


    if (!center) return <div />
    console.log(center)
    return <div style={{ height: 400 }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCYyiJQ4TS9LHuCTfw-9KXu0eRDhsTVkNA' }}
            defaultCenter={center}
            defaultZoom={13}
            yesIWantToUseGoogleMapApiInternals
        //onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        >
            {markers.map((x) => <Marker
                key={x.id}
                lat={x.delivery_geolocation.lat}
                lng={x.delivery_geolocation.lng}
            >{x.id}</Marker>)}
        </GoogleMapReact>
    </div>
}

const OrderBoxes = ({ orders, mapVisible, orderProcessingTime }) => {
    const [hover, setHover] = useState()

    if (orders.length == 0) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Brak zamówień" />
    }

    return <div>
        {mapVisible && <Map orders={orders} hover={hover} setHover={setHover} />}
        <OrdersContainer>
            {orders.map(x => <OrderBox
                key={x.id}
                order={x}
                orderProcessingTime={orderProcessingTime}
                hover={hover}
                setHover={setHover} />)}
        </OrdersContainer>
    </div>
}

const OrderRows = ({ orders, mapVisible, orderProcessingTime }) => {
    const [hover, setHover] = useState()

    if (orders.length == 0) {
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Brak zamówień" />
    }

    return <div>
        {mapVisible && <Map orders={orders} hover={hover} setHover={setHover} />}
        <div>
            {orders.map(x => <OrderRow
                key={x.id}
                order={x}
                orderProcessingTime={orderProcessingTime}
                hover={hover}
                setHover={setHover} />)}
        </div>
    </div>
}


const OrdersCart = styled(Card)`
.ant-card-body:empty { 
    padding: 0;
}
.ant-card-head {
    cursor: pointer;
}
`

const OrdersPage = inject('state')(observer(({ state }) => {
    const orders = state.orders || [];
    const [mapVisible, setMapVisible] = useState(false)
    const [currentTab, setCurrentTab] = useState('pending')

    const tabClicked = (tab) => {
        if (currentTab == tab) {
            setCurrentTab("")
        } else {
            setCurrentTab(tab)
        }
    }

    const pendingOrders = orders.filter(x => x.status == 'pending')
    const readyOrders = orders.filter(x => x.status == 'ready')
    const doneOrders = orders.filter(x => x.status == 'completed' || x.status == 'cancelled')

    var orderProcessingTime = 30
    var orderDeliveryTime = 30

    const [orderTime, setOrderTime] = useState({ processing: 30, delivery: 30 })

    useEffect(() => {
        ApiShop.get('/details').then((x) => {
            if (x.data) {
                setOrderTime({
                    processing: x.data.order_processing_time || 30,
                    delivery: x.data.order_delivery_time || 30,
                })
            }
        })
    }, [])

    return <PanelLayout>
        <OrdersCart
            title={<div onClick={() => tabClicked('pending')} style={{ margin: "-16px 0", padding: "16px 0" }}>
                <Badge count={pendingOrders.length} offset={[15, 0]}>
                    <span style={{ fontSize: 16 }}>
                        Do skompletowania
                </span>
                </Badge>
            </div>}
            style={{ marginBottom: 24 }}
        >
            {currentTab == 'pending' &&
                <OrderBoxes
                    orders={pendingOrders}
                    mapVisible={mapVisible}
                    orderProcessingTime={orderTime.processing}
                />
            }
        </OrdersCart>

        <OrdersCart
            title={<div onClick={() => tabClicked('ready')} style={{ margin: "-16px 0", padding: "16px 0" }}>
                <Badge count={readyOrders.length} offset={[15, 0]}>
                    <span style={{ fontSize: 16 }}>
                        Gotowe do wydania
                </span>
                </Badge>
            </div>}
            style={{ marginBottom: 24 }}
        >
            {currentTab == 'ready' &&
                <OrderRows orders={readyOrders} mapVisible={mapVisible} orderProcessingTime={orderTime.processing + orderTime.delivery} />
            }
        </OrdersCart>

        <OrdersCart
            title={<div onClick={() => tabClicked('completed')} style={{ margin: "-16px 0", padding: "16px 0" }}>
                <span style={{ fontSize: 16 }}>
                    Zakończone
            </span>
            </div>}
            style={{ marginBottom: 24 }}
        >
            {currentTab == 'completed' &&
                <OrderRows orders={doneOrders} mapVisible={mapVisible} orderProcessingTime={0} />
            }
        </OrdersCart>
    </PanelLayout>
}))

export default OrdersPage
