
import React, { useState, useRef, useEffect } from 'react';
import { Modal, Table, Card, Badge, Input, Spin, Form, message, InputNumber, Select, Tooltip } from 'antd';
import { SearchOutlined, CloseOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const InlineButton = styled.a`
color: #888;
padding-left: 6px;
padding-top: 5px;
`

const EditableCell = ({ staticContent, field, fieldName, record, onFinish, withoutButtons, valuePropName }) => {
    const [inEdit, setInEdit] = useState(false);
    const [form] = Form.useForm()

    const cancel = () => {
        setInEdit(false)
    }
    const save = () => { setTimeout(() => form.submit(), 100) }
    const onSubmit = (values) => {
        if (!form.isFieldTouched(fieldName)) {
            cancel()
            return
        }
        onFinish({
            values,
            done: () => setInEdit(false)
        })
    }

    if (!inEdit) {
        return <div onClick={() => { form.resetFields(); setInEdit(true) }}>
            {staticContent}
        </div>;
    }
    return <Form
        initialValues={record}
        layout="inline"
        style={{ margin: "-5px -12px" }}
        form={form}
        onFinish={onSubmit}
    >
        <Form.Item
            name={fieldName}
            valuePropName={valuePropName || "value"}
            rules={[{
                required: true,
                message: 'Pole wymagane',
            }]}
            style={{ width: "calc(100% - 30px)", margin: 0, position: 'relative' }}
        >
            {field({ cancel, save })}
        </Form.Item>
        {/*<InlineButton onClick={save}><CheckOutlined /></InlineButton>*/}
        {!withoutButtons &&
            <InlineButton onClick={cancel}>
                <Tooltip title="Porzuć zmiany">
                    <CloseOutlined />
                </Tooltip>
            </InlineButton>}
    </Form>
}

const HelpButton = ({title, content}) => {
    const openHelp = () => {
        Modal.info({
            title: <p style={{textAlign: "center", fontWeight: 300, fontSize: 28}}>{title}</p>,
            icon: null,
            width: 800,
            closable: true,
            maskClosable: true,
            content: content,
            okButtonProps: {style: {display: 'none'}}
        })
    }
    return <QuestionCircleOutlined
        style={{
            color: 'var(--primary)',
            marginLeft: 6,
            fontSize: '1.2em',
            cursor: 'pointer',
        }}
        onClick={openHelp}
    />
}

export {
    EditableCell,
    HelpButton
}
