import React, { useState, useEffect } from 'react'
import { ApiShop } from '../Api';
import { HotelPanelLayout as PanelLayout } from '../components/PanelLayout';
import { Button, Card, Form, Input, Result } from 'antd';


const HelpPage = ({ }) => {
  const [msgSend, setMsgSend] = useState(false)
  const [loading, setLoading] = useState(false)

  const submitMessage = (values) => {
    setLoading(true)
    return ApiShop.post('/hotel/contact', {
      message: values.message,
      from: values.from,
      customer_type: 'consumer'
    }).then(() => {
      setMsgSend(true)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  return <PanelLayout>
    <Card>
      <div style={{ textAlign: "center" }}>
        {!msgSend && <div>
          <h2>Potrzebujesz pomocy?</h2>
          <h2>Chcesz coś zgłosić?</h2>

          <Form
            layout="vertical"
            style={{ maxWidth: 500, margin: "0 auto" }}
            onFinish={submitMessage}
          >
            <Form.Item
              label="Żebyśmy mogli pomóc, opisz:"
              name="message"
              rules={[{
                required: true,
                message: 'Wpisz swoją wiadomość',
              }]}
            >
              <Input.TextArea
                placeholder={"1. Co chciałeś zrobić? \n2. Co się wydarzyło? \n3. Czego oczekiwałeś?"}
                rows={5}
              />
            </Form.Item>

            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%" }}
              loading={loading}
            >
              WYŚLIJ ZGŁOSZENIE
                        </Button>
          </Form>
        </div>}
        {msgSend && <Result
          status="success"
          title="Wiadomość wysłana!"
          subTitle="Odpowiemy jak najszybciej."
        />}
      </div>
    </Card>
  </PanelLayout>
}

export default HelpPage
