import { observable, action } from 'mobx'
import { Api, ApiShop, createApiShop } from '../Api'


class AppState {
    @observable
    session = {pending: true}

    @action.bound
    setSessionData(session) {
        this.session = session
    }
    @action.bound
    afterLogin(data) {
        this.session = {user: data.user, shopId: data['shop-id'], hotel: data.hotel}
        console .log("session", JSON.stringify(this.session))
        createApiShop()
        this.fetchOrders()

        if(this.session.shopId == 33 || this.session.shopId == 34 || this.session.shopId == 26 || this.session.shopId == 29) { // fixme
            this.lang = 'en'
        }
    }
    @action.bound
    logout() {
        Api.post('/login/logout')
        this.session = {}
        this.orders = []
    }

    @observable
    orders = []

    @action.bound
    setOrders(orders) {
        this.orders = orders
        // extract new orders, dispatch notification
    }

    @action.bound
    setOrder(order) {
        const idx = this.orders.findIndex((x) => x.id == order.id)
        if(idx >= 0) {
            this.orders[idx] = order
        } else {
            this.orders.push(order)
        }
    }

    @action.bound
    fetchOrders() {
        if(this.session.shopId) {
            ApiShop.get('/orders').then(({data}) => this.setOrders(data))
        }
    }

    fetchOrder(id) {
        ApiShop.get(`/order/${id}`).then(({data}) => this.setOrder(data))
    }

    @action.bound
    checkSession() {
        this.session = {pending: true}
        return Api.get('/login/info').then(({data}) => {
            this.afterLogin(data)
        }).catch(() => {
            this.setSessionData({})
        })
    }

    @observable
    lang = 'pl'
}

export const state = new AppState()
window._state = state

setTimeout(state.fetchOrders, 100)
setInterval(state.fetchOrders, 1000 * 60)
