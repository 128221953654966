import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import { Switch, Route, Redirect } from 'react-router-dom';
import ShopPage from './components/ShopPage'
import SettingsPage from './components/SettingsPage'
import ProductsPage from './components/ProductsPage'
import OrdersPage from './components/OrdersPage'
import LoginPage from './components/LoginPage'
import AddProductsPage from './components/AddProductsPage'
import ProductOptionsPage from './components/ProductOptionsPage'
import HelpPage from './components/HelpPage'
import PaymentsConfigPage from './components/PaymentsConfigPage'
import { Spin } from 'antd';
import MenuPage from './hotel/Menu'
import HotelOrdersPage from './hotel/Orders'
import HotelSettingsPage from './hotel/Settings'
import HotelTablesPage from './hotel/Tables'
import HotelHelp from './hotel/Help'
import HotelCheckin from './hotel/Checkin'
import RestaurantMenuPage from './hotel/RestaurantMenu'

import { NoMatch } from './components/PanelLayout'

import './App.css';


@inject('routing')
@inject('state')
@observer
class App extends Component {

  componentDidMount() {
    this.props.state.checkSession()
  }

  render() {
    const { location, push, goBack } = this.props.routing;
    const state = this.props.state;
    const session = state.session;
    const shopId = session.shopId;

    if(session.pending) {
      return <div style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}>
        <Spin spinning={true} delay={200}/>
      </div>
    }



    if (!shopId) {
      return <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/password-reset/:id/:token" component={LoginPage}/>
        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    }

    return (
      <div style={{ height: '100%' }}>
        <Switch>
          <Route path="/shop">
            <ShopPage shopId={shopId} />
          </Route>
          <Route path="/settings">
            <SettingsPage shopId={shopId} />
          </Route>
          <Route path="/add-product">
            <AddProductsPage />
          </Route>
          <Route path="/products">
            <ProductsPage shopId={shopId} />
          </Route>
          <Route path="/product-options">
            <ProductOptionsPage shopId={shopId} />
          </Route>
          <Route path="/orders/:order_id?">
            <OrdersPage shopId={shopId} />
          </Route>
          <Route path="/help">
            <HelpPage shopId={shopId} />
          </Route>
          <Route path="/payments">
            <PaymentsConfigPage shopId={shopId} />
          </Route>
          <Route path="/password-reset/:id/:token" component={LoginPage}/>

          <Route path="/hotel/menu">
            <MenuPage shopId={shopId} />
          </Route>
          <Route path="/hotel/restaurant-menu">
            <RestaurantMenuPage shopId={shopId} menuType="restaurant" key="restaurant"/>
          </Route>
          <Route path="/hotel/restaurant2-menu">
            <RestaurantMenuPage shopId={shopId} menuType="restaurant2" key="restaurant2"/>
          </Route>
          <Route path="/hotel/services-menu">
            <RestaurantMenuPage shopId={shopId} menuType="services"  key="services" />
          </Route>
          <Route path="/hotel/explore/:provider">
            {({match}) => <RestaurantMenuPage shopId={shopId} menuType={match.params.provider} key={match.params.provider} />}
          </Route>
          <Route path="/hotel/orders">
            <HotelOrdersPage shopId={shopId} />
          </Route>
          <Route path="/hotel/settings">
            <HotelSettingsPage shopId={shopId} />
          </Route>
          <Route path="/hotel/tables">
            <HotelTablesPage shopId={shopId} />
          </Route>
          <Route path="/hotel/help">
            <HotelHelp shopId={shopId} />
          </Route>
          <Route path="/hotel/checkin">
            <HotelCheckin shopId={shopId} />
          </Route>

          <Route path="/">
            {!(session.hotel)
            ? <Redirect to="/orders" />
            : <Redirect to="/hotel/menu" /> }
          </Route>
          <Route path="*">
            {/*<NoMatch />*/}
            <Redirect to="/orders" />
          </Route>
        </Switch>
      </div>
    );
  }
}

export default App

//<span>Current pathname: {location.pathname}</span>
//<button onClick={() => push('/test')}>Change url</button>
//<button onClick={() => goBack()}>Go Back</button>