import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Button, Input, Form, Empty, Upload, Spin, message, Card, Row, Col, Table, Select, Result, Alert, Divider, Modal, Collapse } from 'antd';
import { CameraOutlined, FileAddOutlined, UploadOutlined, SaveOutlined, ArrowRightOutlined, BarcodeOutlined, AppstoreAddOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import XLSX from 'xlsx'
import { ApiShop } from '../Api';
import { BrowserBarcodeReader } from '@zxing/library';
import styled from 'styled-components';
import ProductForm from './ProductForm';
import PanelLayout from './PanelLayout';
import { imageUrl } from '../utils';
import { OnboardingSidebar, OnboardingsSteps } from './Onboarding'
import { useHistory } from "react-router-dom";
import { inject } from 'mobx-react';


const BarCodeScanner = ({ onDetection }) => {
    const [cameraError, setCameraError] = useState(false)

    useLayoutEffect(() => {
        const reader = new BrowserBarcodeReader()

        console.log('BarCodeScanner init')

        reader.decodeOnceFromVideoDevice(undefined, 'video')
            .then(result => {
                console.log(result)
                onDetection(result.text)
            })
            .catch(err => {
                setCameraError(true)
                console.error(err)
            })

        return () => {
            reader.stopStreams()
        }
    })
    if (cameraError) {
        return <div style={{
            width: 300,
            height: 200,
            border: "1px solid gray",
            borderRadius: "6px",
            textAlign: "center",
            margin: "0 auto",
        }}>
            <CameraOutlined style={{ fontSize: "64px", marginTop: 50 }} />
            {/*<p>Brak dostępu do kamery</p>*/}
            <p>Włącz aparat</p>
        </div>
    }
    return <video
        id="video"
        width="300"
        height="200"
        style={{ border: "1px solid gray", margin: "0 auto" }}
    ></video>
}

const ScannerWrapper = styled.div`
width: 300px;
margin: 0 auto;
`;

const fetchDescription = (code) => {
    return ApiShop.get(`/product/${code}`).then(res => res.data)
}

const parseXls = (data) => {
    let workbook
    let worksheet
    let d
    try {
        workbook = XLSX.read(data, { type: 'array' })
        worksheet = workbook.Sheets[workbook.SheetNames[0]]
        d = XLSX.utils.sheet_to_json(worksheet, { header: 1, blankrows: false })
    } catch (e) {
        Modal.error({ title: 'Nie udało się odczytać pliku lub nie zawiera on produktów' })
        return {};
    }

    if (d.length < 1) {
        Modal.error({ title: 'Nie udało się odczytać pliku lub nie zawiera on produktów' })
        return {};
    }

    const hasHeader = d[0].some((x) => ("" + x).match(/nazwa|kod|ean|cena/i))

    const productsData = hasHeader ? d.slice(1) : d

    const columns = []
    for (var i in d[0]) {
        columns.push({
            dataIndex: i,
            type: undefined
        })
    }

    productsData.forEach((x, i) => x.key = i)

    return { columns, productsData }
}

const { Option } = Select;

const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

const columnsNames = {
    name: 'Nazwa',
    ean: 'Kod EAN',
    custom_id: 'Kod własny',
    ean_or_id: 'Kod EAN lub własny',
    price: 'Cena',
    unit: 'Jednostka',
    stock: 'Stan magazynowy',
    ignore: 'Pomiń kolumnę',
}

const columnsNameToField = {
    name: 'name',
    ean: 'ean',
    custom_id: 'id',
    ean_or_id: 'id',
    price: 'price',
    unit: 'unit',
    stock: 'availability',
}

const sendProducts = (products) => ApiShop.post('/products-panel', products)


const FileImport = ({ }) => {
    const [columns, setColumns] = useState()
    const [data, setData] = useState()
    const [fileName, setFileName] = useState()

    const [success, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)



    const importFile = (file) => {
        const reader = new FileReader();
        // todo: use webworker (?) (https://oss.sheetjs.com/sheetjs/)
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const { columns, productsData } = parseXls(data)
            setColumns(columns)
            setData(productsData)
            setFileName(file.name)
        }
        reader.readAsArrayBuffer(file);
        return false
    }

    const ColumnHeader = ({ column }) => {
        return <div>
            <Select
                value={column.type}
                onChange={(v) => {
                    column.type = v
                    setColumns(columns.slice())
                }}
                style={{ width: 155 }}
                placeholder="-- wybierz --"
            >
                <Option value="name">Nazwa</Option>
                <Option value="ean">Kod EAN</Option>
                <Option value="custom_id">Kod własny</Option>
                <Option value="ean_or_id">Kod EAN lub własny</Option>
                <Option value="price">Cena</Option>
                <Option value="unit">Jednostka</Option>
                <Option value="stock">Stan magazynowy</Option>
                <Option value="ignore">Pomiń kolumnę</Option>
            </Select>
        </div>
    }

    const parsers = {
        name: (x) => {
            if (x == x.toUpperCase()) { x = capitalize(x) }
            return x.trim()
        },
        ean: x => (x.match(/\d{8,}/) || [])[0],
        custom_id: x => (x.match(/[a-zA-Z0-9_-]+/) || [])[0],
        ean_or_id: x => (x.match(/[a-zA-Z0-9_-]+/) || [])[0],
        price: (x) => {
            x = x.replace(',', '.').replace('zł', '').replace('PLN', '').replace(/ /g, '')
            x = parseFloat(x)
            if (!isNaN(x) && x > 0) return x
        },
        unit: (x) => {
            if (x.match(/szt/i)) return 'szt.'
            if (x.match(/kg|kilogram/i)) return 'kg'
            if (x.match(/zgrzewk/i)) return 'zgrzewkę'
        },
        stock: (x) => {
            if (x.match(/nie|brak/i)) return 0
            if (x.match(/tak|dost|jest/i)) return 999
            x = parseFloat(x)
            if (!isNaN(x)) return x
            return 0
        },
        ignore: (x) => x == 'undefined' ? '-' : x
    }

    const parseValue = (value, type) => parsers[type || 'ignore']("" + value)

    const cellRender = (text, record, index, columnType) => {
        const v = parseValue(text, columnType)
        if (!columnType || columnType == 'ignore') {
            return <div style={{ color: "#999" }}>
                {v}
            </div>
        }
        if (columnType == 'stock') {
            if (v === undefined) return <div />
            return <div>{v == 0 ? "Brak" : (v >= 999 ? "Bez limiu" : v)}</div>
        }
        if (columnType == 'price') {
            return <div>{v && (`${v.toFixed(2).replace('.', ',')} zł`)}</div>
        }
        return <div>{v}</div>
    }

    const columnsDesc = (columns) => {
        return columns.map((c) => ({
            dataIndex: c.dataIndex,
            title: ({ sortOrder, sortColumn, filters }) => <ColumnHeader column={c} />,
            render: (text, record, index) => cellRender(text, record, index, c.type)
        }))
    }

    const saveProducts = () => {
        const cols = {}
        for (var x of columns) {
            if (!x.type || x.type == 'ignore') continue;
            if (cols[x.type]) {
                Modal.error({ title: `Ustaw tylko jedną kolumnę "${columnsNames[x.type]}"` })
                return
            }
            cols[x.type] = x
        }
        if (!cols.ean && !cols.custom_id && !cols.ean_or_id) {
            Modal.error({ title: `Ustaw kolumnę z kodem produktu` })
            return
        }
        if (cols.ean_or_id && (cols.ean || cols.custom_id)) {
            Modal.error({ title: `Kolumna "${columnsNames['ean_or_id']}" nie może być użyta razem z kolumną "${columnsNames['ean']}" ani z kolumną"${columnsNames['custom_id']}"` })
            return
        }

        var noIdWarning = false

        const products = []
        for (var row of data) {
            const p = {}
            for (var k in cols) {
                const x = cols[k]
                const value = p[columnsNameToField[k]] || parseValue(row[x.dataIndex], k)
                if (value !== undefined) {
                    p[columnsNameToField[k]] = value
                }
            }
            p.id = p.ean || p.id
            delete p.ean
            if (p.id) {
                products.push(p)
            } else {
                noIdWarning = true
            }
        }
        if (noIdWarning) {
            message.warn("Plik zawierał produkty bez kodu - zostały one pominięte")
        }
        setLoading(true)
        sendProducts(products)
            .then(() => {
                setLoading(false)
                setSuccess(true)
            })
            .catch(() => {
                setLoading(false)
                message.error("Nie udało się zapisać produktów. Spróbuj ponownie później lub skontaktuj się z nami.")
            })
    }

    return <div>
        {!success &&
            <Row gutter={32}>
                <Col>
                    <Upload
                        accept=".xlsx" // application/vnd.ms-excel
                        beforeUpload={importFile}
                        fileList={[]}
                    >
                        <Button type="primary" style={{marginBottom: 24}}>
                            <UploadOutlined /> Prześlij plik .xlsx
                        </Button>
                    </Upload>
                </Col>
                <Col>
                    <p><a href="/sample-files/przykładowa baza produktów.xlsx" target="_blank" style={{ color: "#888", textDecoration: "underline" }}>Zobacz przykładową bazę produktów</a></p>
                </Col>
            </Row>}

        {(data && columns && !success) &&
            <Spin spinning={loading}>
                <Divider />
                <p><b>Ostatnio wgrano: {fileName}</b></p>
                <p>Potwierdź, że nagłówki są zdefiniowane prawidłowo lub skoryguj.</p>
                <div style={{
                    width: "100%",
                    overflow: "scroll"
                }}>
                    <Table
                        dataSource={data}
                        columns={columnsDesc(columns)}
                        size="small"
                        pagination={{ simple: true, defaultPageSize: 5 }}
                    />
                </div>

                <div >
                    <Button
                        type="primary"
                        onClick={saveProducts}
                    >
                        <SaveOutlined /> Zatwierdź
                    </Button>
                    {/*
                    <Button
                        style={{ marginRight: 24 }}
                        onClick={() => {
                            setColumns()
                            setData()
                        }}
                    >
                        <RollbackOutlined /> Anuluj
                    </Button>
                    */}
                </div>
            </Spin>}

        {success && <Result
            status="success"
            title="Produkty zaimportowane!"
            subTitle="Przejdź do zakładki “Baza produktów” aby edytować produkty."
        />}
    </div>
}

const BarCodeScannerSection = () => {
    const [code, setCode2] = useState()
    const [loading, setLoading] = useState(false);
    const [product, setProduct] = useState()

    const setCode = (code) => {
        setCode2(code)
        setLoading(true)
        fetchDescription(code).then(data => {
            setLoading(false)
            setProduct(data)
        }).catch(() => {
            message.info(`Nie znaleziono produktu o kodzie ${code}. Możesz samodzielnie podać jego dane.`)
            setLoading(false)
        })
    }

    if (code) {
        return <Spin spinning={loading} delay={200}>
            <ProductForm
                code={code}
                product={product || {}}
                onFinish={() => {
                    setCode2()
                    setProduct()
                }}
            />
        </Spin>
    }
    return <div>
        <HelpSection content={[{
            icon: 'Laptop-Phone',
            title: 'Wybór urządzenia',
            text: 'Skanować kody EAN możesz dowolnym urządzeniem z kamerą, dostępem do internetu i strony fablo.pl. Jeśli masz niewiele produktów możesz zebrać je w jednym miejscu i skanować kamerką komputera. Jeśli leżą np na półkach rekomendujemy użycie telefonu i skanowanie produktów na półkach.',
        }, {
            icon: 'Bar-Code',
            title: 'Skanowanie kodów',
            text: 'Nakieruj prostokąt z podglądem na kod EAN tak aby znajdował się mniej więcej na środku. Staraj się robić to w dobrze oświetlonym pomieszczeniu.',
        }, {
            icon: 'Tap',
            title: 'Dodawanie produktów',
            text: 'Jeśli kod EAN zostanie odnaleziony w jednej z naszych baz, Brandbanku bądź BioPlanet pojawi się zdjęcie oraz opis. Cenę oraz stan magazynowy trzeba będzie dodać ręcznie. Jeśli nie zostanie odnaleziony, pojawi się formularz do dodania wszystkich informacji na temat produktu.'
        }, {
            icon: 'Box-Open',
            title: 'Stany magazynowe',
            text: 'Pamiętaj, że po wgraniu i uzupełnieniu bazy należy ją monitorować i uaktualniać. Przy zamówieniach online stany magazynowe są bardzo ważne - unikaj anulowania zamówień z powodu braku produktów.'
        }]}
        />

        <ScannerWrapper>
            <BarCodeScanner onDetection={(x) => setCode(x)} />
            {/*
                    <p style={{ marginTop: 32 }}>lub wprowadź kod poniżej:</p>
                    <Form
                        layout="inline"
                        onFinish={({ code }) => setCode(code)}
                    >
                        <Form.Item
                            extra={<small>np. "123" lub "pomidor-malinowy-1"</small>}
                            rules={[{
                                pattern: /^[a-zA-Z0-9-_]+$/,
                                message: 'Dozwolone znaki to litery, cyfry oraz: "-" i "_"'
                            }]}
                            style={{ margin: 0, width: 235 }}
                            name="code"
                        >
                            <Input
                                placeholder="EAN lub własny kod produktu"
                                autoFocus
                                size="large"
                                style={{ borderTopLeftRadius: 6, borderBottomLeftRadius: 6 }}
                            />
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            style={{ margin: "0 0 0 -1px", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        >
                            <ArrowRightOutlined />
                        </Button>
                    </Form>
                        */}
        </ScannerWrapper>
    </div>

}

const HelpSection = ({ content }) => {
    const Coll = styled(Collapse)`
        border: none;
        background: none;
        > .ant-collapse-item, .ant-collapse-content {
            border: none;
        }
        > .ant-collapse-item > .ant-collapse-header {
            border: 1px solid #f0f0f0;
            border-radius: 6px !important;
            font-weight: 600;
            color: #808080;
        }
        .ant-collapse-item-active > .ant-collapse-header {
            background: var(--primary);
            border: none;
            color: #fff;
        }
    `
    return <Coll
        style={{ marginBottom: 32, fontSize: 13 }}
        defaultActiveKey={window.location.search.match('first-time') ? "1" : ""}
    >
        <Collapse.Panel header="Wskazówki" key="1">
            <Row gutter={24}>
                {content.map(x => <Col md={12} lg={6} key={x.icon}>
                    <span className={'icon-' + x.icon} style={{ margin: "8px 0 24px", fontSize: 36, display: 'block' }} />
                    <p><b>{x.title}</b></p>
                    <p>{x.text}</p>
                </Col>)}
            </Row>
        </Collapse.Panel>
    </Coll>
}


const AddFromFileSection = () => {
    return <div>
        <HelpSection content={[{
            icon: 'Check',
            title: 'Przygotowanie pliku',
            text: 'Przygotuj plik ze wszystkimi swoimi produktami w formacie xlsx. Każdy produkt powinien mieć kod własny bądź kod EAN, nazwę, cenę, jednostkę sprzedaży oraz stan magazynowy.',
        }, {
            icon: 'Add-Window',
            title: 'Wgranie pliku',
            text: 'Wgraj plik do FABLO. Wybierz odpowiednie nazwy nagłówków w swoim pliku aby produkty z kodami EAN mogły zostać dopasowane do produktów z bazy Brandbank oraz BioPlanet. Zdjęcia oraz opisy automatycznie pojawią się przy produktach widniejących w bazie.',
        }, {
            icon: 'Pen',
            title: 'Edycja produktów',
            text: 'Po wgraniu pliku przejdź do zakładki “Baza produktów” w panelu bocznym. Sprawdź czy wszystkie produkty dodały się prawidłowo. Uzupełnij dane, których nie było w pliku oraz produkty, które nie zostały odnalezione w żadnej z naszych baz.'
        }, {
            icon: 'Box-Open',
            title: 'Stany magazynowe',
            text: 'Pamiętaj, że po wgraniu i uzupełnieniu bazy należy ją monitorować i uaktualniać. Przy zamówieniach online stany magazynowe są bardzo ważne - unikaj anulowania zamówień z powodu braku produktów.'
        }]} />
        <FileImport />
    </div>
}

const AddCustomSection = ({ onSuccess }) => {
    return <div>
        <HelpSection content={[{
            icon: 'Banana',
            title: 'Przygotowanie produktów',
            text: 'Będziesz musiał wpisać po kolei nazwy, ceny, opisy i stany magazynowe produktów. Przygotuj listę bądź zgromadź produkty w jednym miejscu, aby o żadnym nie zapomnieć.',
        }, {
            icon: 'Aa',
            title: 'Dodawanie produktów',
            text: 'Wypełnij wszystkie pola formularza i kliknij zatwierdź.',
        }, {
            icon: 'Pen',
            title: 'Edycja produktów',
            text: 'Po zatwierdzeniu produkt trafi do zakładki “Baza produktów” tam można go edytować: zmieniać cenę, opis, jednostkę etc.'
        }, {
            icon: 'Box-Open',
            title: 'Stany magazynowe',
            text: 'Pamiętaj, że po wgraniu i uzupełnieniu bazy należy ją monitorować i uaktualniać. Przy zamówieniach online stany magazynowe są bardzo ważne - unikaj anulowania zamówień z powodu braku produktów.'
        }]} />
        <ProductForm
            //code={code}
            oneTime
            product={{}}
            onFinish={(res) => {
                if (res) {
                    onSuccess()
                }
            }}
        />
    </div>
}

const FromBaseSection = () => {
    const [images, setImages] = useState([])
    const [selected, setSelected] = useState()
    const [phrase, setPhrase] = useState('')

    useEffect(() => {
        ApiShop.get('/images-db').then(({ data }) => {
            setImages(data)
        })
    }, [])

    const imgs = images.filter(x => !phrase || x.match(phrase.toLocaleLowerCase()))

    const ImageWrapper = styled.div`
    margin: 24px;
    text-align: center;
    cursor: pointer;
    @media(max-width: 768px) {
        margin: 12px;
    }
    `
    const Image = styled.img`
    width: 150px;
    height: 100;
    object-fit: contain;
    display: block;
    @media(max-width: 768px) {
        width: 130px;
    }
    `

    return <div>
        <HelpSection content={[{
            icon: 'Banana',
            title: 'Przygotowanie produktów',
            text: 'Przygotuj listę produktów, które będziesz chciał dodać, potrzebne będą ich: nazwy, kody własne, jednostki sprzedaży, cena oraz stan magazynowy.',
        }, {
            icon: 'Aa',
            title: 'Dodawanie produktów',
            text: 'Zaznacz kafelek z produktem, który chcesz dodać i wypełnij formularz z informacjami produktu dopasowanymi do Twojego sklepu.',
        }, {
            icon: 'Pen',
            title: 'Edycja produktów',
            text: 'Po zatwierdzeniu produkt trafi do zakładki “Baza produktów” tam można go edytować: zmieniać cenę, opis, jednostkę etc.'
        }, {
            icon: 'Box-Open',
            title: 'Stany magazynowe',
            text: 'Pamiętaj, że po wgraniu i uzupełnieniu bazy należy ją monitorować i uaktualniać. Przy zamówieniach online stany magazynowe są bardzo ważne - unikaj anulowania zamówień z powodu braku produktów.'
        }]} />

        <div style={{
            maxWidth: 400,
            margin: '12px auto'
        }}>
            <Input.Search
                size="large"
                placeholder="Wyszukaj..."
                value={phrase}
                onChange={(e) => setPhrase(e.target.value)}
                autoFocus
            />
        </div>

        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
        }}>
            {imgs.length == 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {imgs.map((x) => <ImageWrapper
                onClick={() => setSelected(x)}
            >
                <Image src={imageUrl(null, x)} />
                {x.replace('fruit:', '')}
            </ImageWrapper>)}

            <Modal
                visible={!!selected}
                onCancel={() => setSelected()}
                footer={null}
                closeIcon={<></>}
            >
                <ProductForm
                    product={{
                        name: (selected || '').replace('fruit:', ''),
                        image_type: selected,
                    }}
                    onFinish={() => {
                        setSelected(null)
                    }}
                />
            </Modal>
        </div>
    </div>
}


const OnboardingInfo = () => {
    return <OnboardingSidebar>
        <h3>Produkty z oferty</h3>
        <p>1. Dodaj wszystkie produkty jakie chcesz udostępnić w sprzedaży online.</p>
        <p>2. Dodaj dobrze wykonane zdjęcia oraz dokładne opisy aby umożliwić klientom jak najlepsze podjęcie decyzji co do zakupów.</p>
        <p>3. Dobrze opisane produkty oraz ładne zdjęcia wzmacniają zaufanie do biznesu, jakości jego oferty i świadczonych usług.</p>
        <p>4. Pamiętaj aby pilnować stanów magazynowych online oraz w sklepie stacjonarnym aby uniknąć anulowania zamówień i zwrotów pieniędzy.</p>
        <p>5. Jeśli kompletując zamówienie nie odznaczysz danego produktu na liście zamówionych rzeczy jego stan magazynowy na fablo.pl automatycznie zmieni się na “Nie dostępny” i przestanie być on widoczny dla klientów.</p>

        <Link to="/">
            <Button
                style={{ marginTop: 24 }}
                type="primary"
            >
                Rozumiem <ArrowRightOutlined />
            </Button>
        </Link>
    </OnboardingSidebar>
}

const AddProductsPage = inject('routing')(({ routing }) => {
    const firstTime = routing.location.search && !!routing.location.search.match('first-time')

    const [section, setSection] = useState()

    const AddButton = styled.div`
        padding: 12px 24px;
        border-right: 1px solid #f0f0f0;
        font-weight: 600;
        cursor: pointer;
        text-align: center;
        @media(max-width: 768px) {
            font-size: 12px;
        }
        &:last-child {
            border-right: none;
        }
        &:hover {
            background-color: #f0f0f0;
        }
        .anticon {
            display: block;
            font-size: 32px;
            margin-bottom: 12px;
        }
    `

    const onSuccess = () => {
        setSection('success')
    }


    return <PanelLayout
        sidebarContent={firstTime && <OnboardingInfo />}
    >
        {firstTime && <OnboardingsSteps step={3} />}

        <Card
            style={{ marginBottom: 24 }}
        >
            <div style={{
                display: 'flex',
                justifyContent: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    border: '1px solid #f0f0f0',
                    borderRadius: 6,
                    marginBottom: 32,
                }}>
                    <AddButton onClick={() => setSection('file')}>
                        <FileAddOutlined />
                        Dodaj z&nbsp;pliku
                    </AddButton>
                    <AddButton onClick={() => setSection('barcode')}>
                        <BarcodeOutlined />
                        Skanuj kod
                    </AddButton>
                    <AddButton onClick={() => setSection('custom')}>
                        <CameraOutlined />
                        Dodaj własny
                    </AddButton>
                    <AddButton onClick={() => setSection('image-db')}>
                        <AppstoreAddOutlined />
                        Dodaj z&nbsp;bazy
                    </AddButton>
                </div>
            </div>

            {section == 'success' &&
                <Alert
                    message="Produkty zostały dodane. Możesz je edytować w zakładce “Baza Produktów”."
                    type="success"
                    closable
                    style={{ maxWidth: 500, margin: '24px auto' }}
                />
            }



            {section == 'barcode' && <BarCodeScannerSection onSuccess={onSuccess} />}
            {section == 'file' && <AddFromFileSection onSuccess={onSuccess} />}
            {section == 'custom' && <AddCustomSection onSuccess={onSuccess} />}
            {section == 'images-db' && <FromBaseSection onSuccess={onSuccess} />}


        </Card>

    </PanelLayout>
})

export default AddProductsPage;
