import React, { useState, useEffect } from 'react'
import { HotelPanelLayout as PanelLayout, t } from '../components/PanelLayout';
import { Form, Button, Input, Card, Select, InputNumber, Tabs, Affix, TimePicker, Radio, Spin, Modal, message, Row, Col, Tooltip } from "antd";
import { MinusCircleOutlined, PlusCircleOutlined, PrinterOutlined, MenuOutlined, CopyOutlined, EditOutlined, EditFilled } from "@ant-design/icons";
import { useRequest } from "ahooks";
import { format, eachDayOfInterval, addDays } from 'date-fns'
import { pl as localePl } from 'date-fns/locale'
import ReactDragListView from 'react-drag-listview';
import { ApiShop, Api } from '../Api';
import { keys, values, filter, toPairs, countBy, flatMap, sortBy, padStart, reverse } from 'lodash'
import moment from 'moment';
import {
  LangSwitcher
} from './Common'
import { handleSaveError } from '../utils'

const categoryTypes = () => {
  return [
    {value: "Kuchnia zimna", label: t({pl: "Kuchnia zimna", en: "Cold kitchen"})},
    {value: "Kuchnia ciepła", label: t({pl: "Kuchnia ciepła", en: "Warm kitchen"})},
    {value: "Sala", label: t({pl: "Sala", en: "Floor"})},
  ]
}


const ProdRow = ({ field, remove, lang, shortsLocked }) => {
  const [descVisible, setDescVisible] = useState(false)
  const EditIcon = descVisible ? EditFilled : EditOutlined

  return <div className="product">
    <div style={{ display: "flex", alignItems: 'center', marginBottom: 12, flexWrap: 'wrap' }}>
      <MenuOutlined className="drag-product" style={{ cursor: 'move', marginRight: 12 }} />

      <Form.Item
        rules={[{ required: true, message: "wymagane" }]}
        name={[field.name, "name", lang]}
        style={{ marginBottom: 0, flex: 1, marginRight: 12, minWidth: 100 }}
      >
        <Input placeholder={t({pl: 'Nazwa produktu', en: 'Product name'})} />
      </Form.Item>

      <Form.Item
        name={[field.name, "short"]}
        style={{ marginBottom: 0, width: 105, marginRight: 12 }}
        rules={[
          { required: true, message: "wymagane" },
          { pattern: /^[a-zA-Z0-9_]+$/, message: "tylko litery oraz znak _" },
        ]}
      >
        <Input placeholder={t({pl: 'skrót', en: 'short'})} maxLength={6} disabled={shortsLocked} />
      </Form.Item>

      <Form.Item
        name={[field.name, "limit"]}
        style={{ marginBottom: 0, marginRight: 12 }}
      >
        <InputNumber style={{ width: 58 }} placeholder="limit" min={1} />
      </Form.Item>

      <Tooltip title="dodaj opis">
        <EditIcon
          style={{ marginRight: 12 }}
          onClick={() => setDescVisible(!descVisible)}
        />
      </Tooltip>

      <MinusCircleOutlined
        style={shortsLocked ? { opacity: 0.5 } : {}}
        onClick={() => {
          if (!shortsLocked) {
            remove(field.name)
          }
        }}
      />
    </div>
    {descVisible &&
      <Form.Item
        name={[field.name, "desc", lang]}
      >
        <Input.TextArea
          autoSize={{ minRows: 5, maxRows: 30 }}
          placeholder={t({pl: 'Opis', en: 'Description'})}
        />
      </Form.Item>
    }
  </div>
}

const Products = ({ listName, lang, shortsLocked }) => {

  return <Form.List name={listName}>
    {(fields, { add, remove, move }) => <>
      <ReactDragListView
        nodeSelector=".product"
        handleSelector=".drag-product"
        onDragEnd={(from, to) => {
          move(from, to)
        }}
      >
        {fields.map(x => <ProdRow
          key={x.name}
          field={x}
          remove={remove}
          lang={lang}
          shortsLocked={shortsLocked}
        />)}
      </ReactDragListView>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={() => add({})}
        type="dashed"
        block
        style={{ marginTop: 12 }}
      >
        {t({pl: 'Dodaj produkt', en: 'Add product'})}
      </Button>
    </>}
  </Form.List>

}

const dayKey = (d) => format(d, 'yyyy-MM-dd')

const TimeInput = ({ value, onChange, disabled }) => {
  return <TimePicker
    format="H:mm"
    minuteStep={15}
    showNow={false}
    suffixIcon={null}
    allowClear={false}
    value={moment(value, 'H:mm')}
    disabled={disabled}
    onSelect={(momentVal) => onChange(momentVal.format('H:mm'))}
    style={{
      backgroundColor: "#fcfcfc",
    }}
  />
}

const Slots = ({ form, locked }) => {

  return <Card
    title={t({pl: "Sloty zamawiania śniadań", en: "Breakfast ordering slots"})}
    style={{
      maxWidth: 385,
      margin: "12px 0",
    }}
  >
    <Form.List name="slots">
      {(fields, { add, remove, move }) => <>
        {sortBy(fields, (x) => padStart(form.getFieldValue(['slots', x.name]).from || '24:00', 5))
          .map(field => <div key={field.key} style={{ display: "flex", alignItems: 'center', marginBottom: 12 }} >

            <Form.Item
              rules={[{ required: true, message: "wymagane" }]}
              name={[field.name, "from"]}
              style={{ marginBottom: 0, marginRight: 12 }}
            >
              <TimeInput disabled={locked} />
            </Form.Item>
          &mdash;
          <Form.Item
              rules={[{ required: true, message: "wymagane" }]}
              name={[field.name, "to"]}
              style={{ marginBottom: 0, marginRight: 12, marginLeft: 12 }}
            >
              <TimeInput disabled={locked} />
            </Form.Item>

            <Form.Item
              name={[field.name, "limit"]}
              style={{ marginBottom: 0, marginRight: 12 }}
            >
              <InputNumber style={{ width: 58 }} placeholder="limit" min={1} />
            </Form.Item>
            <MinusCircleOutlined
              style={locked ? { opacity: 0.5 } : {}}
              onClick={() => {
                if (!locked) {
                  remove(field.name)
                }
              }}
            />
          </div>)}
        <Button
          icon={<PlusCircleOutlined />}
          onClick={() => {
            const slots = form.getFieldValue(['slots']) || []
            const last = slots[slots.length - 1] || {}
            if (last.from && last.to) {
              add({
                from: last.to,
                to: moment(moment(last.to, 'H:mm')).add(moment(last.to, 'H:mm').diff(moment(last.from, 'H:mm'))).format('H:mm')
              })
            } else {
              add({ from: '8:00', to: '8:30' })
            }
          }}
          type="dashed"
          block
          style={{ marginTop: 12 }}
        >
          {t({pl: 'Dodaj slot', en: 'Add slot'})}
        </Button>
      </>}
    </Form.List>
  </Card>
}

const Category = ({ field, remove, shortsLocked, lang }) => {
  const [descVisible, setDescVisible] = useState(false)
  const EditIcon = descVisible ? EditFilled : EditOutlined

  return <Card
    className="category"
    key={field.key}
    style={{
      maxWidth: 500,
      margin: "12px 0",
    }}
    headStyle={{
      paddingLeft: 12,
      paddingRight: 12,
    }}
    title={
      <div>
        <div style={{ display: "flex", alignItems: 'center', flexWrap: 'wrap' }}>
          <MenuOutlined className="drag-category" style={{ cursor: 'move', marginRight: 12 }} />
          <Form.Item
            rules={[{ required: true, message: "required" }]}
            name={[field.name, "name", lang]}
            style={{ marginBottom: 0, marginRight: 12, flex: 1, minWidth: 100 }}
          >
            <Input style={{ fontWeight: 'bold' }} placeholder={t({pl: 'Nazwa kategorii', en: 'Category name'})} />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "required" }]}
            name={[field.name, "type"]}
            style={{ marginBottom: 0, marginRight: 12 }}
          >
            <Select style={{ width: 140 }} placeholder="Wybierz" options={categoryTypes()} />
          </Form.Item>
          <Form.Item
            name={[field.name, "limit"]}
            style={{ marginBottom: 0, marginRight: 12 }}
          >
            <InputNumber style={{ width: 58 }} placeholder="limit" min={1} />
          </Form.Item>
          <Tooltip title="dodaj opis">
            <EditIcon
              style={{ fontSize: 14, marginRight: 12 }}
              onClick={() => setDescVisible(!descVisible)}
            />
          </Tooltip>
          <MinusCircleOutlined
            style={{ fontSize: 14, opacity: shortsLocked ? 0.5 : 1 }}
            onClick={() => {
              if (!shortsLocked) {
                remove(field.name)
              }
            }}
          />
        </div>
        {descVisible &&
          <Form.Item
            name={[field.name, "desc", lang]}
          >
            <Input.TextArea
              autoSize={{ minRows: 5, maxRows: 30 }}
              placeholder="Opis"
              style={{ marginTop: 12 }}
            />
          </Form.Item>
        }
      </div>}
  >
    <Products listName={[field.name, "products"]} lang={lang} shortsLocked={shortsLocked} />
  </Card>
}

const MenuEditor = ({ menus, menu, day, reloadMenu }) => {
  const [form] = Form.useForm();
  const [lang, setLang] = useState(t({pl: 'pl', en: 'en'}))

  const [copyModal, setCopyModal] = useState(false)

  // short codes are locked if there are any orders for this day
  const response = useRequest(() => ApiShop.get('/hotel/orders', { params: { day: dayKey(day) } }), {
    initialData: false,
    formatResult: (x) => x.data && x.data.length == 0,
  })
  const shortsLocked = !response.data

  const menusToCopy = reverse(values(menus).filter(x => !x.menu_type || x.menu_type == 'breakfast').map(x => '' + x._id).sort())

  const save = (values) => {
    // validate keys duplicates
    const keys = {}
    const duplicates = filter(toPairs(countBy(flatMap(values.categories, (x) => x.products.map(x => x.short)))), ([short, count]) => count > 1)
    if (duplicates.length > 0) {
      Modal.error({
        title: "Skróty produktów muszą być unikatowe",
        content: `Powtórzone skróty: ${duplicates.map(x => x[0]).join(", ")}`
      })
      return
    }

    if (values.slots) {
      values.slots = sortBy(values.slots, (x) => padStart(x.from, 5))
    }

    ApiShop.post('/hotel/menu', {
      day: dayKey(day),
      menu: values,
    })
      .then(() => {
        message.success("Zmiany zapisane")
        reloadMenu()
      })
      .catch(handleSaveError())
  }

  return <div>
    <Row gutter={12} style={{ margin: "12px 0 24px" }}>
      <Col>
        <LangSwitcher lang={lang} setLang={setLang} />
      </Col>
      <Col>
        <Button key="print" icon={<PrinterOutlined />} disabled />
      </Col>
      <Col>
        <Button
          key="cp"
          icon={<CopyOutlined />}
          onClick={() => setCopyModal(menusToCopy[0])}
        >{t({pl: 'skopiuj z...', en: 'copy from...'})}</Button>
      </Col>
    </Row>

    <Form
      form={form}
      onFinish={(values) => {
        console.log(values)
        save(values)
      }}
      initialValues={menu}
    >
      <Row gutter={24} style={{ marginBottom: 24 }}>
        <Col>

          <Form.List name="categories">
            {(fields, { add, remove, move }) => <>

              <ReactDragListView
                nodeSelector=".category"
                handleSelector=".drag-category"
                onDragEnd={(from, to) => {
                  move(from, to)
                }}
              >
                <div style={{
                  // columns
                }}>
                  {fields.map(x => <Category
                    key={x.name}
                    field={x}
                    remove={remove}
                    shortsLocked={shortsLocked}
                    lang={lang}
                  />)}
                </div>
              </ReactDragListView>

              <Button
                icon={<PlusCircleOutlined />}
                block
                type="dashed"
                onClick={() => add({})}
                style={{
                  maxWidth: 500,
                  margin: "12px 0",
                }}
              >
                {t({pl: 'Dodaj kategorię', en: 'Add category'})}
              </Button>
            </>}
          </Form.List>
        </Col>
        <Col>
          <Slots
            form={form}
            locked={shortsLocked}
          />
        </Col>
      </Row>

      <Affix offsetBottom={0}>
        <div style={{
          paddingTop: 12,
          paddingBottom: 12,
          textAlign: 'center',
          borderTop: '1px solid #f0f0f0',
          background: "#fff",
          margin: "0 -24px -12px",
        }}>
          <Button type="primary" htmlType="submit" size="large">
            {t({pl: 'Zapisz', en: 'Save'})}
          </Button>
        </div>
      </Affix>

    </Form>

    <Modal
      visible={!!copyModal}
      onCancel={() => setCopyModal(false)}
      title={t({pl: "Skopiuj menu z:", en: 'Copy from:'})}
      onOk={() => {
        form.setFieldsValue(menus[copyModal])
        setCopyModal(null)
      }}
    >
      <Select
        options={menusToCopy.map(x => ({ label: x, value: x }))}
        style={{ width: 200 }}
        value={copyModal}
        onChange={setCopyModal}
        placeholder="Wybierz dzień"
      />
    </Modal>

  </div>
}



const MenuPage = ({ }) => {
  const menuReq = useRequest(() => ApiShop.get('/hotel/menu'))

  const { TabPane } = Tabs;

  const days = eachDayOfInterval({
    start: new Date(),
    end: addDays(new Date(), 7),
  })

  const menus = menuReq.data && menuReq.data.data

  if (!menus) {
    return <PanelLayout>
      <Spin />
    </PanelLayout>
  }

  return <PanelLayout>
    <Card title={t({pl: 'Konfiguracja menu śniadaniowego', en: 'Breakfast menu configuration'})}>
      <Tabs type="card">
        {days.map(d => {
          const menu = menus[(dayKey(d))]
          return <TabPane
            tab={<div style={{
              textAlign: 'center',
              fontWeight: menu ? 'bold' : 'normal',
            }}>
              {format(d, 'eeee', t({ pl: { locale: localePl }, en: {} }))}
              <br />
              {format(d, 'dd.MM.y', t({ pl: { locale: localePl }, en: {} }))}
            </div>}
            key={d}
          >
            <MenuEditor menus={menus} menu={menu} day={d} reloadMenu={() => menuReq.refresh()} />
          </TabPane>
        })}
      </Tabs>
    </Card>


  </PanelLayout>
}


export default MenuPage
