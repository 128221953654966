import React, { useState } from 'react';
import { Radio, InputNumber, Select } from 'antd';

const AvailabilityInput = ({ value, onChange, style, onSelected, autoFocus }) => {
    const selected = value <= 0 ? 'none' : (value >= 999 ? 'inf' : 'num')
    const setValue = (v) => onChange({ target: { value: v } })
    onSelected = onSelected || (() => {})

    return <div><Radio.Group
        onChange={(e) => {
            if (e.target.value == 'none') setValue(0);
            if (e.target.value == 'inf') setValue(999);
            if (e.target.value != 'num') onSelected();
        }}
        value={selected}
        style={style}
    >
        <Radio.Button
            value={'none'}
            style={{ padding: "0 8px" }}
        >
            Brak
        </Radio.Button>
        <Radio.Button
            value={'num'}
            style={{ padding: 0 }}
        >
            <InputNumber
                value={value}
                size="small"
                min={0}
                max={999}
                onChange={v => setValue(v)}
                type="number"
                style={{ width: 60, border: 'none' }}
                autoFocus={autoFocus}
                onBlur={onSelected}
            />
        </Radio.Button>
        <Radio.Button
            value={'inf'}
            style={{ padding: "1px 8px", fontSize: "11px" }}
        >
            Bez limitu
        </Radio.Button>
    </Radio.Group>
    </div>
}

export default AvailabilityInput

