
import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Input, Result, Spin, message, Modal, Select, Divider } from 'antd'
import { FileProtectOutlined } from '@ant-design/icons'
import { useLocation, useHistory } from 'react-router-dom'
import PanelLayout from './PanelLayout'
import { ApiShop, Api } from '../Api';
import { OnboardingSidebar, OnboardingsSteps } from './Onboarding'
import styled from 'styled-components';


const legalForms = [{ "id": 1, "name": "aip\/startup" }, { "id": 2, "name": "dzia\u0142alno\u015b\u0107 gospodarcza" }, { "id": 3, "name": "fundacja" }, { "id": 6, "name": "jednostki samorz\u0105du terytorialnego" }, { "id": 7, "name": "ko\u015bci\u00f3\u0142\/organizacja ko\u015bcielna" }, { "id": 8, "name": "organizacja po\u017cytku publicznego" }, { "id": 10, "name": "przedsi\u0119biorstwo pa\u0144stwowe" }, { "id": 11, "name": "sp\u00f3\u0142dzielnia" }, { "id": 12, "name": "sp\u00f3\u0142ka akcyjna" }, { "id": 13, "name": "sp\u00f3\u0142ka akcyjna w organizacji" }, { "id": 14, "name": "sp\u00f3\u0142ka cywilna" }, { "id": 16, "name": "sp\u00f3\u0142ka komandytowo-akcyjna" }, { "id": 17, "name": "sp\u00f3\u0142ka skarbu pa\u0144stwa" }, { "id": 18, "name": "sp\u00f3\u0142ka z o.o." }, { "id": 19, "name": "sp\u00f3\u0142ka z o.o. w organizacji" }, { "id": 20, "name": "stowarzyszenie" }, { "id": 21, "name": "uczelnie, jednostki o\u015bwiaty" }, { "id": 22, "name": "wsp\u00f3lnota mieszkaniowa" }, { "id": 23, "name": "inna" }, { "id": 24, "name": "sp\u00f3\u0142ka jawna" }, { "id": 25, "name": "sp\u00f3\u0142ka komandytowa" }, { "id": 26, "name": "sp\u00f3\u0142ka partnerska" }, { "id": 27, "name": "pa\u0144stwowa jednostka organizacyjna" }, { "id": 28, "name": "dzia\u0142alno\u015b\u0107 gospodarcza zagraniczna" }, { "id": 29, "name": "sp\u00f3\u0142ka zagraniczna" }]

const tpayCategories = [{ "id": 0, "name": "-" }, { "id": 60, "name": "Sklepy  us\u0142ugi sportowe" }, { "id": 61, "name": "Zdrowie i uroda" }, { "id": 62, "name": "Rozrywka" }, { "id": 63, "name": "Artykyu\u0142y dzieci\u0119ce" }, { "id": 64, "name": "Dom i ogr\u00f3d" }, { "id": 65, "name": "Przemys\u0142 spo\u017cywczy i gastronomia" }, { "id": 66, "name": "Poligrafia, wydawnictwa, ksi\u0119garnie" }, { "id": 67, "name": "Erotyka" }, { "id": 68, "name": "Artyku\u0142y biurowe i opakowania" }, { "id": 69, "name": "Artyku\u0142y chemiczne" }, { "id": 70, "name": "Portale internetowe" }, { "id": 71, "name": "Motoryzacja" }, { "id": 72, "name": "Reklama i media" }, { "id": 73, "name": "Sprzeda\u017c bilet\u00f3w" }, { "id": 74, "name": "Kursy i szkolenia" }, { "id": 75, "name": "Moda i akcesoria" }, { "id": 76, "name": "Bi\u017cuteria i r\u0119kodzie\u0142o" }, { "id": 77, "name": "Informatyka i oprogramowanie" }, { "id": 78, "name": "Us\u0142ugi finansowe" }, { "id": 79, "name": "AGD\/RTV i elektronika" }, { "id": 80, "name": "Budownictwo i narz\u0119dzia specjalistyczne" }, { "id": 81, "name": "Artyku\u0142y dla zwierz\u0105t" }, { "id": 82, "name": "Transport" }, { "id": 83, "name": "Inne" }, { "id": 84, "name": "Us\u0142ugi turystyczne" }, { "id": 85, "name": "Bukmacher" }]

const OptInForm = ({ shop, firstTime }) => {
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [activationLink, setActivationLink] = useState(false)
  const history = useHistory()

  const postRegistration = (values) => {
    setLoading(true)
    ApiShop.post('/tpay', values)
      .then(({data}) => {
        setLoading(false)
        setActivationLink(data['activation-link'] || '#')
        if(firstTime) {
          history.push('/settings?first-time')
        }
      })
      .catch(({ response }) => {
        setLoading(false)
        Modal.error({
          title: 'Wystąpił błąd wysyłania formularza. Sprawdź poprawność danych lub spróbuj później.',
          content: response.data ? response.data.error : '',
        })
      })
  }

  return <Spin spinning={loading}>
    {!activationLink &&
      <Card
        title="Rejestracja w systemie płatności TPay"
        style={{ marginBottom: 20 }}
      >
        <Form
          form={form}
          style={{ maxWidth: 800, margin: '0 auto' }}
          onFinish={postRegistration}
          labelCol={{
            xs: { span: 12 },
            sm: { span: 10 }
          }}
          wrapperCol={{
            xs: { span: 12 },
            sm: { span: 11, offset: 1 }
          }}
          initialValues={{
            email: shop['owner-email'],
            taxId: shop.nip,
          }}
        >



          <Divider>Dane firmy</Divider>

          <Form.Item
            name="name"
            label="Nazwa firmy"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>

          <Form.Item
            name="taxId"
            label="NIP"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>

          <Form.Item
            name="regon"
            label="Regon"
          >
            <Input
              placeholder=""
            />
          </Form.Item>

          <Form.Item
            name="krs"
            label="KRS"
          >
            <Input
              placeholder=""
            />
          </Form.Item>

          <Form.Item
            name="legalForm"
            label="Forma prawna"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Select>
              {legalForms.map(x => <Select.Option value={x.id}>{x.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            name="categoryId"
            label="Wybierz branże, w której działa Twoja firma"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Select>
              {tpayCategories.map(x => <Select.Option value={x.id}>{x.name}</Select.Option>)}
            </Select>
          </Form.Item>


          <Form.Item
            label="Ulica"
            name="street"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>
          <Form.Item
            label="Numer domu"
            name="houseNumber"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>
          <Form.Item
            label="Numer lokalu"
            name="roomNumber"
          >
            <Input
              placeholder=""
            />
          </Form.Item>
          <Form.Item
            label="Kod pocztowy"
            name="postalCode"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>
          <Form.Item
            label="Miasto"
            name="city"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>



          <Divider>Reprezentant firmy</Divider>
          <Form.Item
            label="Imię"
            name="person_name"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>
          <Form.Item
            label="Nazwisko"
            name="person_surname"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }]}
          >
            <Input
              placeholder=""
            />
          </Form.Item>
          <Form.Item
            name="email"
            label="E-mail"
            rules={[{
              required: true,
              message: 'Pole wymagane',
            }, {
              type: 'email',
              message: 'Wprowadź poprawny adres E-mail',
            }]}
            extra="Na ten adres zostaną wysłane dane dostępowe do konta TPay"
          >
            <Input
              placeholder="email@email.com"
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8 }}>
            {firstTime &&
              <Button
                onClick={() => history.push('/settings?first-time')}
                style={{marginRight: 24}}
              >
                POMIŃ
              </Button>}
            <Button type="primary" htmlType="submit">WYŚLIJ</Button>
          </Form.Item>
        </Form>
      </Card>}
    {activationLink && <Card style={{textAlign: 'center'}}>
      <Result
        status="success"
        title="Konto w TPay zostało utworzone"
        subTitle={<>Kliknij <a href={activationLink} target="_blank">tutaj</a> aby je aktywować. Link do aktywacji konta otrzymasz także w mailu.</>}
      />
    </Card>}
  </Spin>
}

const OnboardingInfo = () => {
  return <OnboardingSidebar>
      <h3>Płatności online</h3>
      {/*<p>1. </p>
      <p>2. </p>
      <p>3. </p>
      <p>4. </p>
      <p>5. </p>*/}
  </OnboardingSidebar>
}

const PaymentsConfigPage = ({ shopId }) => {

  const [shop, setShop] = useState()

  useEffect(() => {
    ApiShop.get('/details').then(({ data }) => {
      setShop(data)
    })
  }, [])

  const location = useLocation()
  const firstTime = location.search && !!location.search.match('first-time')


  const Wrapper = styled.div`
    @media(max-width: 768px) {
      .ant-card-head-title { font-size: 14px; }
      .ant-result-title {
          font-size: 24px;
          line-height: 1.2em;
          margin-bottom: 32px;
      }
      .ant-result-info { padding: 0; }
    }
    `

  if(!shop) {
    return <PanelLayout>
    <Spin spinning={!shop}/>
    </PanelLayout>
  }
  return <PanelLayout
    sidebarContent={firstTime && <OnboardingInfo />}
  >
    {firstTime && <OnboardingsSteps step={0} />}
    <Wrapper>
      {!shop.tpay_merchant_id &&
        <OptInForm shop={shop} firstTime={firstTime}/>
      }
      {shop.tpay_merchant_id &&
        <Card style={{ marginBottom: 20, textAlign: 'center' }}>
          <Result
            icon={<FileProtectOutlined />}
            title="Płatności online są dostępne"
            subTitle={!(shop.available_payment_types && shop.available_payment_types.online) ? "Możesz włączyć pobieranie płatności online w zakładce \"Ustawienia\"" : ""}
          />
          <a href="https://secure.tpay.com/panel/" target="_blank">
            <Button type="primary" style={{marginBottom: 50}}>Otwórz panel płatności TPay</Button>
          </a>
        </Card>
      }
    </Wrapper>
  </PanelLayout>
}

export default PaymentsConfigPage
