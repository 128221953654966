import React, { Component, useState } from 'react';
import { Button, Tabs, Card, Divider, Form, Input, message, Result } from 'antd';
import { DeleteOutlined, } from '@ant-design/icons';
import PanelLayout from './PanelLayout'
import styled from 'styled-components';
import { Api } from '../Api';
import { state } from '../models/AppState'

const { TabPane } = Tabs;

const content = {
    orders: (<div>
        W panelu zamówień znajdują się trzy zakładki:
        <ol>
            <li>Do skompletowania.</li>
            <li>Gotowe do wydania.</li>
            <li>Zakończone.</li>
        </ol>
        <p><b>1. Do skompletowania:</b></p>
        <p>Zamówienie trafia tutaj gdy klient wybierze produkty, poda dane kontaktowe oraz zatwierdzi złożenie zamówienia (oraz zapłaci jeśli wybierze płatność online). Zamówienia z tej zakładki mogą trafić do zakładki “Gotowe do wydania” jeśli kliknie się na przycisk “Do wydania” albo zakładki “Zakończone” jeśli kliknie się czerwoną ikonkę kosza (<DeleteOutlined style={{ color: '#ff4d4f' }}/>).</p>

        <p style={{ color: '#ff4d4f' }}>UWAGA! Anulowanie zamówienia opłaconego online wiążę się ze zwrotem środków i nie można go cofnąć.</p>

        <p>Kompletowanie zamówienia odbywa się poprzez odznaczenie kwadraciku obok danego produktu. Jeśli produkt jest niedostępny pozostawiamy kwadracik pusty (po kliknięciu “Do wydania” klient otrzyma listę produktów z oznaczeniem produktów niedostępnych).</p>

        <p>Po spakowaniu całego zamówienia oraz wystawieniu paragonu klikamy “Do wydania” , klient otrzyma maila z informacją o możliwości odbioru bądź przy dostawie, z informacją iż zamówienie zostanie dostarczone w czasie określonym w ustawieniach.</p>

        <img
            src="/imgs/orders-help.png"
            style={{width: '90%', margin: '24px auto', display: 'block'}}
        />

        <p><b>2. Gotowe do wydania:</b></p>

        <p>Zamówienie trafia tutaj gdy w zakładce “Do skompletowania” kliknie się przycisk “Do wydania”.</p>

        <p>Jeśli klient przyjdzie odebrać zamówienie należy otworzyć zamówienie klikając na nie, sprawdzić klikając na ikonkę czy zostało opłacone - jeśli nie jest opłacone pobrać płatność od klienta metodą zgodną z paragonem, następnie wpisać PIN podany przez klienta i wydać zamówienie klikając “Wydaj zamówienie”.</p>

        <p>Jeśli zauważymy, że zamówienie jest skompletowanie nie poprawnie bądź przez przypadek zostało przeniesione do tej zakładki możemy kliknąć “Przywróć do “Do skompletowania”. UWAGA! Opcja dostępna tylko dla zamówień nie opłaconych online.</p>

        <p>Jeśli chcemy anulować zamówienie klikamy w czerwony przycisk kosza (<DeleteOutlined style={{ color: '#ff4d4f' }}/>). UWAGA! Przy zamówieniach opłaconych online czynności nie da się cofnąć.</p>

        <p><b>3. Zakończone:</b></p>

        <p>Trafiają tutaj zamówienia odebrane przez klientów, dostarczone oraz anulowane. Można je przywrócić do poprzednich zakładek o ile nie są zamówieniami opłaconymi online.</p>
    </div>),

    products: (<div>
        <p>W bazie produktów znajdują się produkty dodane w zakładce “Dodawanie produktów”.</p>

        <p>Możesz edytować ich: nazwę, cenę, jednostkę sprzedaży i stan magazynowy poprzez kliknięcie na nie.</p>

        <p>Personalizacja produktów pozwala na tworzenie produktów składających się z więcej niż jednej pozycji, np dania restauracyjne składające się z przystawki, dania głównego oraz dodatków.</p>

        <p>Czerwony kosz (<DeleteOutlined style={{ color: '#ff4d4f' }}/>) powoduje usunięcie produktu.</p>

    </div>),



    shop: (<div>
        <p>Wizytówka biznesu służy do przedstawienia swojego biznesu, jest to mini strona internetowa sklepu. Link do niej możesz umieszczać na swoich social mediach bądź w innych miejscach w internecie aby powiadomić swoich klientów o możliwości składania zamówień online.</p>

        <p>Jest ona miejscem gdzie klienci mogą dowiedzieć się czegoś więcej o sklepie zanim zobaczą jego produkty - możesz traktować ją jak witrynę swojego sklepu.</p>

        <p>Po kliknięciu na przycisk “Wejdź do sklepu” klienci trafią do listy produktów.</p>
    </div>),

    settings: (<div>
       <p>Informacje umieszczane w ustawieniach są niezwykle ważne. Na ich podstawie będzie organizowane całe działanie sklepu online: koszty dodatkowych usług, czas jaki pracownicy mają na skompletowanie zamówienia albo dostarczenie go do klienta.</p>

       <p>Każda informacja umieszczana w tej zakładce powinna być przemyślana oraz przekazana osobom obsługującym zamówienia złożone online.</p>

       <p>Ważne jest aby pamiętać o aktualizowaniu tej zakładki! Jeśli na przykład zamówienia pakowane są dużo szybciej niż podany tutaj czas warto jest go zmienić aby informacje przesyłane do klientów mailowo były jak najdokładniejsze.</p>
    </div>),

    payments: (<div>
        <p>Zakładka ta służy do wysłania formularza zgłoszeniowego do TPay o uruchomienie płatności.</p>

        <p>W przypadku problemów z płatnościami prosimy o przesłanie nam wiadomości opisującej problem poprzez formularz kontaktowy.</p>
    </div>),
}

const Text = styled.div`
font-size: 14px;
text-align: justify;
max-width: 800px;
b {
    color: #2E773A;
}
`

const MobileHidden = styled.div`
@media(max-width: 600px) {
    display: none;
}
`

const Icon = ({ name }) => <span className={'icon-' + name} style={{ fontSize: '1.4em', display: 'block'}} />

const HelpPage = ({ }) => {
    const [msgSend, setMsgSend] = useState(false)

    const submitMessage = (values) => {
        return Api.post('/contact-form/email', {
            message: values.message,
            from: state.session.user,
            customer_type: 'business'
        }).then(() => {
            setMsgSend(true)
        }).catch(() => {
            message.error("Nie udało się wysłać wiadomości. Spróbuj później.")
        })
    }

    return <PanelLayout>
        <MobileHidden>
            <Card title="FAQ - często zadawane pytania">
                <Tabs defaultActiveKey="1" tabPosition="left">
                    <TabPane tab={<><Icon name="Cash-register2" />Zamówienia</>} key="1">
                        <Text>{content.orders}</Text>
                    </TabPane>
                    {/*<TabPane tab={<><Icon name="Add" />Dodawanie<br/> produktów</>} key="2">
                        <Text>{content.sklep}</Text>
                    </TabPane>*/}
                    <TabPane tab={<><Icon name="Bulleted-List" />Baza<br/> produktów</>} key="3">
                        <Text>{content.products}</Text>
                    </TabPane>
                    <TabPane tab={<><Icon name="Shop" />Wizytówka<br/> biznesu</>} key="4">
                        <Text>{content.shop}</Text>
                    </TabPane>
                    <TabPane tab={<><Icon name="Gears" />Ustawienia</>} key="5">
                        <Text>{content.settings}</Text>
                    </TabPane>
                    <TabPane tab={<><Icon name="Credit-Card" />Płatności<br/> online</>} key="6">
                        <Text>{content.payments}</Text>
                    </TabPane>
                </Tabs>
            </Card>
        </MobileHidden>
        <Card>

            <div style={{ textAlign: "center" }}>
                {!msgSend && <div>
                    <h2>Nie znalazłeś odpowiedzi na swoje pytanie?</h2>
                    <h2>Napisz do nas!</h2>
                    <Form
                        layout="vertical"
                        style={{ maxWidth: 500, margin: "0 auto" }}
                        onFinish={submitMessage}
                    >
                        <Form.Item
                            label="Wiadomość:"
                            name="message"
                            rules={[{
                                required: true,
                                message: 'Wpisz swoją wiadomość',
                            }]}
                        >
                            <Input.TextArea
                                placeholder="Twoje pytanie"
                                rows={5}
                            />
                        </Form.Item>

                        <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                            WYŚLIJ ZGŁOSZENIE
                        </Button>
                    </Form>
                </div>}
                {msgSend && <Result
                    status="success"
                    title="Wiadomość wysłana!"
                    subTitle="Odpowiemy jak najszybciej."
                />}
            </div>
        </Card>
    </PanelLayout>
}

export default HelpPage
