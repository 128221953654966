import React, { useState, useEffect } from 'react';
import { Divider, Table, Card, Button, Input, Radio, Form, useForm, InputNumber, message } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import PanelLayout from './PanelLayout'
import { EditableCell } from './Utils'
import { debounce } from '../utils'
import styled from 'styled-components'
import { ApiShop } from '../Api';

const OptionsWrapper = styled.div`
display: flex;
flex-wrap: wrap;
padding: 0 16px;
> * {
    width: 300px;
}
`

const Option = styled.div`
display: flex;
margin-right: 20px;

a { 
    margin-top: 5px;
    margin-left: 5px;
    color: #888;
    opacity: 0;
}
&:hover > a { opacity: 1; }
`

const getProductsOptions = () => {
    return ApiShop.get('/details').then(({data}) => {
        return data.product_options || {}
    })
}

const saveProductsOptions = (data) => {
    return ApiShop({
        method: 'PUT',
        url: '/details',
        data: {
            product_options: data,
        },
    })
}

const ExpandedRow = ({ record, setData }) => {
    const [form] = Form.useForm()

    return <div>
        <Form
            initialValues={record}
            layout="vertical"
            style={{ margin: "-5px -12px" }}
            form={form}
            onFinish={(values) => setData({values, done: () => {}})}
            onValuesChange={() => form.submit()}
        >
            <Form.Item
                name="type"
                rules={[{
                    required: true,
                    message: 'Wybierz rodzaj opcji',
                }]}
                style={{marginLeft: 16}}
            >
                <Radio.Group>
                    <Radio value="multi" style={{display: 'block', lineHeight: '30px'}}>Opcja wielokrotnego wyboru</Radio>
                    <Radio value="single" style={{display: 'block', lineHeight: '30px'}}>Opcja jednokrotnego wyboru</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.List name="options">
                {(items, { add, remove }) => <OptionsWrapper>
                    {items.map((field, idx) => (
                        <Option key={field.name}>
                            <Form.Item
                                name={[field.name, "label"]}
                                style={{flex: 1}}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item name={[field.name, "price"]}>
                                <InputNumber
                                    min={-1000}
                                    max={1000}
                                    formatter={value => value ? `${value} zł` : ''}
                                    parser={value => value.replace(',','.').replace(' zł', '')}
                                    precision={2}
                                    placeholder="cena"
                                    style={{width: 82}}
                                />
                            </Form.Item>
                            <a onClick={() => remove(field.name)}>
                                <CloseOutlined/>
                            </a>
                        </Option>
                    ))}
                    <Form.Item
                        //name="_new"
                    >
                    <Input.Search
                        placeholder="dodaj..."
                        enterButton="+"
                        onSearch={(x, e) => {
                            add({ label: x })
                            //form.setFieldsValue({_new: ""})
                            e.preventDefault()
                        }}
                        style={{width: 241}}
                    />
                    </Form.Item>
                </OptionsWrapper>}
            </Form.List>
        </Form>
    </div>
}

const ProductOptionsPage = ({ }) => {
    const [inEdit, setInEdit] = useState()
    const [data, setData] = useState({})

    const addNew = () => {
        setData({
            ...data,
            [new Date().getTime()]: {
                name: 'Nowa grupa',
                title: 'Tytuł...',
            }
        })
    }

    useEffect(() => {
        getProductsOptions().then(setData)
    }, [])

    const updateData = (values) => {
        const {id, ...rest} = values
        const d = {
            ...data,
            [id]: {...data[id], ...rest},
        }
        setData(d)
        saveProductsOptions(d).catch(() => {
            message.error("Wystąpił błąd podczas zapisywania. Spróbuj później")
        })
        console.log("save", d)
    }

    const inlineSave = (id) => ({ values, done }) => {
        updateData({id, ...values})
        done()
    }

    const columns = [{
        title: 'Nazwa',
        dataIndex: 'name',
        width: 200,
        render: (text, record, index) => (
            <EditableCell
                onFinish={inlineSave(record.id)}
                staticContent={`${text} (${(record.options || []).length})`}
                record={record}
                fieldName="name"
                field={({ cancel, save }) => <Input
                    placeholder="Nazwa"
                    onBlur={save}
                    autoFocus
                />}
            />
        )
    }, {
        title: 'Tytuł',
        dataIndex: 'title',
        render: (text, record, index) => (
            <EditableCell
                onFinish={inlineSave(record.id)}
                staticContent={text}
                record={record}
                fieldName="title"
                field={({ cancel, save }) => <Input
                    placeholder="Tytuł"
                    onBlur={save}
                    autoFocus
                />}
            />
        )
    }, {
        title: '',
        width: 150,
        align: 'right',
        render: (_, record) => (
            <>
                <a
                    onClick={() => {
                        setInEdit(record);
                    }}
                >
                    Edytuj
                </a>
                <Divider type="vertical" />
                <a href="">Usuń</a>
            </>
        ),
    }]

    const dataToList = (d) => Object.entries(d).map(([k, v]) => ({ id: k, ...v }))
    

    return <PanelLayout>
        <Card
            title="Dodaj grupy a następnie opcje personalizacji"
            extra={<Button type="primary" onClick={addNew}>Dodaj</Button>}
        >
            <Table
                dataSource={dataToList(data)}
                columns={columns}
                rowKey="id"
                expandable={{
                    expandedRowKeys: inEdit && [inEdit.id],
                    expandedRowRender: (record, index) => <ExpandedRow
                        record={record}
                        setData={ debounce(inlineSave(record.id), 3000) }/>,
                    onExpand: (expanded, record) => {
                        if (expanded) {
                            setInEdit(record)
                        } else {
                            setInEdit(undefined)
                        }
                    }
                }}
            />
        </Card>
    </PanelLayout>
}

export default ProductOptionsPage
