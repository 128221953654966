import React, { useState, useEffect } from 'react'
import { HotelPanelLayout as PanelLayout, t } from '../components/PanelLayout';
import { Form, Button, Input, Card, Select, InputNumber, Tabs, Typography, Spin, Empty, message, Modal, Row, Col, Statistic, Divider } from "antd";
import { TeamOutlined, FieldTimeOutlined, ExclamationOutlined, PlusOutlined, ExclamationCircleOutlined, UserOutlined, RestOutlined, CheckOutlined } from "@ant-design/icons";
import { useRequest, useCreation, useMap } from "ahooks";
import { format, startOfTomorrow } from 'date-fns'
import { ApiShop } from '../Api';
import { reverse, groupBy, toPairs, sortBy, some, keys, countBy, padStart } from 'lodash'
import { debounce, handleSaveError } from '../utils'


const addOrder = (day, slot, room) => {
  return ApiShop.post(`/hotel/orders`, {
    day,
    slot,
    room,
    orders: [{ products: {} }]
  })
    .catch(handleSaveError("Nie udało się dodać zamówienia"))
}

const saveOrder = (id, values) => {
  return ApiShop.put(`/hotel/order/${id || 'new'}`, values).catch(handleSaveError("Nie udało się zapisać zmian"))
}

const removeOrder = (id) => {
  return ApiShop.delete(`/hotel/order/${id}`).catch(handleSaveError("Nie udało się usunąć zamówienia"))
}



const WithChangeButton = ({ value, input, onChange }) => {
  const [clicked, setClicked] = useState(false)

  if (clicked) {
    return <div style={{ display: 'inline-block' }}>
      {input}
      <Button
        onClick={() =>
          onChange(() => setClicked(false))
        }
      >OK</Button>
    </div>
  }

  return <span>
    {value}
    <Button
      onClick={() => setClicked(true)}
      type="primary"
      style={{ marginLeft: 12 }}
    >
      {t({pl: 'zmień', en: 'change'})}
    </Button>
  </span>
}

const RoomChangeButton = ({ number, changeRoomNumber }) => {
  const [newNumber, setNewNumber] = useState(number)
  const parse = (x) => (x.match(/[1-9]\d*/) || [])[0]
  const change = (close) => {
    Modal.confirm({
      title: <>Czy na pewno chcesz zmienić numer pokoju gościa na <b>{newNumber}</b>?</>,
      content: <Typography.Text type="warning">Pamiętaj o wydaniu nowej karty QR z odpowiednim numerem pokoju.</Typography.Text>,
      okText: 'Zmień',
      cancelText: 'Anuluj',
      onOk: () => {
        changeRoomNumber(newNumber)
        close()
      },
      onCancel: close,
    })
  }

  return <WithChangeButton
    value={number}
    input={<Input
      placeholder="Nowy numer pokoju"
      style={{ width: 160 }}
      value={newNumber}
      onChange={(e) => setNewNumber(parse(e.target.value || ''))}
      type="tel"
      autoFocus
    />}
    onChange={change}
  />
}

const SlotChangeButton = ({ slot, changeSlot, slots }) => {
  const [newSlot, setNewSlot] = useState(slot)

  return <WithChangeButton
    value={slot}
    input={<Select
      value={newSlot}
      options={slots.map(x => ({ label: x.id, value: x.id }))}
      onChange={(v) => setNewSlot(v)}
    />}
    onChange={(close) => {
      changeSlot(newSlot)
      close()
    }}
  />
}

const Comments = ({ title, msg }) => {
  if (msg) {
    return <div><p>{title}:</p>
      <p style={{
        borderLeft: '2px solid #eee',
        paddingLeft: 12,
      }}>{msg}</p>
    </div>
  } else {
    return <div />
  }
}

const OrderPreview = ({ order, menu, debouncedSave, reloadOrders, tabId, confirmOrder }) => {
  const cats = menu.categories.map((c) => {
    return {
      ...c,
      products: c.products.filter(x => order.products && order.products[x.short] > 0)
    }
  })

  return <div>
    {cats.map((cat) => {
      if (cat.products.length) {
        return <div key={t(cat.name)}>
          <p>{t(cat.name)}</p>
          <ul>
            {cat.products.map(p => (
              <li key={p.short}>
                [<b>{p.short}</b>] {t(p.name)}
                {order.products[p.short] > 1 && <b> × {order.products[p.short]}</b>}
              </li>
            ))}
          </ul>
        </div>
      }
    })}
    <Comments title={t({pl: 'Uwagi gościa', en: 'Guests requests'})} msg={order.note} />
    <label>{t({pl: 'Uwagi od frontdesku', en: 'Frontdesk comments'})}:</label>
    <Input.TextArea
      placeholder="Uwagi"
      defaultValue={order.frontdesk_note}
      onChange={(e) => debouncedSave(order._id, { frontdesk_note: e.target.value })}
      style={{ marginBottom: 24 }}
    />

    <Button
      style={{ marginRight: 12 }}
      icon={<RestOutlined />}
      onClick={() => {
        Modal.confirm({
          title: 'Czy usunąć to zamówienie',
          icon: <ExclamationCircleOutlined />,
          onOk() {
            removeOrder(order._id).then(() => reloadOrders())
          },
        })
      }}
    >
      {t({pl: 'Usuń śniadanie', en: 'Delete breakfast'})} #{tabId}
    </Button>
    {order.not_finished && <Button
      icon={<CheckOutlined />}
      type="primary"
      onClick={() => {
        Modal.confirm({
          title: 'Czy potwierdzić to zamówinie',
          icon: <ExclamationCircleOutlined />,
          onOk: confirmOrder,
        })
      }}
    >
      {t({pl: 'POTWIERDŹ ZAMÓWIENIE', en: 'CONFIRM BREAKFAST'})}
    </Button>}

  </div>
}

const OrderEdit = ({ order, menu, onFinish }) => {
  const [form] = Form.useForm()
  const inCol = Math.ceil(menu.categories.length / 2)
  const columns = [menu.categories.slice(0, inCol), menu.categories.slice(inCol)]

  return <Form
    labelCol={{ span: 18 }}
    wrapperCol={{ span: 6 }}
    initialValues={order}
    form={form}
    onFinish={(values) => {
      saveOrder(order._id, { ...order, ...values }).then(() => {
        message.success("Zamówienie zapisane")
        form.resetFields()
        onFinish()
      })
    }}
  >
    <div>
      {columns.map((column, i) => (
        <div key={i} style={{ display: 'inline-block', verticalAlign: 'top' }}>
          {column.map(cat => (
            <Card
              key={cat.name.pl}
              title={cat.name.pl}
              size="small"
              style={{
                width: 300,
                margin: 12,
              }}
            >
              {cat.products.map(p => {
                return <Form.Item
                  name={["products", p.short]}
                  label={t(p.name)}
                  colon={false}
                  labelAlign="left"
                >
                  <InputNumber
                    size="large"
                    min={0}
                    style={{ width: 64 }}
                  //defaultValue={order.products[p.short]}
                  //onChange={(v) => saveOrder(order._id, { ['products.' + p.short]: v })}
                  />
                </Form.Item>
              })}
            </Card>
          ))}
        </div>
      ))}
    </div>
    <Form.Item
      name="note"
      label={"Uwagi gościa:"}
      colon={false}
      labelAlign="left"
      labelCol={24}
      wrapperCol={24}
    >
      <Input.TextArea />
    </Form.Item>
    <div style={{
      textAlign: 'center',
      marginTop: 12,
    }}>
      <Button type="primary" htmlType="submit">
        Zapisz
      </Button>
    </div>
  </Form>
}

const OrderHeader = ({ orders, changeRoomNumber, slots, changeSlot }) => {

  return <Row gutter={16} style={{ marginBottom: 36 }} justify="space-between">
    <Col>
      <div className="ant-statistic-title">{t({pl: 'Pokój', en: 'Room'})}:</div>
      <div className="ant-statistic-content">
        {changeRoomNumber
          ? <RoomChangeButton number={orders[0].room} changeRoomNumber={changeRoomNumber} />
          : orders[0].room}
      </div>
    </Col>
    <Col>
      <div className="ant-statistic-title">Slot:</div>
      <div className="ant-statistic-content">
        {changeSlot
          ? <SlotChangeButton slot={orders[0].slot} slots={slots} changeSlot={changeSlot} />
          : orders[0].slot}
      </div>
    </Col>
    <Col>
      <div className="ant-statistic-title">{t({pl: 'Dzień', en: 'Day'})}:</div>
      <div className="ant-statistic-content">{orders[0].day}</div>
    </Col>
  </Row>
}

const OrderEditPopup = ({ order, menu, setNewOrder, reloadOrders }) => {
  return <div>
    <p style={{ fontSize: 24 }}>
      {t({pl: 'Nowe śniadanie', en: 'New breakfast'})}
    </p>
    <OrderHeader orders={[order]} />
    <OrderEdit
      order={order}
      menu={menu}
      onFinish={() => {
        setNewOrder(null)
        reloadOrders()
      }}
    />
  </div>
}

const OrderPopup = ({ orders, menu, reloadOrders, setNewOrder }) => {
  const [activeTab, setActiveTab] = useState(0)

  if (!menu || !menu.categories) {
    return <Empty description="Brak menu na wybrany dzień" />
  }

  const debouncedSave = debounce(saveOrder, 1000)

  const changeRoomNumber = (number) => {
    Promise.all(orders.map(x => saveOrder(x._id, { room: number }))).then(reloadOrders)
  }
  const changeSlot = (slot) => {
    Promise.all(orders.map(x => saveOrder(x._id, { slot: slot }))).then(reloadOrders)
  }
  const confirmOrder = () => {
    Promise.all(orders.map(x => saveOrder(x._id, { not_finished: false }))).then(reloadOrders)
  }

  return <div>
    <p style={{ fontSize: 24 }}>
      {t({pl: 'Zamówienie', en: 'Order'})}
      <small style={{ marginLeft: 12, color: "rgba(0, 0, 0, 0.45)", fontSize: 14 }}>{t({pl: 'złożone', en: 'ordered'})}: {format(new Date(orders[0].created_at), "HH:mm, dd-MM-yyyy")} </small>
    </p>
    <div style={{ display: 'none' }}><Statistic /></div>

    <OrderHeader
      orders={orders}
      changeRoomNumber={changeRoomNumber}
      slots={menu.slots}
      changeSlot={changeSlot}
    />

    <Tabs
      type="card"
      defaultActiveKey={orders[orders.length - 1]._id}
      tabBarExtraContent={{
        right: <Button
          type="primary"
          onClick={() => {
            setNewOrder({
              day: orders[0].day,
              room: orders[0].room,
              slot: orders[0].slot,
            })
          }}
          icon={<PlusOutlined />}
        >{t({pl: 'Dodaj śniadanie', en: 'Add breakfast'})}</Button>
      }}
      activeKey={'' + activeTab}
      onTabClick={setActiveTab}
    >
      {orders.map((x, i) => {

        return <Tabs.TabPane
          tab={<><UserOutlined /> {t({pl: 'Gość', en: 'Guest'})} #{i + 1}</>}
          key={i}
        >
          <div style={{ padding: '0 12px' }}>
            <OrderPreview
              order={x}
              menu={menu}
              debouncedSave={debouncedSave}
              reloadOrders={reloadOrders}
              tabId={i + 1}
              confirmOrder={confirmOrder}
            />
          </div>
        </Tabs.TabPane>
      })}
    </Tabs>

  </div>
}

const Orders = ({ orders, edit }) => {

  return <div>
    {orders && orders.map(([room, orders]) => (
      <div
        key={room}
        style={{
          display: 'inline-block',
          border: orders[0].not_finished ? '1px solid #faad14' : '1px solid #f0f0f0',
          borderRadius: 6,
          padding: 12,
          margin: 12,
          width: 130,
          textAlign: 'center',
          cursor: 'pointer',
        }}
        onClick={() => edit({ room: room, day: orders[0].day })}
      >
        <div style={{ fontSize: '1.2em', marginBottom: 6, fontWeight: 600 }}>{room}
          {some(orders, x => x.note) && <ExclamationOutlined style={{ float: 'right', marginTop: 5 }} />}
        </div>
        <FieldTimeOutlined />&nbsp;{orders[0].slot}<br />
        <TeamOutlined />&nbsp;{orders.length}
      </div>
    ))}
  </div>
}

const NewOrderPopup = ({ visible, onCancel, menu, day, orders, onFinish, setInPopup }) => {
  const [newOrder, setNewOrder] = useState({ day })
  return <Modal
    visible={visible}
    width={600}
    onCancel={onCancel}
    title={t({pl: "Dodaj zamówienie", en: 'Add order'})}
    onOk={() => {
      if (!newOrder.room || !newOrder.day || !newOrder.slot) {
        message.error('Uzupełnij numer pokoju, dzień oraz slot');
        return;
      }
      setInPopup(newOrder)
      if (!some(orders, x => x.day == newOrder.day && x.room == newOrder.room)) {
        onFinish(newOrder)
      }
      onCancel()
    }}
  >
    <Row gutter={24} justify="space-between">
      <Col>
        <label>
          {t({pl: 'Numer pokoju', en: 'Room number'})}:<br />
        </label>
        <Input
          style={{width: 130}}
          placeholder="1001"
          value={newOrder.room}
          onChange={(e) => setNewOrder({ ...newOrder, room: e.target.value })}
        />
      </Col>
      <Col>
        <label>
        {t({pl: 'Dzień', en: 'Day'})}:<br />
        </label>
        <Select
          style={{width: 130}}
          value={newOrder.day}
          options={reverse(sortBy(keys(menu))).map(x => ({ value: x, label: x }))}
          onChange={(v) => setNewOrder({ ...newOrder, day: v, slot: null })}
        />
      </Col>
      <Col>
        <label>
          Slot:<br />
        </label>
        <Select
          style={{width: 130}}
          disabled={!newOrder.day}
          value={newOrder.slot}
          options={((menu && menu[newOrder.day] || {}).slots || []).map(x => ({ label: x.id, value: x.id }))}
          onChange={(v) => setNewOrder({ ...newOrder, slot: v })}
        />
      </Col>
    </Row>
  </Modal>
}

const OrdersPage = ({ }) => {
  const response = useRequest(() => ApiShop.get('/hotel/orders'), {
    pollingInterval: 10000,
    pollingWhenHidden: false,
    refreshOnWindowFocus: true,
  })
  const orders = response.data && response.data.data
  const reloadOrders = response.run
  const days = useCreation(() => orders && countBy(orders, x => x.day), [orders])

  const [dayFilter, setDayFilter] = useState(format(startOfTomorrow(), 'yyyy-MM-dd'))
  const [slotFilter, setSlotFilter] = useState()
  const [searchFilter, setSearchFilter] = useState()

  const [inPopup, setInPopup] = useState()
  const ordersInPopup = inPopup ? (orders || []).filter(x => x.day == inPopup.day && x.room == inPopup.room) : []

  const [newOrder, setNewOrder] = useState(false)
  const [addOrderVisible, setAddOrderVisible] = useState(false)

  const ordersInDay = useCreation(() => {
    return orders && orders.filter(x => {
      return x.day == dayFilter
    })
  }, [orders, dayFilter])

  const results = useCreation(() => {
    return orders ? sortBy(
      toPairs(
        groupBy(
          ordersInDay.filter(x => {
            return (!searchFilter || ('' + x.room).match(searchFilter))
              && (!slotFilter || x.slot == slotFilter)
          }), x => x.room)
      ), x => +x[0]) : []
  }, [ordersInDay, slotFilter, searchFilter])

  const resultsConfirmed = useCreation(() => results.filter(([room, orders]) => !orders[0].not_finished), [results])
  const resultsNotFinished = useCreation(() => results.filter(([room, orders]) => orders[0].not_finished), [results])

  const menuR = useRequest(() => ApiShop.get('/hotel/menu'))
  const menu = menuR.data && menuR.data.data

  if (!orders) {
    return <PanelLayout>
      <Spin />
    </PanelLayout>
  }

  const DayFilter = <Select
    key="day"
    placeholder="Wybierz dzień"
    showSearch
    value={dayFilter}
    options={reverse(sortBy(toPairs(days), x => x[0])).map(([k, v]) => ({ label: `${k} (${v})`, value: k }))}
    onSelect={(x) => {
      setDayFilter(x)
      setSlotFilter(null)
    }}
    style={{ width: 150, marginRight: 12 }}
  />
  const SlotFilter = <Select
    key="slot"
    placeholder={t({pl: 'Wybierz slot', en: 'Choose slot'})}
    showSearch
    value={slotFilter}
    options={orders && sortBy(toPairs(countBy(ordersInDay, x => x.slot)), x => padStart(x[0], 13, '0')).map(([k, v]) => ({ label: `${k} (${v})`, value: k }))}
    onSelect={setSlotFilter}
    allowClear
    onClear={() => setSlotFilter(null)}
    style={{ width: 140, marginRight: 12 }}
  />

  return <PanelLayout>
    <Card
      title={t({pl: 'Zamówienia', en: 'Orders'})}
      extra={[
        DayFilter,
        SlotFilter,
        <Input.Search
          key="room"
          placeholder={t({pl: "Szukaj pokoju...", en: 'Search room...'})}
          style={{ width: 'auto', marginRight: 12 }}
          onSearch={(x) => setSearchFilter(x)}
          allowClear
        />,
        <Button
          key="add"
          icon={<PlusOutlined />}
          onClick={() => setAddOrderVisible(true)}
        >{t({pl: 'Dodaj', en: 'Add'})}</Button>,
      ]}
    >
      {resultsNotFinished.length > 0 && <div>
        <p style={{fontSize: 16}}>Otwarte zamówienia</p>
        <Orders orders={resultsNotFinished} edit={setInPopup} />
        <Divider/>
      </div>}
      <Orders orders={resultsConfirmed} edit={setInPopup} />
    </Card>

    <Modal
      visible={!!ordersInPopup.length}
      onCancel={() => setInPopup(null)}
      footer={null}
      width={820}
    >
      {ordersInPopup.length &&
        <OrderPopup
          key={`${inPopup.room},${inPopup.day}`}
          orders={ordersInPopup}
          menu={menu[ordersInPopup[0].day]}
          reloadOrders={reloadOrders}
          setNewOrder={setNewOrder}
        />
      }
    </Modal>

    <Modal
      visible={!!newOrder}
      footer={null}
      width={700}
      onCancel={() => setNewOrder(null)}
      zIndex={1001}
    >
      {newOrder &&
        <OrderEditPopup
          order={newOrder}
          setNewOrder={setNewOrder}
          menu={menu[newOrder.day]}
          reloadOrders={reloadOrders}
        />
      }
    </Modal>


    <NewOrderPopup
      visible={addOrderVisible}
      day={dayFilter}
      menu={menu}
      orders={orders}
      onCancel={() => setAddOrderVisible(false)}
      onFinish={setNewOrder}
      setInPopup={setInPopup}
    />


  </PanelLayout>
}


export default OrdersPage
