import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import { Button, Drawer, Input, Form, InputNumber, Upload, Card, message, Switch, Select, Row, Col, Radio } from 'antd';
import OutsideClickHandler from 'react-outside-click-handler';

import { CameraOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { ApiShop, getShopId } from '../Api';
import { formatPrice, imageUrl } from '../utils'
import AvailabilityInput from './AvailabilityInput'
import { HelpButton } from './Utils'

const saveImage = (productId, image) => {
    const formData = new FormData()
    formData.append("file", image)
    return ApiShop.post(`/product/${productId}/image`, formData, { 'Content-Type': 'multipart/form-data' })
}

const saveProduct = (data) => {
    data.category = '' //fixme
    var image = null

    if (data.image) {
        image = data.image[0]
        delete data.image
        data.image_type = 'custom'
    }

    // todo: handle brandbank_id
    return ApiShop.put(`/product/${data.id}`, data)
        .then(() => {
            if (image) {
                return saveImage(data.id, image)
            }
        }).then(() => {
            message.success("Zapisano")
        })
}

const stripHtml = (s) => {
    return (s || '').replace(/(<([^>]+)>)/ig, '')
}

/// xxx: copied form frontend/ProductDetails
const ProductDescription = ({ product }) => {
    const fetchDetails = (id) => {
        return ApiShop.get(`/product/${id}`).then(({ data }) => data)
    }
    const [data, setData] = useState()
    const details = data && data.description
    useEffect(() => {
        fetchDetails(product.id).then(setData)
    }, [product])

    const Label = styled.div`
        font-weight: 600;
    `
    const Value = styled.div``
    const Desc = styled.div`
        margin-bottom: 16px;
        break-inside: avoid;
    `

    const desc = (x) => {
        const res = []

        if (x.empty) {
            res.push(<Desc>
                <Value style={{ color: "#aaa" }}>Brak opisu produktu</Value>
            </Desc>)
        }

        if (x.custom) {
            res.push(<Desc>
                <Value>{x.custom}</Value>
            </Desc>)
        }

        if (x.marketing) {
            res.push(<Desc>
                <Value>{x.marketing}</Value>
            </Desc>)
        }
        if (x.ingredients) {
            res.push(<Desc>
                <Label>Skład:</Label>
                <Value>{stripHtml(x.ingredients.join ? x.ingredients.join(', ') : x.ingredients)}</Value>
            </Desc>)
        }
        if (x.storage) {
            res.push(<Desc>
                <Label>Przechowywanie:</Label>
                <Value>{x.storage}</Value>
            </Desc>)
        }
        if (x.allergy_advice) {
            var txt = []
            for (var k in x.allergy_advice) {
                txt.push(<span key={k}>{k}: {x.allergy_advice[k]}<br /></span>)
            }
            res.push(<Desc>
                <Label>Alergeny:</Label>
                <Value>{txt}</Value>
            </Desc>)
        }
        if (x.allergy_text) {
            res.push(<Desc>
                <Value>{x.allergy_text}</Value>
            </Desc>)
        }
        if (x.manufacturer) {
            res.push(<Desc>
                <Label>Producent:</Label>
                <Value>{x.manufacturer}</Value>
            </Desc>)
        }
        if (x.nutrition) {

            if (x.nutrition['100g']) {
                res.push(<Desc>
                    <Label>Wartości odżywcze w 100g:</Label>
                    <Value>{x.nutrition['100g']}</Value>
                </Desc>)
            }
            if (x.nutrition['100ml']) {
                res.push(<Desc>
                    <Label>Wartości odżywcze w 100ml:</Label>
                    <Value>{x.nutrition['100ml']}</Value>
                </Desc>)
            }
            if (x.nutrition.columnHeaders) {
                res.push(<Desc>
                    <Label>Wartości odżywcze:</Label>
                    <Value>
                        <table>
                            <tbody>
                                <tr>
                                    <th></th>
                                    {x.nutrition.columnHeaders.map((x, i) => <th key={i}>{x}</th>)}
                                </tr>
                                {x.nutrition.rowData.map((r, i) => (<tr key={i}>
                                    <td>{r.nutrient}</td>
                                    {r.values.map((x, i) => <td key={i}>{x}</td>)}
                                </tr>))}
                            </tbody>
                        </table>
                    </Value>
                </Desc>)
            }
        }
        return res
    }


    return <div>
        {!!details && <>{desc(details).map((x, i) => <div key={i}>{x}</div>)}</>}
    </div>

}

const Wrapper = styled.div`
.ant-upload {
    width: 100%;
    border: none;
    > .ant-upload {
        padding: 0;
        img {
            border-radius: 6px;
        }
    }
}
.upload-placeholder {
    width: 100%;
    border: 1px dashed #888;
    border-radius: 12px;
    padding: 80px 12px;
    text-align: center;
    cursor: pointer;

    &:hover {
        border: 1px solid #498551;
    }

    .anticon-camera {
        font-size: 32px;
    }
}
`

const OptionsWrapper = styled.div`
display: flex;
flex-wrap: wrap;
padding: 0 16px;
align-items: baseline;
> div {
    width: 342px;
}
`

const Option = styled.div`
display: flex;
margin-right: 20px;

a { 
    margin-top: 5px;
    margin-left: 5px;
    color: #888;
    opacity: 0;
}
&:hover > a { opacity: 1; }
`

const Customization = ({ customization, field, remove, setInEdit }) => {
    var options = customization.options
    options = options && ('[' + options.map(x => (x.label || '-') + (x.price ? ` (${formatPrice(x.price)} zł)` : '')).join(', ') + ']')
    return <div
        key={field.name}
        style={{
            fontWeight: '600',
            cursor: 'pointer',
        }}>
        <p>
            <span onClick={() => setInEdit(customization.id)}>
                {customization.title} {options}
            </span>
            <a style={{ fontSize: 12, color: '#888', marginLeft: 8 }} onClick={() => remove(field.name)}><CloseOutlined /></a>
        </p>
    </div>
}

const CustomizationInEdit = ({ customization, field, setInEdit, form }) => {
    return <OutsideClickHandler
        onOutsideClick={() => setInEdit(null)}
    >
        <div style={{
            marginBottom: 24,
            borderLeft: '2px solid var(--primary)',
            padding: '0 0 0 12px',
        }}>
            <Form.Item
                name={[field.name, "title"]}
                rules={[{
                    required: true,
                    message: 'Wpisz pytanie do klienta',
                }]}
            >
                <Input placeholder="Wpisz pytanie do klienta" />
            </Form.Item>
            <Form.Item
                name={[field.name, "type"]}
                rules={[{
                    required: true,
                    message: 'Wybierz rodzaj opcji',
                }]}
            >
                <Radio.Group>
                    <Radio.Button value="multi">wielokrotnego wyboru</Radio.Button>
                    <Radio.Button value="single">jednokrotnego wyboru</Radio.Button>
                </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate
                //shouldUpdate={(p, n) => !n.customization !== p.customization}
            >
                {() => form.getFieldValue(['customizations', field.name, "type"]) &&
                    <Form.List name={[field.name, "options"]}>
                        {(items, { add, remove }) => <OptionsWrapper>
                            {items.map((field, idx) => (
                                <Option key={field.name}>
                                    <Radio checked={false} />
                                    <Form.Item
                                        name={[field.name, "label"]}
                                        style={{ flex: 1, marginBottom: 8 }}
                                    >
                                        <Input
                                            placeholder="opcja do wyboru"
                                        />
                                    </Form.Item>
                                    <Form.Item name={[field.name, "price"]} style={{ marginBottom: 8 }}>
                                        <InputNumber
                                            min={-1000}
                                            max={1000}
                                            formatter={value => value ? `${value} zł` : ''}
                                            parser={value => value.replace(',', '.').replace(' zł', '')}
                                            precision={2}
                                            placeholder="cena"
                                            style={{ width: 102 }}
                                        />
                                    </Form.Item>
                                    <a onClick={() => remove(field.name)}>
                                        <CloseOutlined />
                                    </a>
                                </Option>
                            ))}
                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    add({
                                        label: '',
                                    })
                                }}
                                size="small"
                            >Dodaj opcję do wyboru</Button>
                        </OptionsWrapper>}
                    </Form.List>
                }
            </Form.Item>
        </div>
    </OutsideClickHandler>
}

const ProductCustomization = ({ form }) => {
    const [inEdit, setInEdit] = useState()
    return <div>
        <Card
            title={<div>
                <span>Personalizacja produktu</span>
                <HelpButton
                        title="Produkt personalizowany"
                        content={<>
                            <p style={{textAlign: 'center', fontSize: 16}}>Pozwól swoim klientom na personalizowanie produktów takich jak np. lunche albo dania obiadowe. Poniżej możesz zobaczyć jak produkty tego typu będzie widział klient w trakcie dodawania do koszyka.</p>
                            <img src='/imgs/product-options-help.png' style={{ width: '100%' }} />
                        </>}
                    /></div>}
            size="small">

            {/* 
        <Form.Item
            label="Personalizacja produktu?"
            name="customization"
            labelCol={{ span: 12 }}
            wrapperCol={{ span: 12 }}
            style={{ flexDirection: 'row', alignItems: 'baseline' }}
            valuePropName="checked"
            initialValue={!!(form.getFieldValue("customizations") && form.getFieldValue("customizations").length)}
        >
            <Switch checkedChildren="Tak" unCheckedChildren="Nie" />
        </Form.Item>
        */}
            <Form.Item noStyle shouldUpdate
            // shouldUpdate={(p, n) => !n.customization !== p.customization}
            >
                {() => !form.getFieldValue("customization") &&
                    <Form.List
                        name="customizations"
                    >
                        {(customizations, { add, remove }) => <div>
                            {customizations.map((field, idx) => {
                                const customization = form.getFieldValue(["customizations", field.name])
                                return inEdit == customization.id
                                    ? <CustomizationInEdit key={idx} field={field} customization={customization} remove={remove} setInEdit={setInEdit} form={form} />
                                    : <Customization key={idx} field={field} customization={customization} remove={remove} setInEdit={setInEdit} />
                            }
                            )}

                            <Button
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    const id = new Date().getTime()
                                    add({
                                        id,
                                        title: '',
                                        options: [{}],
                                    })
                                    setInEdit(id)
                                }}
                                size="small"
                            >Nowe pytanie do klienta</Button>
                            {/*<Form.Item name="_new_question">
                            <Input.Search
                                placeholder="Wpisz pytanie do klienta"
                                enterButton="Dodaj"
                                onSearch={(v, e) => {
                                    const id = new Date().getTime()
                                    if (v) { add({ id, title: v }) }
                                    setInEdit(id)
                                    form.setFieldsValue({ _new_question: null })
                                    e.preventDefault()
                                }}
                                style={{ maxWidth: 500 }}
                            />
                            </Form.Item>*/}
                        </div>}
                    </Form.List>
                }
            </Form.Item>
        </Card>
    </div>
}


const ProductForm = ({
    code,
    product,
    onFinish,
    oneTime
}) => {

    const [form] = Form.useForm()
    const [img, setImg] = useState()
    const [newImg, setNewImg] = useState()

    if (product.availability === undefined) product.availability = 999;
    product.unit = product.unit || "szt."

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue(product)
        setImg(product.image_type)
    }, [product])

    const onSave = (values) => {
        saveProduct(values)
            .then(() => onFinish(true))
            .catch(() => message.error("Nie udało się zapisać produktu. Spróbuj później."))
    }

    const findProduct = () => {
        ApiShop.get(`/product/${form.getFieldValue('id')}`).then((res) => {
            const product = res.data
            form.setFieldsValue(product)
            setImg(product.image_type)
        }).catch((err) => { })
    }

    const findImage = () => {
        if (code && code.match(/\d{8,}/)) return;
        if (img && !img.match('fruit:')) return;
        const name = (form.getFieldValue('name') || '').toLowerCase()
        ApiShop.get('/find-image-by-name', { params: { name } }).then((res) => {
            const img = res.data.image
            form.setFieldsValue({ image_type: img })
            setImg(img)
        }).catch((err) => { })
    }

    const MobileWrapper = styled.div`
    @media(max-width: 768px) {
        margin: 0 auto;
        width: max-content;
    }
    `

    return <Wrapper>
        <Form
            form={form}
            initialValues={{ ...product, id: code }}
            layout="vertical"
            onFinish={onSave}
            style={{
                maxWidth: 400,
                margin: '0 auto'
            }}
            hideRequiredMark
        >
            <Form.Item
                name="id"
                rules={[{
                    required: true,
                    message: 'Pole wymagane',
                }]}
                disabled={!!code}
            >
                <Input
                    placeholder="Kod własny"
                    onBlur={findProduct}
                    style={{ fontSize: 16 }}
                />
            </Form.Item>
            <Form.Item
                name="name"
                rules={[{
                    required: true,
                    message: 'Pole wymagane',
                }]}
            >
                <Input
                    placeholder="Nazwa"
                    onBlur={findImage}
                    style={{ fontSize: 16 }}
                />
            </Form.Item>

            <label style={{ marginBottom: 24, display: 'block' }}>
                <Form.Item
                    name="image_type"
                    noStyle
                />
                <Form.Item
                    name="image"
                    valuePropName="files"
                    noStyle
                >
                    <Input
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={(e, a) => {
                            if (e.target.files && e.target.files[0]) {
                                const reader = new FileReader();
                                reader.onload = (e) => { setNewImg(e.target.result) }
                                reader.readAsDataURL(e.target.files[0])
                            }
                        }}
                    />
                </Form.Item>
                {(img || newImg)
                    ? <img src={newImg || imageUrl(code, img)} style={{ maxWidth: 400, maxHeight: 300, objectFit: 'contain', cursor: 'pointer' }} />
                    : <div className="upload-placeholder">
                        <CameraOutlined />
                        <div className="ant-upload-text">
                            Dodaj zdjęcie <br />
                            <span style={{ opacity: 0.5 }}>(min. 288x288px)</span>
                        </div>
                    </div>}
            </label>

            {/*
            <Form.Item
                name="image_type"
            >
                <Upload
                    name="file"
                    listType="picture-card"
                    showUploadList={false}
                    action={`/api/shop/${getShopId()}/product/${code}/image`}
                    onChange={(e) => {
                        if (e.file.status == 'done') {
                            form.setFieldsValue({ image_type: 'custom' })
                            setImg('custom')
                        }
                    }}
                >
                    {img
                        ? <img src={imageUrl(code, img)} style={{ width: 200, height: 200, objectFit: 'contain' }} />
                        : <div className="upload-placeholder">
                            <CameraOutlined />
                            <div className="ant-upload-text">Dodaj zdjęcie</div>
                        </div>}
                </Upload>
            </Form.Item>
                    */}

            <MobileWrapper>
                <Row gutter={16}>
                    <Col>
                        <Form.Item
                            label="Cena"
                            name="price"
                            rules={[{
                                required: true,
                                message: 'Pole wymagane',
                            }]}
                        >
                            <InputNumber
                                placeholder="0,00"
                                precision={2}
                                decimalSeparator=","
                                min={0}
                                style={{ fontSize: 16 }}
                            />
                        </Form.Item>
                    </Col>
                    <Col><p style={{ marginTop: 35 }}>za</p></Col>
                    <Col>
                        <Form.Item
                            label="Jednostka"
                            name="unit"
                            rules={[{
                                required: true,
                                message: 'Pole wymagane',
                            }]}
                        >
                            <Select>
                                <Select.Option value="szt.">sztukę</Select.Option>
                                <Select.Option value="kg">kilogram</Select.Option>
                                <Select.Option value="zgrzewkę">zgrzewkę</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>


                <Form.Item
                    label="Stan magazynowy"
                    name="availability"
                >
                    <AvailabilityInput />
                </Form.Item>
                <Form.Item
                    name={["description", "custom"]}
                >
                    <Input.TextArea
                        placeholder="Twój opis produktu"
                    />
                </Form.Item>

                <ProductCustomization form={form} />

                {oneTime ?
                    <Button type="primary" htmlType="submit">Zatwierdź</Button>
                    : <>
                        <Button onClick={onFinish}>Wróć</Button>
                        <Button style={{ marginLeft: 24 }} type="primary" htmlType="submit">Zatwierdź</Button>
                    </>}
            </MobileWrapper>
        </Form>
    </Wrapper>
}

export default ProductForm
export { ProductDescription, saveImage, ProductCustomization }