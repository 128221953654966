import React, { useState } from 'react';
import { Form, Input, Button, Result, Alert, Col, Row, Modal } from 'antd';
import { UserOutlined, LockOutlined, CheckCircleFilled } from '@ant-design/icons';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Api } from '../Api';
import { state } from '../models/AppState'


const doLogin = (values) => {
  return Api.post('/login', values).then(res => {
    state.afterLogin(res.data)
  })
}

const ResetForm = ({ id, token }) => {
  const onFinish = values => {
    
    Api.post('/login/password/reset', {
      id,
      token,
      password: values.password,
    }).then(() => {
      Modal.info({
        title: 'Hasło zresetowane',
        content: 'Możesz się teraz zalogować',
        onOk: () => window.location = '/login'
      })
    }).catch(() => {
      Modal.error({
        title: 'Nie udało się zresetować hasła',
        content: 'Link mógł stracić ważność',
        onOk: () => window.location = '/login'
      })
    })
  };

  return (
    <div>
      <h2>Podaj nowe hasło</h2>
      <Form
        name="normal_login"
        className="login-form"
        layout="vertical"
        onFinish={onFinish}
      >

        <Form.Item
          name="password"
          label="Hasło"
          rules={[{ required: true, message: 'Podaj hasło' }]}
        >
          <Input.Password
            placeholder="Hasło"
            size="large"
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          label="Potwierdź hasło"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Wprowadź ponownie swoje hasło',
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject('Wprowadzone hasła są różne');
              },
            }),
          ]}
        >
          <Input.Password
            placeholder="Hasło"
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%", height: 39  }}>
            USTAW NOWE HASŁO
        </Button>
        </Form.Item>
      </Form>
    </div>
  );
}

const registerLink = () => window.location.hostname.match("demo") ? "//demo.fablo.pl/?form" : "//fablo.pl/?form"

const ForgotForm = ({ toLogin }) => {
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onFinish = (values) => {
    setError(false)
    Api.post('/login/password/forgot', values).then(() => {
      setError(false)
      setSuccess(true)
    }).catch(() => {
      setError(true)
    })
  };

  return <div>
    {!success &&
      <>
        <h2>Nie pamiętasz hasła?</h2>
        <p>Podaj swój email. Jeśli posiadsz konto wyślemy Ci link resetujący hasło.</p>

        <Form
          name="normal_login"
          className="login-form"
          onFinish={onFinish}
        >
          {error && <Alert message="Nie udało się zresetować hasła. Spróbuj później." type="error" style={{ marginBottom: "20px" }} />}

          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Podaj email' }]}
          >
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="E-mail"
              size="large"
              type="email"
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: "100%", height: 39 }}>
              WYŚLIJ
          </Button>
          </Form.Item>
          <Row justify="space-between">
            <Col>
              <a style={{ textDecoration: 'underline' }} onClick={toLogin}>Zaloguj się</a>
            </Col>
            <Col>
              <a href={registerLink()} style={{ textDecoration: 'underline' }}>Zarejestruj swój sklep</a>
            </Col>
          </Row>
        </Form>
      </>}
    {success &&
      <Result
        status="success"
        title="Link resetujący hasło wysłany"
        subTitle="Kliknij na link w mailu aby ustalić nowe hasło"
        icon={<CheckCircleFilled style={{ color: "#2E773A" }} />}
      />}
  </div>
}

const LoginForm = ({ toPasswordReset }) => {
  const [error, setError] = useState(false)

  const onFinish = values => {
    setError(false)
    doLogin({
      email: values.email,
      password: values.password
    }).catch(() => {
      setError(true)
    })
  };

  return (
    <div>
      <h2>Zaloguj się</h2>
      <p>Cześć! Zaloguj się aby kontynuować rozwijanie swojego biznesu online.</p>
      <Form
        name="normal_login"
        className="login-form"
        onFinish={onFinish}
      >
        {error && <Alert message="Nie udało się zalogować. Sprawdź e-mail i hasło." type="error" style={{ marginBottom: "20px" }} />}

        <Form.Item
          name="email"
          rules={[{ required: true, message: 'Podaj email' }]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="E-mail"
            size="large"
            type="email"
          />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Podaj hasło' }]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            placeholder="Hasło"
            size="large"
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" style={{ width: "100%", height: 39 }}>
            ZALOGUJ
        </Button>
        </Form.Item>
        <Row justify="space-between">
          <Col>
            <a style={{ textDecoration: 'underline' }} onClick={toPasswordReset}>Nie pamiętasz hasła?</a>
          </Col>
          <Col>
            <a href={registerLink()} style={{ textDecoration: 'underline' }}>Zarejestruj swój sklep</a>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

const RotatingHeroImages = () => {
  const Wrapper = styled.div`
      height: 100%;
      position: relative;
      @keyframes fade {
          0% {opacity: 0;}
          20% {opacity: 1;}
          33% {opacity: 1;}
          53% {opacity: 0;}
          100% {opacity: 0;}
      }
      img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: center bottom;
          animation-name: fade;
          animation-iteration-count: infinite;
          animation-timing-function: ease;
          animation-duration: 15s;
      }
  `
  return <Wrapper>
    {[1, 2, 3].map((item) => (
      <img
        key={item}
        src={`/hp-slide-${item}.jpg`}
        style={{
          animationDelay: `-${item * 5}s`
        }}
      />
    ))}
  </Wrapper>
}


const Wrapper = styled.div`
height: 100%;
display: flex;
align-items: center;
justify-content: center;
padding: 15px;
font-family: "Open Sans", 'Helvetica', 'Arial', sans-serif;

color: #252525;
h2 {
  font-weight: 300;
  font-size: 33px;
  margin-bottom: 26px;
}
p {
  font-weight: 400;
  color: #808080;
  font-size: 19px;
  margin-bottom: 32px;
  line-height: 32px;
}
*::selection {
  background: #EFBA51 !important;
}
`

const LogoWrapper = styled.div`
margin-left: 50px;
position: absolute;
top: 6px;
left: 0;
border-radius: 55px;
width: 110px;
height: 68px;
text-align: center;
@media (max-width: 460px) {
    margin-left: 6px;
}
@media (max-width: 370px) {
    height: 50px;
    width: 80px;
}
`
const HeaderContainer = styled.div`
display: flex;
justify-content: flex-end;
padding: 13px;
box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.04);
background: #fff;
z-index: 1;
position: absolute;
width: 100%;
`

const LoginPage = ({ match }) => {
  const { id, token } = match && match.params || {}
  const [form, setForm] = useState(id ? 'reset' : 'login')

  return <div style={{ height: "100%" }}>
    <HeaderContainer>
      <LogoWrapper>
        <a href={`//${window.location.hostname.replace(/panel\.|my\./, '')}/`}>
          <img style={{ height: "100%" }} src="/logo.png" />
        </a>
      </LogoWrapper>
      <a href={`//${window.location.hostname.replace(/panel\.|my\./, '')}/pomoc`}>
      <Button type="danger" style={{height: 39, background: 'rgb(198, 24, 25)', borderColor: 'rgb(198, 24, 25)'}}>ZGŁOŚ PROBLEM</Button>
      </a>
    </HeaderContainer>
    <Row style={{ height: "100%" }}>
      <Col md={12}>
        <RotatingHeroImages />
      </Col>
      <Col md={2}></Col>
      <Col md={8}>
        <Wrapper>
          {form == 'login' && <LoginForm toPasswordReset={() => setForm('forgot')} />}
          {form == 'forgot' && <ForgotForm toLogin={() => setForm('login')} />}
          {form == 'reset' && <ResetForm id={id} token={token} />}
        </Wrapper>
      </Col>
    </Row>
  </div>
}

export default LoginPage
export { LoginForm }