import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import PanelLayout from './PanelLayout';
import { Modal, Table, Card, Badge, Input, Spin, Form, message, InputNumber, Select, Popconfirm, Switch } from 'antd';
import { SearchOutlined, CloseOutlined, CheckOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import ProductForm, { ProductDescription, saveImage, ProductCustomization } from './ProductForm'
import { ApiShop } from '../Api';
import { formatPrice, imageUrl, formatQuantity, debounce } from '../utils';
import styled from 'styled-components';
import AvailabilityInput from './AvailabilityInput'
import { EditableCell } from './Utils'
import OutsideClickHandler from 'react-outside-click-handler'

const Thumbnail = styled.img`
height: 50px;
max-width: 50px;
object-fit: contain;
border-radius: 2px;
margin: -10px 0;
@media(max-width: 768px) {
    margin: -7px 0;
}
`


const inlineSave = (fetchProducts, id) => ({ values, done }) => {
    if(Object.keys(values).length === 0) return Promise.resolve();
    const promises = []
    if (values.image) {
        const image = values.image[0]
        delete values.image
        values.image_type = 'custom'
        promises.push(saveImage(id, image))
    }

    promises.push(ApiShop.put(`/product/${id}`, { ...values }))
    Promise.all(promises)
        .then(() => {
            message.success("Zapisano")
            fetchProducts(true).then(() => { done && done() }).catch(() => { done && done() })
            return
        }).catch(() => {
            message.error("Nie udało się zapisać. Spróbuj później")
        })
}

const ExpandedRow = ({ product, onFinish }) => {
    const [form] = Form.useForm()

    return <div style={{ minWidth: 300 }}>

        {(product.brandbank_id || product.bioplanet_id) && <div>
            <p style={{ color: 'rgb(239,140,151)' }}>Produkt pobrany z bazy produktów, brak możliwości edycji poniższych informacji.</p>
            <div style={{
                columnCount: 2,
                fontSize: 13,
                marginBottom: 24,
            }}>
                <ProductDescription product={product} />
            </div>
        </div>}


        {!(product.brandbank_id || product.bioplanet_id) && <div>
            <Input.TextArea
                placeholder="Twój opis produktu"
                rows={5}
                defaultValue={(product.description && product.description.custom) || ''}
                onBlur={(e) => onFinish({ values: { description: { custom: e.target.value } } })}
                style={{ maxWidth: 500 }}
            />

            <Form
                form={form}
                initialValues={product}
                layout="vertical"
                onFinish={() => {}}
                onValuesChange={debounce((_, values) => {
                    delete values.customization
                    onFinish({values: values})
                    }, 1000)}
                style={{ maxWidth: 500, marginTop: 26 }}
            >
                <ProductCustomization form={form} />
            </Form>
            
        </div>}

        {/*
        <Select
            mode="multiple"
            placeholder="Wybierz opcje personalizacji"
            style={{ width: "100%" }}
            onChange={(val) => onFinish({ values: { options: val } })}
            defaultValue={product.options}
        >
            {options.map(x => <Select.Option
                key={x.id}
            >
                <b>{x.name} ({(x.options || []).length})</b>: {x.title} [{formatOptions(x.options)}]
            </Select.Option>)}
        </Select>
        */}
    </div>
}

const TableWrapper = styled.div`
overflow: scroll;
.ant-table-row-expand-icon-cell {
    padding: 0;
    width: 0;
}
.ant-table-expand-icon-col {
    width: 80px;
}
th.ant-table-cell {
    text-align: center;
    font-weight: 600;
}
.ant-table {
    //width: 100%;
    //min-width: 1100px;
}
.ant-input, .ant-input-number-input {
    background: #fff;
}
@media(max-width: 768px) {
    margin: 0 -24px;
    .ant-table { min-width: 300px; }
}
`

const Wrapper = styled.div`
    @media(max-width: 500px) {
        .ant-card-head-title { display: none; }
        .ant-card-extra { width: 100% }
    }
`

const ProductsPage = () => {
    const [inEdit, setInEdit] = useState();

    const [rawProducts, setRawProducts] = useState([]);
    const [searchPhrase, setSearch] = useState("");
    const [sorter, setSorter] = useState()

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchProducts = (silent) => {
        if (!silent) { setLoading(true) }
        return ApiShop.get(`products-panel`).then(({ data }) => {
            setRawProducts(data)
            setLoading(false)
        })
    }

    useEffect(() => {
        fetchProducts()
    }, [])

    useEffect(() => {
        let prods;
        if (searchPhrase) {
            prods = rawProducts.filter(x => ("" + (x.id || "")).toLowerCase().match(searchPhrase.toLowerCase()) || (x.name || "").toLowerCase().match(searchPhrase.toLowerCase()))
        } else {
            prods = rawProducts
        }
        if (sorter && sorter.field) {
            const collator = new Intl.Collator('pl', { numeric: true, sensitivity: 'accent' });
            prods.sort((a, b) => {
                const x = "" + a[sorter.field]
                const y = "" + b[sorter.field]
                const m = sorter.order == 'ascend' ? 1 : -1
                return m * collator.compare(x, y);
            })
        }
        setProducts(prods.slice())
    }, [searchPhrase, sorter, rawProducts])

    const tableSettingsChanged = (pagination, filters, sorter, extra) => {
        //console.log(pagination, filters, sorter, extra)
        setSorter(sorter)
    }

    const wide = window.innerWidth > 768

    const ImageInput = ({ value, onChange, save, cancel, img }) => {
            
    
        return <label style={{
            margin: '5px 12px',
            display: 'block',
            position: 'relative'
        }}>
            <OutsideClickHandler
              onOutsideClick={cancel}
            >
                <span style={{opacity: 0.2}}>
                {img}
                </span>
                <a style={{
                    position: 'absolute',
                    top: 3,
                    left: 1,
                    display: 'block',
                }}>
                    Zmień
                </a>
                <Input
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    onChange={(e) => { onChange(e); save() }}
                    onBlur={cancel}
                    autoFocus
                />
            </OutsideClickHandler>
        </label>
    }

    const columns = [
        {
            title: 'Kod produktu',
            dataIndex: 'id',
            width: 185,
            sorter: true,
            responsive: ['md'],
        },
        {
            title: 'Zdjęcie',
            dataIndex: 'image_type',
            align: 'center',
            width: wide ? 82 : 55,
            sorter: true,
            render: (text, record, index) => {
                if (record.image_type === 'bio' || record.image_type === 'brandbank') {
                    return text && <Thumbnail src={imageUrl(record.id, record.image_type)} />
                }
                const img = text && <Thumbnail src={imageUrl(record.id, record.image_type) + "?" + new Date().getTime()} />
                return <EditableCell
                    onFinish={inlineSave(fetchProducts, record.id)}
                    staticContent={img}
                    record={record}
                    fieldName="image"
                    valuePropName="files"
                    withoutButtons
                    field={({ cancel, save }) => <ImageInput
                        save={save}
                        cancel={cancel}
                        img={img}
                    />}
                />

            }

        },
        {
            title: 'Nazwa',
            dataIndex: 'name',
            sorter: true,
            //width: 100,
            render: (text, record, index) => (
                wide ? <EditableCell
                    onFinish={inlineSave(fetchProducts, record.id)}
                    staticContent={text}
                    record={record}
                    fieldName="name"
                    field={({ cancel, save }) => <Input
                        placeholder="Nazwa"
                        onBlur={save}
                        autoFocus
                    />}
                />
                    : text
            )
        },
        {
            title: 'Cena',
            dataIndex: 'price',
            //align: 'right',
            width: 100,
            sorter: true,
            responsive: ['md'],
            render: (text, record, index) => (
                <EditableCell
                    onFinish={inlineSave(fetchProducts, record.id)}
                    staticContent={text && formatPrice(text)}
                    record={record}
                    fieldName="price"
                    field={({ cancel, save }) => <InputNumber
                        placeholder="0,00"
                        precision={2}
                        decimalSeparator=","
                        min={0}
                        onBlur={save}
                        autoFocus
                    />}
                />)
        },
        {
            title: 'Jedn.',
            dataIndex: 'unit',
            sorter: true,
            width: 100,
            responsive: ['md'],
            render: (text, record, index) => (
                <EditableCell
                    onFinish={inlineSave(fetchProducts, record.id)}
                    staticContent={text}
                    record={record}
                    fieldName="unit"
                    withoutButtons
                    field={({ cancel, save }) => <Select
                        onBlur={save}
                        onChange={save}
                        autoFocus
                        defaultOpen
                    >
                        <Select.Option value="szt.">sztukę</Select.Option>
                        <Select.Option value="kg">kilogram</Select.Option>
                        <Select.Option value="zgrzewkę">zgrzewkę</Select.Option>
                    </Select>}
                />)
        },
        {
            title: 'Stan mag.',
            dataIndex: 'availability',
            align: 'center',
            width: wide ? 190 : 105,
            sorter: true,
            render: (x, record, index) => (

                <EditableCell
                    onFinish={inlineSave(fetchProducts, record.id)}
                    staticContent=
                    {<Badge
                        status={x > 0 ? "success" : "default"}
                        text={x == 0 ? "Brak" : (x >= 999 ? "Bez limitu" : formatQuantity(x))}
                    />}
                    record={record}
                    fieldName="availability"
                    withoutButtons
                    formWrapper={(content) => { }}
                    field={({ cancel, save }) => (
                        <AvailabilityInput
                            onSelected={save}
                            autoFocus={true}
                            style={{ margin: "0 -16px", width: 190, right: 0, position: 'absolute', top: 0 }}
                        />
                    )}
                />)
        },
        {
            title: '',
            dataIndex: 'option',
            valueType: 'option',
            width: 50,
            responsive: ['md'],
            //align: 'right',
            render: (_, record) => (
                <>
                    {/*<a
                        onClick={() => {
                            setInEdit(record);
                        }}
                    >
                        Edytuj
                    </a>*/}
                    <Popconfirm
                        title="Usunąć produkt?"
                        okText="Tak"
                        cancelText="Nie"
                        placement="topRight"
                        onConfirm={() =>
                            inlineSave(fetchProducts, record.id)({ values: { archived: true } })
                        }
                    >
                        <a href="" style={{ color: "rgb(227,160,167)" }}><DeleteOutlined /></a>
                    </Popconfirm>
                </>
            ),
        },
    ];

    return <PanelLayout>
        <Spin spinning={loading}><Wrapper>
            <Card
                title="Produkty w sklepie"
                extra={
                    <Input.Search
                        placeholder="Wyszukaj..."
                        allowClear={true}
                        style={{ maxWidth: "500px", fontSize: 16 }}
                        onSearch={(v, e) => setSearch(v)}
                        defaultValue={searchPhrase}
                    />}
            >
                <TableWrapper>
                    <Table
                        rowKey="id"
                        tableLayout="fixed"
                        dataSource={products}
                        columns={columns}
                        pagination={{
                            showTotal: (all, range) => `Produkty ${range[0]}-${range[1]} z ${all}`,
                            defaultPageSize: 50,
                            pageSizeOptions: ['50', '100', '500']
                        }}
                        size={wide ? "normal" : "small"}
                        onChange={tableSettingsChanged}
                        expandable={wide && {
                            expandedRowRender: (record, index) => <ExpandedRow
                                onFinish={inlineSave(fetchProducts, record.id)}
                                product={record}
                            />,
                            rowExpandable: ({ options }) => true,
                            expandIconColumnIndex: 6,
                            expandIcon: (props) => {
                                const tmp = <Switch
                                    checked={props.expanded}
                                    checkedChildren="Tak"
                                    unCheckedChildren="Nie"
                                    onChange={() => props.onExpand(props.record)}
                                />
                                const product = props.record
                                return <div>
                                    <a onClick={() => props.onExpand(props.record)}>
                                        {(product.brandbank_id || product.bioplanet_id) ? 'Podgląd' : 'Edytuj'}
                                    </a>
                                </div>
                            },
                        }}
                    />
                </TableWrapper>
            </Card>
        </Wrapper></Spin>

        <Modal
            visible={!!inEdit}
            footer={null}
            onCancel={() => setInEdit()}
        >
            {inEdit &&
                <ProductForm
                    onFinish={() => {
                        setInEdit()
                        fetchProducts()
                    }}
                    code={inEdit.id}
                    product={inEdit}
                    oneTime={true}
                />
            }
        </Modal>
    </PanelLayout>
}

export default ProductsPage
